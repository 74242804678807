import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Link, useHistory, useLocation, useRouteMatch, useParams } from 'react-router-dom';
import { propEq, findIndex, append, set, lensProp } from 'ramda';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';

import Button from '../../../components/Button';
import { Root } from '../../../theme/globalStyles';

import InitialOnboarding from '../../../components/Onboarding/InitialOnboarding';
import Profile from '../../Profile/components';
import BusinessInfo from '../../BusinessInfo/components';
import PriceTable from '../../PriceTable/components';
import Services from '../../Services/components';
import { useScreenMeasure } from '../../../helpers/hooks/useScreenMeasure';

import { validations as basicValidations } from '../../Profile/components/validations';
import { validations as businessValidations } from '../../BusinessInfo/components/validations';
import { setRecaptcha } from '../../Profile/store/actions';
import { upsertUser } from '../../Profile/store/thunk';
import { upsertEstablishment } from '../../BusinessInfo/store/thunk';
import { setBaseAPI, setToken } from '../../../services';

import { setIsInitialOnboardingOpen, setIsFromRegistration } from '../../../components/Onboarding/store/actions';

import { useStyles } from './styles';

export default function InitialRegistration() {
  const dispatch = useDispatch();

  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const { url } = useRouteMatch();
  const { resaleId } = useParams();

  const isFromLogin = history?.location?.state?.data?.isFromLogin;

  const isProfileRegistration = location.pathname == '/cadastro-inicial';
  const isEstablishmentRegistration = location.pathname == '/cadastro-inicial/estabelecimento';
  const isPriceTableRegistration = location.pathname == '/cadastro-inicial/tabela-de-precos';
  const isServiceRegistration = location.pathname == '/cadastro-inicial/servicos';

  const [isMobile] = useScreenMeasure();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const profileSubmit = useSelector(state => state.profile.submit);
  const profileLoading = useSelector(state => state.profile.isLoading);
  const businessInfoSubmit = useSelector(state => state.businessInfo.submit);
  const businessLoading = useSelector(state => state.businessInfo.isLoading);
  const services = useSelector(state => state.services.list);
  const { token, loggedIn } = useSelector(state => state.login);
  const { isInitialOnboardingOpen } = useSelector(state => state.onboarding);

  const isLoading = profileLoading || businessLoading;

  const initialConfig = [
    {
      id: 'profile',
      title: 'Dados do usuário',
      path: `${url}`,
      component: Profile,
      onSubmit: params => {
        delete params['emailConfirm'];
        return dispatch(upsertUser({ ...params, ...(resaleId && { resaleId }) }))
      },
      validations: basicValidations,
      submitParams: {
        userId,
        ...profileSubmit
      }
    },
    {
      id: 'businessInfo',
      title: 'Dados do estabelecimento',
      path: `${url}/estabelecimento`,
      component: BusinessInfo,
      onSubmit: params => {
        let newConfig = initialConfig;
        newConfig = append(servicesPath, newConfig);
        setConfig(newConfig);

        const submitParams = {
          ...params,
          establishmentTypes: [2],
          ...(resaleId && { resaleId })
        }

        setBusinessInfoData(submitParams);
        return dispatch(upsertEstablishment(submitParams));
      },
      validations: businessValidations,
      submitParams: {
        userId,
        establishmentId,
        ...businessInfoSubmit
      }
    }
  ];

  const [isReloadFinished, setIsReloadFinished] = useState(false);

  const [businessInfoData, setBusinessInfoData] = useState({});
  const [config, setConfig] = useState(initialConfig);
  const [nextStep, handleStepNavigation] = useState(0);
  const currentIndex = findIndex(propEq('path', location.pathname), config);
  const currentStep = config[currentIndex];

  const servicesPath = {
    id: 'services',
    title: 'Serviços',
    path: `${url}/servicos`,
    component: Services,
    onSubmit: 'push',
    submitParams: {}
  }

  const priceTablePath = {
    id: 'priceTable',
    title: 'Tabela de preços',
    path: `${url}/tabela-de-precos`,
    component: PriceTable,
    onSubmit: 'push',
    submitParams: {}
  }

  useEffect(() => {
    dispatch(setRecaptcha(false));
  }, []);

  useEffect(() => {
    if(isProfileRegistration && !loggedIn) {
      dispatch(setIsInitialOnboardingOpen(true));
    }
  }, [isProfileRegistration, loggedIn]);

  useEffect(() => {
    if(loggedIn || isFromLogin) {
      history.push(config[nextStep + 1].path);
    }
  }, [isFromLogin, loggedIn]);

  useEffect(() => {
    handleStepNavigation(currentIndex);
  }, [currentIndex]);

  useEffect(() => {
    if(token) {
      setToken(token);
    }
  }, [token]);

  useEffect(() => {
    setBaseAPI(userId, establishmentId);
  }, [userId, establishmentId]);

  // const handleEstablishmentTypes = params => {
  //   let newConfig = initialConfig;

  //   if (establishmentTypes.includes(1) || establishmentTypes.includes('1')) {
  //     newConfig = append(priceTablePath, newConfig);
  //   }

  //   if (establishmentTypes.includes(2) || establishmentTypes.includes('2')) {
  //     newConfig = append(servicesPath, newConfig);
  //   }

  //   setConfig(newConfig);
  // };

  // const handleEstablishmentTypes = params => {
    // let newConfig = initialConfig;
  //   params.establishmentTypes.push(2);
  //   newConfig = append(servicesPath, newConfig);
  //   setConfig(newConfig);
  // }

  useEffect(() => {
    const isPageReloaded = window.performance
      .getEntriesByType('navigation')
      .map((nav) => nav.type)
      .includes('reload');

    window.scrollTo(0, 0);

    if(isPageReloaded && !isReloadFinished) {
      setIsReloadFinished(true);
      return;
    }

    if(config[nextStep]) {
      history.push(config[nextStep].path);
    }
  }, [config, nextStep]);

  const handleNextStep = values => {
    dispatch(setIsFromRegistration(true));

    const { onSubmit, id } = currentStep;
    const isLastIndex = currentIndex == (config?.length - 1);

    let params = {
      ...values,
      userId: userId ?? null,
      establishmentId: establishmentId ?? null
    }

    if(
      id === 'priceTable' &&
      businessInfoSubmit?.establishmentTypes.includes(1) &&
      businessInfoSubmit?.establishmentTypes.includes(2)
    ) {
      handleStepNavigation(nextStep + 1);
    }

    if(id == 'services' && services?.length == 0) {
      return toast.error('Você precisa cadastrar pelo menos um serviço.');
    }

    if(isLastIndex && (id == 'priceTable' || id == 'services')) {
      return history.push('/');
    }

    if(onSubmit === 'push') {
      return handleStepNavigation('next');
    }

    if (id === 'businessInfo') {
      // const { establishmentTypes } = params;
      // handleEstablishmentTypes(establishmentTypes);
      // handleEstablishmentTypes(params);
      params = set(lensProp('userId'), userId, params);
    }

    return onSubmit(params).then(() => {
      handleStepNavigation(nextStep + 1);
    });
  }

  const handlePreviousStep = async () => {
    if(!nextStep) {
      history.push('/');
      return;
    }

    handleStepNavigation(nextStep - 1);
  }

  return(
    <Root>
      <Container className={classes.root}>
        {isInitialOnboardingOpen && <InitialOnboarding />}
        <Grid container direction="column" justify="space-between">
          {!isMobile && (
            <Stepper
              alternativeLabel
              nonLinear
              activeStep={currentIndex}
              style={{ background: 'transparent' }}
            >
              {config.map((nav, i) => (
                <Step key={nav.path}>
                  <StepButton
                    completed={currentIndex > i}
                    disabled={currentIndex < i}
                    component={Link}
                    to={nav.path}
                    style={{ textDecoration: 'none' }}
                  >
                    {nav.title}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          )}
          <Grid>
            {config.map(({ component: Component, ...nav }) => (
              <Route
                exact
                key={nav.path}
                path={nav.path}
                render={routeProps => (
                  <Formik
                    enableReinitialize
                    initialValues={
                      nav.id == 'businessInfo'
                        ? businessInfoData
                        : nav.submitParams
                    }
                    validationSchema={nav.validations}
                    onSubmit={handleNextStep}
                  >
                    {({ ...formikProps }) => (
                      <Form>
                        <Component formikProps={formikProps} {...routeProps} />
                        <Grid
                          className={classes.footer}
                          style={{ position: 'fixed', bottom: 0 }}
                        >
                          <Container>
                            <Grid
                              container
                              justify={(!isEstablishmentRegistration && !isPriceTableRegistration && !isServiceRegistration) ? "space-between" : "flex-end"}
                            >
                              {(!isEstablishmentRegistration && !isPriceTableRegistration && !isServiceRegistration) && (
                                <Button
                                  type="button"
                                  color="secondary"
                                  loading={isLoading}
                                  onClick={handlePreviousStep}
                                >
                                  Voltar
                                </Button>
                              )}
                              <Button color="success" loading={isLoading}>
                                Avançar
                              </Button>
                            </Grid>
                          </Container>
                        </Grid>
                      </Form>
                    )}
                  </Formik>
                )}
              />
            ))}
          </Grid>
        </Grid>
      </Container>
    </Root>
  );
}
