import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import VMasker from 'vanilla-masker';
import moment from 'moment';

import { Formik, Form } from 'formik';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Link from '@material-ui/core/Link';
import { Button as MUIButton } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faImage, faUserPlus, faList, faTrash } from '@fortawesome/free-solid-svg-icons';

import Modal from '../../Modal';
import Input from '../../Input';
import Select from '../../Select';
import Button from '../../Button';
import ButtonConfirmation from '../../ButtonConfirmation';
import Loader from '../../Loader';
import ExportPDF from '../../ExportPDF';
import SingleDatePicker from '../../SingleDatePicker';
import ModalConfirmation from '../../ModalConfirmation';
import Item from './Item';

import { fetchUsersSituation } from 'components/Filter/store/services';
import { sendNfse } from 'pages/ProvisionalReceiptConfig/store/thunk';
import { registerFieldChange, setUpdate, setRemove, setReceipt, clearChanges, clearServiceOrder, onConvertAndChangeData } from '../store/actions';
import { setUserId } from 'pages/Profile/store/actions';
import { setEstablishmentId } from '../../../pages/BusinessInfo/store/actions';
import { setClientId } from '../../../pages/ClientRegistration/components/steps/BasicInfo/store/actions';
import { loadServiceOrders, loadResumeOrders } from '../../../pages/OperationalHistory/store/thunk';
import { loadTablesFromEstablishment } from 'pages/PriceTable/store/thunk';
import { loadProfile } from 'pages/Profile/store/thunk';
import { loadComissionedPayrollListSettings } from 'pages/ComissionedPayrollList/store/thunk';
import { toggleServiceOrder } from '../../../pages/OperationalHistory/store/actions';
import { getOsReceipt, loadServiceOrder, removeServiceOrder, upsertServiceOrder, sendReceipt, getMeansPayments, getServices, removeDiscount } from '../store/thunk';
import { convertToOptions, convertToDB, convertToReal, convertToBrl } from '../../../helpers/converters';
import { validations, removeValidations, emailValidation } from './validations';

import { SITE_RESTRICTIONS } from '../../../helpers/restrictions';

import { useStyles } from './styles';

export default function ServiceOrder({ pageOrigin, transactionsProps, storageHistoryProps }) {
  const dispatch = useDispatch();

  const history = useHistory();
  const isFromStorageHistory = pageOrigin == 'storageHistory';

  const classes = useStyles();

  const [listServices] = useState([]);
  const [services, setServices] = useState([]);
  const [newServices, setNewServices] = useState([]);
  const [servicesAmount, setServicesAmount] = useState([]);
  const [hasActiveServiceUsage, setHasActiveServiceUsage] = useState(false);
  const [isRemovingDiscount, setIsRemovingDiscount] = useState(false);

  const defaultEstablishmentId = useSelector(state => state.login.establishments)[1]?.establishmentId;
  const { token, establishments } = useSelector(state => state.login);
  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { selects, qp } = useSelector(state => state.filters);
  const [availablePaymentMethods, setAvailablePaymentMethods] = useState(convertToOptions(['paymentMethod'], selects));
  const { startDate, endDate } = useSelector(state => state.dateFilter);
  const { modal } = useSelector(state => state.operationalHistory);
  const page = useSelector(state => state.pagination.activePage);
  const pageSize = useSelector(state => state.pagination.size);
  const userRestrictions = useSelector(state => state.profile.userRestrictions);
  const { isSendNfseLoading } = useSelector(state => state.provisionalReceiptConfig);

  const isEstablishmentPremiumPackage = establishments
    ?.find(establishment => +establishment.establishmentId == +establishmentId)
    ?.premiumPackage == 1;

  const {
    isLoading,
    isUpdating,
    isRemoving,
    isSending,
    submit,
    cardCode,
    rpsNumber,
    rpsSeries,
    nfseNumber,
    entryUserName,
    exitUserName,
    entryDateTime,
    exitDateTime,
    permanence,
    plate,
    vehicleModel,
    vehicleColor,
    contractId,
    typePrice,
    paymentMethodName,
    paymentCode,
    amount,
    amountTotal,
    clientName,
    clientEmail,
    clientDocument,
    financialSituationId,
    observations,
    clientId,
    serviceOrderId,
    serviceOrderUser,
    serviceOrderEstablishment,
    nfseLink,
    nfseStatus,
    serviceOrderCode,
    paymentDateTime,
    servicesOnly,
    entryPhotoUrl,
    covenantReceiptUrl,
    situationId,
    transactionId,
    discount,
    discountId,
    discountAmount
  } = useSelector(state => state.serviceOrder);

  const [taxSettings, setTaxSettings] = useState(0);
  const [selectedTypePriceId, setSelectedTypePriceId] = useState();

  const contractTypes = useSelector(state => state.filters.selects).find(select => select.id == 'contract').options;
  const contractName = contractTypes?.filter(contract => contract.value == contractId)[0]?.label;

  const comissionedList = useSelector(state => state.comissionedPayrollList.list)?.map(comissioned => {
    return {
      value: comissioned?.uuid,
      label: comissioned?.name
    }
  });

  const typePricesTables = useSelector(state => state.priceTable.tables)?.map(table => {
    return {
      label: table?.typePrice,
      value: table?.typePrice
    }
  });

  const typePrices = useSelector(state => state.filters.selects)
    ?.find(select => select.id == 'typePrice')?.options
    ?.filter(typePrice => typePrice.label != '--');

  const fieldChange = registerFieldChange;
  const onValueChange = func => e => dispatch(func(e.target.name, e.target.value));
  const hasObservations = specificObs => (observations ? specificObs() : null);

  const [isEntryPhotoUrlModalOpen, setIsEntryPhotoUrlModalOpen] = useState(false);
  const [isCovenantReceiptUrlModalOpen, setIsCovenantReceiptUrlModalOpen] = useState(false);

  const hasClient = clientName || clientEmail || clientDocument;

  const accountProps = {
    pageOrigin,
    serviceOrderId,
    userId: serviceOrderUser,
    establishmentId: serviceOrderEstablishment
  }

  const handleServiceOrder = () => {
    return dispatch(loadServiceOrder(accountProps));
  }

  const handleGetOsReceipt = () => {
    const params = {
      userId,
      establishmentId: serviceOrderEstablishment,
      serviceOrderId
    }

    return getOsReceipt(params);
  }

  const getMeans = async () => {
    if(serviceOrderEstablishment) {
      try {
        const data = await dispatch(getMeansPayments(userId, serviceOrderEstablishment));

        const newPayments =
          data.content &&
          data.content.map(item => ({
            label: item.paymentMethodName,
            value: item.establishmentPaymentMethodId
          }));

        setAvailablePaymentMethods(newPayments);
      } catch (err) {
        console.log('error', err);
      }
    }
  }

  const getServicesFunction = async () => {
    if(serviceOrderEstablishment) {
      try {
        const data = await dispatch(getServices(userId, serviceOrderEstablishment));

        const newServices =
          data.content &&
          data.content.map(item => ({
            label: item.description,
            value: item.serviceId,
            amount: convertToReal(item.amount)
          }));

        setServices(newServices);
      } catch (err) {
        console.log('error', err);
      }
    }
  }

  useEffect(() => {
    dispatch(loadProfile(userId));
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await fetchUsersSituation(userId, token);

      const establishmentTaxSettings = data?.establishments
        ?.find(establishment => +establishment?.establishmentId == +serviceOrderEstablishment)
        ?.taxSettings;

      setTaxSettings(establishmentTaxSettings);
    })();
  }, []);

  useEffect(() => {
    if(!establishmentId) {
      dispatch(loadTablesFromEstablishment(serviceOrderEstablishment));
    }
  }, [establishmentId]);

  useEffect(() => {
    setSelectedTypePriceId(typePrice);
  }, [typePrice]);

  useEffect(() => {
    newServices.forEach(services => {
      if(services?.serviceUsage) {
        setHasActiveServiceUsage(true);
        return;
      }
    });
  }, [newServices]);

  useEffect(() => {
    getServicesFunction();
  }, [serviceOrderEstablishment]);

  useEffect(() => {
    getMeans();
  }, [serviceOrderEstablishment]);

  useEffect(() => {
    return () => dispatch(clearServiceOrder());
  }, []);

  useEffect(() => {
    handleServiceOrder();
  }, [serviceOrderId]);

  useEffect(() => {
    const servicesAmountList = services?.map(service => {
      return {
        amount: formatServiceAmountToNumberOnly(service.amount),
        description: service.label
      }
    });

    setServicesAmount(servicesAmountList);
  }, [services]);

  useEffect(() => {
    const formattedNewServices = submit.services?.map(service => {
      return {
        ...service,
        isComissionedOpen: false
      }
    });

    if(!isUpdating) {
      setNewServices(formattedNewServices);
    }

    if(submit.services?.length > 0 && isEstablishmentPremiumPackage) {
      dispatch(loadComissionedPayrollListSettings({
        userId,
        establishmentId: establishmentId || serviceOrderEstablishment,
        page: 1,
        limit: '',
        qp: '&profileId=2'
      }));
    }
  }, [submit.services]);

  const handleServiceComissionedOpen = index => {
    const updatedServices = [...newServices];
    updatedServices[index].isComissionedOpen = !updatedServices[index].isComissionedOpen;
    setNewServices(updatedServices);
  }

  const handleAddServiceComissioned = (uuid, index) => {
    const updatedServices = [...newServices];
    const updatedComissioned = updatedServices[index].commissioners;
    const updatedComissionedUuidList = updatedComissioned?.map(comissioned => comissioned?.uuid);

    if(updatedComissionedUuidList?.includes(uuid)) {
      return;
    }

    const selectedComissioned = {
      uuid,
      dateTime: moment(moment.now()).format('YYYY-MM-DD HH:mm:ss')
    }

    updatedServices[index].commissioners = updatedComissioned?.length > 0
      ? [...updatedComissioned, selectedComissioned]
      : [selectedComissioned];

    setNewServices(updatedServices);
  }

  const handleRemoveServiceComissioned = (uuid, index) => {
    const updatedServices = [...newServices];

    const updatedComissioned = updatedServices[index].commissioners
      ?.filter(comissioned => comissioned?.uuid != uuid);

    updatedServices[index].commissioners = updatedComissioned;

    setNewServices(updatedServices);
  }

  const handleServiceAmountChange = (amount, index) => {
    const updatedServices = [...newServices];
    const formattedAmount = VMasker.toMoney(amount);

    updatedServices[index].amount = `R$ ${formattedAmount}`;

    setNewServices(updatedServices);
  }

  const handleServiceOrders = () => {
    const extraProps = {
      startDate,
      endDate,
      exportation: false,
      userId: serviceOrderUser,
      establishmentId: establishmentId ? establishmentId : ''
    }

    const resumeProps = {
      startDate,
      endDate
    }

    dispatch(loadServiceOrders({ page, pageSize, extraProps }));
    dispatch(loadResumeOrders(userId, establishmentId, qp, resumeProps));
  }

  const sendForClientUpdate = () => {
    const selectedEstablishmentId =
      serviceOrderEstablishment || defaultEstablishmentId;

    dispatch(toggleServiceOrder(false));

    dispatch(setUserId(userId));
    dispatch(setEstablishmentId({ establishmentId: selectedEstablishmentId }));
    dispatch(setClientId(clientId));

    history.push({
      pathname: '/cadastro-de-cliente/informacoes-basicas/',
      state: {
        data: {
          userId,
          establishmentId: selectedEstablishmentId,
          clientId,
          isEdit: true
        }
      }
    });
  }

  const handleReceipt = async () => {
    await dispatch(setReceipt());
    window.location.href = '#email-send';
  }

  const handleRemoveServiceOrder = (values, actions) => {
    const { resetForm } = actions;

    const {
      reason,
      userPassword
    } = values;

    const params = {
      reason,
      userPassword
    }

    let extraProps = {}

    if(pageOrigin == 'transactions') {
      const {
        qp,
        transactionsPage,
        transactionsPageSize
      } = transactionsProps;

      extraProps = {
        transactionsQp: qp,
        transactionsPage,
        transactionsPageSize
      }
    }

    if(pageOrigin == 'storageHistory') {
      const {
        storageHistoryPage,
        storageProductUuid
      } = storageHistoryProps;

      extraProps = {
        storageHistoryPage,
        storageProductUuid
      }
    }

    dispatch(removeServiceOrder(params, accountProps, startDate, endDate, extraProps))
      .then(() => {
        resetForm();
        handleServiceOrders();
      });
  }

  const handleChangeValueService = value => {
    const itemSelected = services.filter(item => item.value === value)[0];

    const newItemSelected = {
      amount: itemSelected.amount,
      serviceId: itemSelected.value,
      description: itemSelected.label,
      quantity: itemSelected.quantity || 1,
      isNewService: true
    }

    setNewServices(newServices.concat([newItemSelected]));
  }

  const updateItemServicesQuantityAndAmount = (index, operation) => {
    const updatedServices = [...newServices];

    const description = newServices[index].description;
    const quantity = +newServices[index].quantity;

    const unitaryAmount =
      servicesAmount?.find(service => service.description == description)?.amount;

    if(operation == 'add' && !!unitaryAmount) {
      const addQuantity = quantity + 1;

      updatedServices[index].quantity = addQuantity;
      updatedServices[index].amount = calculateServiceAmountByQuantity(unitaryAmount.toString(), addQuantity);
    }

    if(operation == 'sub' && !!unitaryAmount) {
      if(quantity == 0) {
        return;
      }

      const subQuantity = quantity - 1;

      updatedServices[index].quantity = subQuantity;
      updatedServices[index].amount = calculateServiceAmountByQuantity(unitaryAmount.toString(), subQuantity);
    }

    setNewServices(updatedServices);
  }

  const handleUpdateServiceOrder = (values, actions) => {
    const { resetForm } = actions;

    const {
      reason,
      userPassword
    } = values;

    let extraProps = {}

    if(pageOrigin == 'transactions') {
      const {
        qp,
        transactionsPage,
        transactionsPageSize
      } = transactionsProps;

      extraProps = {
        transactionsQp: qp,
        transactionsPage,
        transactionsPageSize
      }
    }

    if(pageOrigin == 'storageHistory') {
      const {
        storageHistoryPage,
        storageProductUuid
      } = storageHistoryProps;

      extraProps = {
        storageHistoryPage,
        storageProductUuid
      }
    }

    const formatEntryDateTime = `${submit.entryDate} ${submit.entryTime}`;
    const formatExitDateTime = `${submit.exitDate} ${submit.exitTime}`;
    const formatPaymentDateTime = `${submit.paymentDate} ${submit.paymentTime}`;

    const getAmountServicesTotal = services => {
      const amountServicesTotal = services.reduce((serviceAcumulator, service) => {
        return serviceAcumulator?.amount + service?.amount;
      });

      return amountServicesTotal;
    }

    const servicesToSubmit = newServices.length > 0
      ? newServices : submit.services;

    const formatServicesToSubmit = servicesToSubmit
      .map(item => ({
        amount: convertToDB(formatServiceAmountToNumberOnly(item?.amount)),
        description: item?.description,
        serviceId: item?.serviceId,
        serviceUsage: item?.serviceUsage,
        serviceContractId: item?.serviceContractId,
        quantity: item?.quantity,
        commissioners: item?.commissioners?.length == 0 ? JSON.stringify([]) : item?.commissioners
      }))
      .filter(services => services.quantity > 0);

    const params = {
      entryDateTime: formatEntryDateTime,
      exitDateTime: formatExitDateTime,
      paymentDateTime: formatPaymentDateTime,
      paymentMethodId: parseInt(submit.paymentMethodId, 10),
      plate: submit.plate.toUpperCase(),
      amount: convertToDB(submit.amount),
      services: newServices.length === 0 ? JSON.stringify([]) : formatServicesToSubmit,
      cardCode: submit.cardCode,
      typePrice: selectedTypePriceId,
      reason,
      userPassword,
      amountServices: formatServicesToSubmit.length > 1
        ? getAmountServicesTotal(formatServicesToSubmit)
        : formatServicesToSubmit[0]?.amount
    }

    dispatch(upsertServiceOrder(params, accountProps, startDate, endDate, extraProps)).then(() => {
      resetForm();
      handleServiceOrder();
      handleServiceOrders();
    });
  }

  const handleSendServiceOrder = (values, actions) => {
    const { resetForm } = actions;
    const params = { serviceOrderId, ...values }

    dispatch(sendReceipt(params, accountProps)).then(() => {
      resetForm();
    });
  }

  const handleRemoveDiscount = () => {
    dispatch(removeDiscount(userId, serviceOrderEstablishment, serviceOrderId));
    setIsRemovingDiscount(false);
  }

  const handleCloseServiceOrder = () => {
    dispatch(clearChanges());
    dispatch(toggleServiceOrder(false));
  }

  const changeDate = (field, date) => {
    dispatch(onConvertAndChangeData(field, date));
  }

  const handleSendToNfse = () => {
    return window.open(nfseLink, '_blank');
  }

  const formatServiceAmountToNumberOnly = amount => {
    return amount?.replaceAll('R$', '').trim();
  }

  const calculateServiceAmountByQuantity = (amount, quantity) => {
    const formattedAmount = parseFloat(formatServiceAmountToNumberOnly(amount?.toString()));
    const formattedQuantity = parseFloat(quantity);

    const amountTotal = formattedAmount * formattedQuantity;

    return convertToBrl(amountTotal);
  }

  return(
    <Modal
      id="service-order"
      title="Informações da OS"
      scroll="body"
      open={modal}
      onClose={handleCloseServiceOrder}
      maxWidth={hasClient || newServices.length > 0 ? 'lg' : 'sm'}
      modalActions={
        <Collapse in={!isRemoving && !isUpdating && !isSending}>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                color="error"
                disabled={isLoading || userRestrictions?.includes(SITE_RESTRICTIONS.REMOVE_OS) || isFromStorageHistory}
                onClick={() => dispatch(setRemove())}
              >
                Remover OS
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                disabled={!financialSituationId || isLoading || userRestrictions?.includes(SITE_RESTRICTIONS.EDIT_OS)}
                onClick={() => dispatch(setUpdate())}
              >
                Editar OS
              </Button>
            </Grid>
            <Grid item>
              <ExportPDF
                exportType="receipt"
                color="success"
                disabled={isLoading || isFromStorageHistory}
                requestData={handleGetOsReceipt}
              >
                Baixar recibo
              </ExportPDF>
            </Grid>
            <Grid item>
              <Button
                color="success"
                disabled={!financialSituationId || isLoading}
                onClick={handleReceipt}
              >
                Enviar recibo
              </Button>
            </Grid>
            {(+taxSettings == 1 && !!transactionId && +financialSituationId == 3 && +nfseStatus == 0) && (
              <Grid item>
                <ButtonConfirmation
                  title="Enviar NFS-e"
                  color="success"
                  disabled={!financialSituationId || isLoading}
                  loading={isLoading || isSendNfseLoading}
                  modalTitle="Enviar NFS-e"
                  modalText="Tem certeza que deseja enviar a nota fiscal?"
                  modalButtons={[
                    {
                      title: "Sim",
                      color: "success",
                      onClick: () => dispatch(sendNfse(userId, serviceOrderEstablishment, transactionId))
                    }
                  ]}
                />
              </Grid>
            )}
            {nfseLink ? (
              <Grid item>
                <Button
                  color="success"
                  disabled={isLoading}
                  onClick={handleSendToNfse}
                >
                  Visualizar nota fiscal
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </Collapse>
      }
    >
      <Modal
        id="service-order-entryPhotoUrl"
        title="Placa"
        scroll="body"
        open={isEntryPhotoUrlModalOpen}
        onClose={() => setIsEntryPhotoUrlModalOpen(false)}
      >
        <img
          src={entryPhotoUrl}
          style={{ width: '100%', height: 'auto' }}
        />
      </Modal>
      <Modal
        id="service-order-covenantReceiptUrl"
        title="Tipo de cobrança"
        scroll="body"
        open={isCovenantReceiptUrlModalOpen}
        onClose={() => setIsCovenantReceiptUrlModalOpen(false)}
      >
        <img
          src={covenantReceiptUrl}
          style={{ width: '100%', height: 'auto' }}
        />
      </Modal>
      <Loader isLoading={isLoading}>
        <Formik
          enableReinitialize
          initialValues={submit}
          validationSchema={isRemoving ? removeValidations : validations}
          onSubmit={isRemoving ? handleRemoveServiceOrder : handleUpdateServiceOrder}
        >
          {({ ...formikProps }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item md={hasClient || newServices.length > 0 ? 6 : 12} xs={12}>
                  <Card elevation={0} className={classes.card}>
                    <List
                      disablePadding
                      className={classes.list}
                      style={{ marginTop: 8 }}
                    >
                      <Item primary="Código da OS:">{serviceOrderCode}</Item>
                      <Item primary="Cartão:">{cardCode}</Item>
                      <Item>
                        {isUpdating && (
                          <Input
                            name="cardCode"
                            type="number"
                            label="Cartão"
                            placeholder="Digite o número do cartao"
                            value={submit.cardCode || cardCode}
                            onChange={onValueChange(fieldChange)}
                          />
                        )}
                      </Item>
                      <Item primary="N˚ de série RPS:">{rpsSeries}</Item>
                      <Item primary="N˚ RPS:">{rpsNumber}</Item>
                      <Item primary="N˚ NFSe:">{nfseNumber}</Item>
                    </List>
                    <List disablePadding className={classes.list}>
                      <Item primary="Operador de entrada:">{entryUserName}</Item>
                      <Item primary="Operador de saída:">{exitUserName}</Item>
                    </List>
                    <List disablePadding className={classes.list}>
                      <Item primary="Período:">{permanence}</Item>
                      <Item primary="Entrada:">
                        {isUpdating ? (
                          <Grid container spacing={2}>
                            <Grid item sm={6} xs={12}>
                              <SingleDatePicker
                                name="entryDate"
                                label="Data da entrada"
                                initialDate={submit.entryDate}
                                onDateChange={date => changeDate('entryDate', date)}
                              />
                            </Grid>
                            <Grid item sm={6} xs={12}>
                              <Input
                                name="entryTime"
                                type="text"
                                label="Hora da entrada"
                                placeholder="00:00:00"
                                maxLength={8}
                                value={VMasker.toPattern(submit.entryTime, '99:99:99')}
                                error={formikProps.errors.entryTime}
                                helperText={formikProps.errors.entryTime}
                                onChange={onValueChange(fieldChange)}
                              />
                            </Grid>
                          </Grid>
                        ) : (
                          `${entryDateTime}`
                        )}
                      </Item>
                      {situationId != 1 && (
                        <Item primary="Saída:">
                          {isUpdating ? (
                            <Grid container spacing={2}>
                              <Grid item sm={6} xs={12}>
                                <SingleDatePicker
                                  name="exitDate"
                                  label="Data de saída"
                                  initialDate={submit.exitDate}
                                  onDateChange={date => changeDate('exitDate', date)}
                                />
                              </Grid>
                              <Grid item sm={6} xs={12}>
                                <Input
                                  name="exitTime"
                                  type="text"
                                  label="Hora da saída"
                                  placeholder="00:00:00"
                                  maxLength={8}
                                  value={VMasker.toPattern(submit.exitTime, '99:99:99')}
                                  error={formikProps.errors.exitTime}
                                  helperText={formikProps.errors.exitTime}
                                  onChange={onValueChange(fieldChange)}
                                />
                              </Grid>
                            </Grid>
                          ) : (
                            `${exitDateTime}`
                          )}
                        </Item>
                      )}
                      {financialSituationId !== 5 &&
                      financialSituationId !== 6 &&
                      paymentDateTime &&
                      paymentDateTime !== '0001-01-01 00:00:00' &&
                      paymentDateTime !== 'Invalid date' &&
                      paymentDateTime !== 'Data inválida' && (
                        <Item primary="Data de pagamento:">
                          {isUpdating ? (
                            <Grid container spacing={2}>
                              <Grid item sm={6} xs={12}>
                                <SingleDatePicker
                                  name="paymentDate"
                                  label="Data do pagamento"
                                  initialDate={submit.paymentDate}
                                  onDateChange={date => changeDate('paymentDate', date)}
                                />
                              </Grid>
                              <Grid item sm={6} xs={12}>
                                <Input
                                  name="paymentTime"
                                  type="text"
                                  label="Hora do Pagamento"
                                  placeholder="00:00:00"
                                  maxLength={8}
                                  value={VMasker.toPattern(submit.paymentTime, '99:99:99')}
                                  error={formikProps.errors.paymentTime}
                                  helperText={formikProps.errors.paymentTime}
                                  onChange={onValueChange(fieldChange)}
                                />
                              </Grid>
                            </Grid>
                          ) : (
                            `${paymentDateTime}`
                          )}
                        </Item>
                      )}
                    </List>
                    <List disablePadding className={classes.list}>
                      {isUpdating && (
                        <Item primary="Placa:">
                          <Grid>
                            <Input
                              name="plate"
                              type="plate"
                              label="Placa"
                              placeholder="AAA-0000"
                              value={submit.plate}
                              onChange={onValueChange(fieldChange)}
                            />
                          </Grid>
                        </Item>
                      )}
                      {!isUpdating && (
                        <ListItem>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                          >
                            <Typography
                              variant="body1"
                              color="primary"
                              className={classes.title}
                            >
                              Placa:
                            </Typography>
                            <Link
                              target="_blank"
                              onClick={() => {
                                entryPhotoUrl != null && entryPhotoUrl != ''
                                  ? setIsEntryPhotoUrlModalOpen(true)
                                  : setIsEntryPhotoUrlModalOpen(false);
                              }}
                            >
                              {(entryPhotoUrl != null && entryPhotoUrl != '') && (
                                <FontAwesomeIcon
                                  style={{ marginRight: 10, fontSize: 16 }}
                                  color='primary'
                                  icon={faImage}
                                />
                              )}
                              {plate ? plate : "Link para foto"}
                            </Link>
                          </Grid>
                        </ListItem>
                      )}
                      <Item primary="Veículo:">
                        {vehicleModel && `${vehicleModel} - ${vehicleColor}`}
                      </Item>
                    </List>
                    <List disablePadding className={classes.list}>
                      <Item primary="Tipo de contrato:">{contractName}</Item>
                      {(+servicesOnly != 1 && isUpdating) && (
                        <Item primary="Tipo de cobrança:">
                          <Grid item xs={12}>
                            <Select
                              name="typePriceId"
                              label="Selecione o tipo"
                              options={establishmentId ? typePrices : typePricesTables}
                              value={selectedTypePriceId}
                              onChange={event => setSelectedTypePriceId(event.target.value)}
                            />
                          </Grid>
                        </Item>
                      )}
                      {(+servicesOnly != 1 && !isUpdating && (typePrice != '' || typePrice != null)) && (
                        <ListItem>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                          >
                            <Typography
                              variant="body1"
                              color="primary"
                              className={classes.title}
                            >
                              Tipo de cobrança:
                            </Typography>
                            <Link
                              target="_blank"
                              onClick={() => {
                                covenantReceiptUrl != null && covenantReceiptUrl != ''
                                  ? setIsCovenantReceiptUrlModalOpen(true)
                                  : setIsCovenantReceiptUrlModalOpen(false);
                              }}
                            >
                              {(covenantReceiptUrl != null && covenantReceiptUrl != '') && (
                                <FontAwesomeIcon
                                  style={{ marginRight: 10, fontSize: 16 }}
                                  color='primary'
                                  icon={faImage}
                                />
                              )}
                              {typePrice}
                            </Link>
                          </Grid>
                        </ListItem>
                      )}
                      {financialSituationId !== 5 && financialSituationId !== 6 && (
                        <Item primary="Meio de pagamento:">
                          {isUpdating ? (
                            <Grid item xs={12}>
                              <Select
                                name="paymentMethodId"
                                label="Selecione o meio de pagamento"
                                options={availablePaymentMethods}
                                value={submit.paymentMethodId}
                                onChange={onValueChange(fieldChange)}
                              />
                            </Grid>
                          ) : (
                            `${paymentMethodName || ''}`
                          )}
                        </Item>
                      )}
                      {paymentCode && (
                        <Item primary="Código de pagamento:">{paymentCode}</Item>
                      )}
                    </List>
                    <List disablePadding className={classes.list}>
                      {(+servicesOnly == 0) && (
                        <>
                          {((typePrice !== null || typePrice !== '') && servicesOnly !== 0) || (financialSituationId !== 5 && (
                            <Item primary="Valor:">
                              {isUpdating ? (
                                <Grid item>
                                  <Input
                                    name="amount"
                                    type="money"
                                    label="Valor"
                                    placeholder="R$ 0,00"
                                    value={submit.amount}
                                    onChange={onValueChange(fieldChange)}
                                  />
                                </Grid>
                              ) : (
                                `${amount}`
                              )}
                            </Item>
                          ))}
                        </>
                      )}
                      {hasObservations(() => (
                        <Item primary="Observação geral:">
                          {observations.observation}
                        </Item>
                      ))}
                      {hasObservations(() => (
                        <Item primary="Observação de edição:">
                          {observations.editObservation}
                        </Item>
                      ))}
                      {hasObservations(() => (
                        <Item primary="Observação de cancelamento:">
                          {observations.cancelObservation}
                        </Item>
                      ))}
                      {hasObservations(() => (
                        <Item primary="Observação de remoção:">
                          {observations.deleteObservation}
                        </Item>
                      ))}
                      {hasObservations(() => (
                        <Item primary="Observação de edição de valor:">
                          {observations.changePriceObservation}
                        </Item>
                      ))}
                    </List>
                  </Card>
                  {isRemovingDiscount && (
                    <ModalConfirmation
                      open={isRemovingDiscount}
                      onClose={() => setIsRemovingDiscount(false)}
                      handleConfirmation={handleRemoveDiscount}
                    >
                      Tem certeza que deseja remover o desconto desta OS?
                    </ModalConfirmation>
                  )}
                  {!!discount && (
                    <Grid
                      elevation={0}
                      className={classes.card}
                      style={{ position: 'relative', marginTop: 30, marginBottom: 30 }}
                    >
                      <Button
                        size='small'
                        variant='contained'
                        color='error'
                        style={{ minWidth: 0, padding: 10, position: 'absolute', right: '-15px', top: '-20px' }}
                        onClick={() => setIsRemovingDiscount(true)}
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          color='#FFFFFF'
                        />
                      </Button>
                      <List>
                        <Item primary="Desconto:">
                          {discount?.typeId == 1 ? convertToBrl(parseFloat(discount?.amount)) : `${discount?.amount} %`}
                        </Item>
                        <Item primary="Data de criação:">{moment(discount?.registerDateTime)?.format('DD/MM/YYYY HH:mm:ss')}</Item>
                        <Item primary="Usuário de criação:">{discount?.registerUserName}</Item>
                      </List>
                    </Grid>
                  )}
                  <Grid>
                    <Card elevation={0} className={classes.card}>
                      <List>
                        {!!discountId && (
                          <Item primary="Valor do desconto:">{convertToBrl(parseFloat(discountAmount))}</Item>
                        )}
                        <Item primary="Valor total:">{amountTotal}</Item>
                      </List>
                    </Card>
                  </Grid>
                </Grid>
                {hasClient || newServices.length > 0 ? (
                  <Grid item md={6} xs={12}>
                    <Collapse in={hasClient}>
                      <Card elevation={0} className={classes.card}>
                        <CardContent>
                          <Grid>
                            <Input
                              disabled
                              placeholder="Cliente"
                              value={clientName}
                            />
                          </Grid>
                          <Grid>
                            <Input
                              disabled
                              placeholder="E-mail"
                              value={clientEmail}
                            />
                          </Grid>
                          <Grid>
                            <Input
                              disabled
                              type="document"
                              placeholder="CPF | CNPJ"
                              value={clientDocument}
                            />
                          </Grid>
                          {!userRestrictions?.includes(SITE_RESTRICTIONS.EDIT_CLIENT) && (
                            <Grid>
                              <Button
                                block
                                type="button"
                                color="primary"
                                disabled={!financialSituationId || isLoading}
                                onClick={sendForClientUpdate}
                              >
                                Editar cliente
                              </Button>
                            </Grid>
                          )}
                        </CardContent>
                      </Card>
                    </Collapse>
                    {newServices && newServices.length > 0 && 
                      <Collapse in={true}>
                        <Card elevation={0} className={classes.card}>
                          <List>
                            <ListItem>
                              <Typography variant="h6" color="primary">Serviços</Typography>
                            </ListItem>
                            {!isUpdating && newServices.length > 0
                              ? newServices.map((service, index) => (
                                <Grid style={{ display: 'flex', flexDirection: 'row', padding: 8 }}>
                                  <Typography
                                    color='primary'
                                    style={{ marginLeft: 10 }}
                                  >
                                    {service.quantity}
                                  </Typography>
                                  <Typography
                                    color='primary'
                                    style={{ marginLeft: 15, marginRight: 15 }}
                                  >
                                    {service.description}
                                  </Typography>
                                  <Typography color='primary'>
                                    {service?.serviceContractId ? service?.serviceUsage : service?.amount}
                                  </Typography>
                                </Grid>
                              ))
                              : newServices.map((service, index) => (
                                <>
                                  <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: 8 }}>
                                    <Grid style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                      <MUIButton
                                        size='small'
                                        onClick={() => updateItemServicesQuantityAndAmount(index, 'sub')}
                                      >
                                        <FontAwesomeIcon
                                          icon={faMinus}
                                          color='primary'
                                        />
                                      </MUIButton>
                                      <Typography
                                        color='primary'
                                        style={{ marginLeft: 10, marginRight: 10, fontSize: 18 }}
                                      >
                                        {service?.quantity}
                                      </Typography>
                                      <MUIButton
                                        size='small'
                                        onClick={() => updateItemServicesQuantityAndAmount(index, 'add')}
                                      >
                                        <FontAwesomeIcon
                                          icon={faPlus}
                                          color='primary'
                                        />
                                      </MUIButton>
                                    </Grid>
                                    <Typography
                                      color='primary'
                                      style={{ marginLeft: 15, marginRight: 15 }}
                                    >
                                      {service?.description}
                                    </Typography>
                                    <Grid style={{ width: '33%' }}>
                                      <Input
                                        name={`service.${index}`}
                                        type="number"
                                        placeholder="R$ 0,00"
                                        disabled={
                                          (!financialSituationId || isLoading || hasActiveServiceUsage) &&
                                          !service?.isNewService &&
                                          service?.serviceContractId
                                        }
                                        value={
                                          service?.serviceContractId
                                            ? service?.serviceUsage
                                            : service?.amount
                                        }
                                        onChange={event => handleServiceAmountChange(event.target.value, index)}
                                      />
                                    </Grid>
                                    <Grid>
                                      <Button
                                        type='button'
                                        color='primary'
                                        style={{ height: 45, marginLeft: 10 }}
                                        onClick={() => handleServiceComissionedOpen(index)}
                                      >
                                        <FontAwesomeIcon
                                          icon={service?.isComissionedOpen ? faList : faUserPlus}
                                          color='#FFFFFF'
                                        />
                                      </Button>
                                    </Grid>
                                  </Grid>
                                  {(service?.isComissionedOpen && service?.commissioners?.length > 0) && (
                                    <Item>
                                      <Grid container style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, marginBottom: 10 }}>
                                        {service?.commissioners?.map(commissioned => (
                                          <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                                            <Typography
                                              color='primary'
                                              style={{ marginRight: 10 }}
                                            >
                                              {comissionedList?.find(list => list?.value == commissioned?.uuid)?.label}
                                            </Typography>
                                            <MUIButton
                                              type='button'
                                              size='small'
                                              onClick={() => handleRemoveServiceComissioned(commissioned?.uuid, index)}
                                            >
                                              <FontAwesomeIcon
                                                icon={faTrash}
                                                color='#E53935'
                                              />
                                            </MUIButton>
                                          </Grid>
                                        ))}
                                      </Grid>
                                    </Item>
                                  )}
                                  {service?.isComissionedOpen && (
                                    <Item>
                                      <Grid container>
                                        <Select
                                          name="comissioned"
                                          label="Adicionar comissionado"
                                          options={comissionedList}
                                          onChange={event => handleAddServiceComissioned(event.target.value, index)}
                                        />
                                      </Grid>
                                    </Item>
                                  )}
                                </>
                              ))
                            }
                            {isUpdating && (
                              <Item>
                                <Grid container>
                                  <Select
                                    name="services"
                                    label="Cadastrar serviço"
                                    options={services}
                                    value={listServices}
                                    onChange={e => handleChangeValueService(e.target.value)}
                                  />
                                </Grid>
                              </Item>
                            )}
                          </List>
                        </Card>
                      </Collapse>
                    }
                  </Grid>
                ) : null}
              </Grid>
              <Collapse in={isUpdating || isRemoving}>
                {isUpdating && (nfseNumber != null && nfseNumber != '' && nfseNumber != '0') && (
                  <Grid container spacing={2}>
                    <Grid item className={classes.warningBox}>
                      <Typography className={classes.warningBoxTypography}>
                        Após a edição, o valor da nota fiscal, já emitida, não será alterado.
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                {isRemoving && (nfseNumber != null && nfseNumber != '' && nfseNumber != '0') && (
                  <Grid container spacing={2}>
                    <Grid item className={classes.warningBox}>
                      <Typography className={classes.warningBoxTypography}>
                        Após a remoção, a nota fiscal poderá ser cancelada. Verifique no portal da prefeitura.
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={2}>
                  <Grid item sm={hasClient || newServices.length > 0 ? 8 : 12} xs={12}>
                    <Input
                      noAutoComplete
                      name="reason"
                      type="textarea"
                      label="Observação"
                      placeholder="Digite sua observação"
                      value={formikProps.values.reason}
                      error={formikProps.errors.reason}
                      helperText={formikProps.errors.reason}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                  <Grid item sm={hasClient || newServices.length > 0 ? 4 : 12} xs={12}>
                    <Input
                      noAutoComplete
                      name="userPassword"
                      type="password"
                      label="Senha"
                      placeholder="Digite sua senha de acesso ao sistema"
                      value={formikProps.values.userPassword}
                      error={formikProps.errors.userPassword}
                      helperText={formikProps.errors.userPassword}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} justify="flex-end">
                  <Grid item>
                    <Button
                      color="error"
                      onClick={() => {
                        setNewServices(submit.services);
                        dispatch(clearChanges());
                      }}
                    >
                      {isRemoving ? 'Cancelar remoção' : 'Cancelar alteração'}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      color="primary"
                      disabled={isLoading}
                    >
                      {isRemoving ? 'Concluir remoção' : 'Concluir alteração'}
                    </Button>
                  </Grid>
                </Grid>
              </Collapse>
            </Form>
          )}
        </Formik>
        <Collapse in={isSending}>
          <Formik
            enableReinitialize
            initialValues={{ email: '' }}
            validationSchema={emailValidation}
            onSubmit={handleSendServiceOrder}
          >
            {({ ...formikProps }) => (
              <Form>
                <Grid id="email-send">
                  <Input
                    name="email"
                    label="E-mail"
                    placeholder="usuario@exemplo.com.br"
                    value={formikProps.values.email}
                    error={formikProps.errors.email}
                    helperText={formikProps.errors.email}
                    onChange={formikProps.handleChange}
                  />
                </Grid>
                <Grid container spacing={2} justify="flex-end">
                  <Grid item>
                    <Button
                      color="error"
                      disabled={isLoading}
                      onClick={() => dispatch(clearChanges())}
                    >
                      Cancelar envio
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button color="primary" disabled={isLoading}>
                      Concluir envio
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Collapse>
      </Loader>
    </Modal>
  );
}