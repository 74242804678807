import { compose, over, set, lensProp, lensPath, map, type } from 'ramda';
import { renameProp } from '../../../helpers/common';

import {
  REGISTER_FIELD_CHANGE,
  SET_RPS_INFO,
  SET_RPS_ERRORS,
  CLEAR_RPS_ERRORS,
  SET_SERVICES,
  SET_ECONOMIC_ACTIVIES,
  SET_CERTIFICATE_FILE,
  HANDLE_LOADING,
  HANDLE_CERTIFICATE_LOADING,
  CLEAR_RPS_INFO,
  CLEAR_CERTIFICATE,
  SET_LOGO_FILE,
  SET_DATA_PAGE,
  SET_CITY_CODE,
  SET_IS_CITY_LOADED,
  SET_WARNING_MESSAGE,
  SET_RESTRICTIONS,
  SET_CONFIG_TYPE,
  SET_ESTABLISHMENTS_WITH_CONFIG,
  SET_SELECTED_ESTABLISHMENTS_WITH_CONFIG,
  SET_LAST_RPS_SUCCESS,
  IS_RPS_CONFIG,
  SET_IS_SEND_NFSE_LOADING
} from './constants';

const defaultState = {
  dataPage: {},
  isLoading: false,
  isSendNfseLoading: false,
  isCertificateLoading: false,
  availableCities: [],
  availableServices: [],
  availableEconomicActivities: [],
  establishmentsWithConfig: [],
  selectedEstablishmentsWithConfig: null,
  certificateFile: null,
  certificateFileFromAPI: null,
  logoFile: null,
  isCityLoaded: true,
  warningMessageCheckbox: false,
  restrictionsCheckbox: false,
  configType: 1,
  isRpsConfig: false,
  lastRpsSuccess: {
    series: null,
    number: null
  },
  submitErrors: [],
  submit: {
    documentId: null,
    municipalSubscription: null,
    stateSubscription: null,
    statusRps: 1,
    simpleNational: 2,
    encouraging: 2,
    issRetain: 2,
    automaticSendNfse: 0,
    districtTributationId: null,
    taxationRegime: null,
    issChargeability: null,
    cityCode: null,
    activityCode: null,
    discrimination: null,
    accessKey: null,
    certificate: null,
    autenticatorPassword: null,
    autenticatorPasswordConfirm: null,
    autenticatorToken: null,
    autenticatorUser: null,
    password: null,
    passwordConfirm: null,
    taxSettingsOwner: 1,
    establishmentTaxSettingOwner: {},
    customSettings: {
      suportaEmissaoNFeSemCliente: {
        text: '',
        status: '0'
      },
      suportaEmissaoNFeClienteSemCpf: {
        text: '',
        status: '0'
      },
      suportaEmissaoNFeClienteSemEndereco: {
        text: '',
        status: '0'
      }
    },
    activityCodeList: {
      description: '',
      serviceCode: '',
      code: '',
      aliquot: ''
    },
    automaticGenerate: {
      serviceOrders: false,
      invoices: false,
      selectedTransactions: false,
      paymentMethods: []
    }
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case REGISTER_FIELD_CHANGE: {
      const field = action.field.split('.');
      let { value } = action;

      if(field[0] === 'statusRps') {
        value = parseInt(value, 10);
      }

      if(field.length > 1) {
        return set(lensPath(['submit', field[0], field[1]]), value, state);
      }

      return set(lensPath(['submit', field[0]]), value, state);
    }
    case SET_RPS_INFO: {
      const formatProvisionalReceiptInfo = compose(
        over(lensProp('automaticGenerate'), obj => {
          if(type(obj) === 'Array') {
            if(obj.length === 0) {
              return defaultState.submit.automaticGenerate;
            }
          }

          const convertToNumber = arr =>
            arr && map(str => parseInt(str, 10), arr);

          return over(lensProp('paymentMethods'), convertToNumber, obj);
        }),
        over(lensProp('activityCode'), code => parseInt(code, 10)),
        over(lensProp('activityCodeList'), list => {
          if(!list) {
            return {};
          }

          try {
            return JSON.parse(list)[0];
          } catch(error) {
            return !!list[0] ? list[0] : {};
          }
        })
      );

      const rps = compose(
        set(lensProp('isLoading'), false),
        set(lensProp('certificateFile'), null),
        set(lensProp('certificateFileFromAPI'), action?.data?.certificateFile),
        set(lensProp('submit'), formatProvisionalReceiptInfo(action?.data)),
        set(lensPath(['submit', 'autenticatorToken']), action?.data?.autenticatorToken),
        set(lensPath(['submit', 'autenticatorPassword']), action?.data?.autenticatorPassword),
        set(lensPath(['submit', 'autenticatorUser']), action?.data?.autenticatorUser)
      );

      return rps(state);
    }
    case SET_RPS_ERRORS: {
      return {
        ...state,
        submitErrors: action.errors
      }
    }
    case CLEAR_RPS_ERRORS: {
      return {
        ...state,
        submitErrors: []
      }
    }
    case SET_SERVICES: {
      const formatServices = data =>
        data &&
        map(
          compose(
            renameProp('code', 'value'),
            renameProp('description', 'label')
          ),
          data
        );
      const services = compose(
        set(lensProp('isLoading'), false),
        set(lensProp('availableServices'), formatServices(action?.data))
      );

      return services(state);
    }
    case SET_ECONOMIC_ACTIVIES: {
      const formatActivitiesOptions = options => {
        const { code } = options;
        const convertOption = compose(
          over(lensProp('label'), description => `${code} - ${description}`),
          renameProp('description', 'label'),
          renameProp('code', 'value')
        );

        return convertOption(options);
      }
      const formatActivities = data =>
        data && map(formatActivitiesOptions, data);

      const economicActivities = compose(
        set(lensProp('isLoading'), false),
        set(
          lensProp('availableEconomicActivities'),
          formatActivities(action?.data)
        )
      );

      return economicActivities(state);
    }
    case SET_CERTIFICATE_FILE: {
      return set(lensProp('certificateFile'), action?.file, state);
    }
    case SET_LOGO_FILE: {
      return set(lensProp('logoFile'), action?.file, state);
    }
    case HANDLE_LOADING: {
      return set(lensProp('isLoading'), action?.status, state);
    }
    case HANDLE_CERTIFICATE_LOADING: {
      return set(lensProp('isCertificateLoading'), action?.status, state);
    }
    case CLEAR_RPS_INFO: {
      const clear = compose(
        set(lensProp('availableCities'), state.availableCities),
        set(lensProp('availableServices'), state.availableServices),
        set(lensProp('availableEconomicActivities'), state.availableEconomicActivities),
        set(lensProp('establishmentsWithConfig'), state.establishmentsWithConfig)
      );

      return clear(defaultState);
    }
    case CLEAR_CERTIFICATE: {
      const clear = compose(
        set(lensPath(['submit', 'password']), ''),
        set(lensPath(['submit', 'passwordConfirm']), ''),
        set(lensPath(['submit', 'certificate']), null),
        set(lensProp('certificateFile'), null),
        set(lensProp('isCertificateLoading'), false)
      );

      return clear(state);
    }
    case SET_CITY_CODE: {
      const newCityCode = compose(set(lensPath(['submit', 'cityCode']), action?.code));
      return newCityCode(state);
    }
    case SET_DATA_PAGE: {
      return {
        ...state,
        dataPage: action?.data,
      }
    }
    case SET_IS_CITY_LOADED: {
      return {
        ...state,
        isCityLoaded: action?.isCityLoaded
      }
    }
    case SET_WARNING_MESSAGE: {
      return {
        ...state,
        warningMessageCheckbox: action?.checked
      }
    }
    case SET_RESTRICTIONS: {
      return {
        ...state,
        restrictionsCheckbox: action?.checked
      }
    }
    case SET_CONFIG_TYPE: {
      return {
        ...state,
        configType: action?.config
      }
    }
    case SET_ESTABLISHMENTS_WITH_CONFIG: {
      return {
        ...state,
        establishmentsWithConfig: action?.establishments
      }
    }
    case SET_SELECTED_ESTABLISHMENTS_WITH_CONFIG: {
      return {
        ...state,
        selectedEstablishmentsWithConfig: action?.id
      }
    }
    case SET_LAST_RPS_SUCCESS: {
      return {
        ...state,
        lastRpsSuccess: action?.rps
      }
    }
    case IS_RPS_CONFIG: {
      return {
        ...state,
        isRpsConfig: action?.isConfig
      }
    }
    case SET_IS_SEND_NFSE_LOADING: {
      return {
        ...state,
        isSendNfseLoading: action?.isLoading
      }
    }
    default: {
      return state;
    }
  }
}