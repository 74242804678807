import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { concat } from 'ramda';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faExclamationCircle, faLink } from '@fortawesome/free-solid-svg-icons';

import { Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Filter from '../../../components/Filter/components';
import FilterMobile from '../../../components/FilterMobile/components';
import MTable from '../../../components/MTable';
import ServiceOrder from '../../../components/ServiceOrder/components';
import Invoice from '../../../components/Invoice';
import InvoiceServiceContract from 'components/InvoiceServiceContract';
import Receipt from '../../Transactions/components/Receipt';
import Payment from '../../Transactions/components/Payment';
import ProvisionalReceiptError from './ProvisionalReceiptError';

import { loadProvisionalReceipt } from '../store/thunk';
import { handleError } from '../store/actions';
import { formatFilters } from '../../../helpers/formatters';
import { convertList } from '../../../helpers/converters';
import { loadFilters } from '../../../components/Filter/store/thunk';
import { loadSingleTransaction } from 'pages/Transactions/store/thunk';
import { setServiceOrderId } from '../../../components/ServiceOrder/store/actions';
import { toggleServiceOrder } from '../../OperationalHistory/store/actions';
import { setClientId } from '../../ClientRegistration/components/steps/BasicInfo/store/actions';
import { setEstablishmentId } from '../../BusinessInfo/store/actions';
import { setInvoiceId, handleUpdateInvoice } from '../../ClientInvoices/store/actions';
import { handleRegistration, setTransactionDetails } from '../../Transactions/store/actions';

import { setBaseAPI } from '../../../services';

import { inputs } from './resources';

const headers = [
  { title: 'Número da nota', field: 'nfseNumber', sortable: false },
  { title: 'Série e N˚(RPS)', field: 'rpsSerieAndNumber', sortable: false },
  { title: 'Emissão', field: 'nfseDateTime', sortable: false },
  { title: 'Competência', field: 'paymentDateTimeView', sortable: false },
  { title: 'Status', field: 'nfseStatus', sortable: true, defaultSort: 'desc' },
  { title: 'Valor', field: 'amountView', sortable: false }
];

const width = window.innerWidth;
const tableWidth = width < 768 ? 160 : 100;

export default function ProvisionalReceipt() {
  const dispatch = useDispatch();

  const width = window.innerWidth;
  const isMobile = width < 1024;

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { modal } = useSelector(state => state.operationalHistory);
  const { isUpdatingInvoice } = useSelector(state => state.clientInvoices);
  const { registration, submit: { transactionTypeId } } = useSelector(state => state.transactions);
  const { selects, qp } = useSelector(state => state.filters);
  const { startDate, endDate } = useSelector(state => state.dateFilter);
  const { list, total, isLoading, error } = useSelector(state => state.provisionalReceipt);

  const filters = concat(inputs, formatFilters(['nfseStatus'], selects));
  const convertedList = convertList(['nfseStatus'], filters, list);

  const [isOpenInvoiceServiceContract, setIsOpenInvoiceServiceContract] = useState(false);
  const [calculatedInvoiceDateTime, setCalculatedInvoiceDateTime] = useState('');

  const [provisionalReceiptPage, setProvisionalReceiptPage] = useState(1);
  const provisionalReceiptPageSize = 10;
  const provisionalReceiptPageCount = Math.ceil(total / provisionalReceiptPageSize);

  const handleProvisionalReceiptList = ({ page, qp }) => {
    const extraProps = { startDate, endDate };
    dispatch(loadProvisionalReceipt({ userId, establishmentId, page, pageSize: provisionalReceiptPageSize, qp, extraProps }));
  }

  useEffect(() => {
    dispatch(handleError({ error: [], transactionId: null }));
  }, []);

  useEffect(() => {
    if(startDate !== 'Invalid date' && endDate !== 'Invalid date') {
      handleProvisionalReceiptList({ page: 1, qp });
      dispatch(loadFilters(userId, establishmentId));
    }
  }, [establishmentId, startDate, endDate]);

  useEffect(() => {
    handleProvisionalReceiptList({ page: provisionalReceiptPage, qp });
  }, [provisionalReceiptPage]);

  const goToLink = nfseLink => window.open(nfseLink, '_blank');

  const calculateInvoiceDateTime = (sourceId, clientId) => {
    const newInvoiceDateTime = sourceId?.toString()?.replace(clientId?.toString(), "");
    return newInvoiceDateTime;
  }

  const handleTransactionRegistration = async (status, modalType) => {
    dispatch(handleRegistration(status, modalType));
  }

  const selectTransactionType = async (
    establishmentId,
    sourceId,
    categorySourceId,
    clientId = null,
    data = null
  ) => {
    if(categorySourceId === 1) {
      await dispatch(setServiceOrderId(sourceId, userId, establishmentId));
      await dispatch(toggleServiceOrder(true));
    }

    if(categorySourceId === 2) {
      await dispatch(setClientId(clientId));
      await dispatch(setEstablishmentId({ establishmentId }));
      await dispatch(setInvoiceId(sourceId));
      await setBaseAPI(userId, establishmentId);
      await dispatch(handleUpdateInvoice(true));
    }

    if(categorySourceId === 3) {
      const singleTransactionData = await dispatch(loadSingleTransaction(userId, establishmentId, data?.transactionId));

      if(singleTransactionData) {
        const { transactionTypeId } = singleTransactionData;

        await handleTransactionRegistration(true, +transactionTypeId).then(async () => {
          await dispatch(setTransactionDetails({ ...singleTransactionData }));
        });
      }
    }

    if(categorySourceId === 8) {
      await dispatch(setClientId(clientId));
      setCalculatedInvoiceDateTime(calculateInvoiceDateTime(sourceId, clientId));
      setIsOpenInvoiceServiceContract(true);
    }
  }

  useEffect(() => {
    if(!(headers.filter(header => header.field === 'actions').length > 0)) {
      headers.push({
        title: 'Ações',
        field: 'actions',
        render: rowData => {
          const {
            nfseStatusNumber,
            sourceId,
            transactionId,
            categorySourceId,
            clientId,
            establishmentId
          } = rowData;

          return(
            <>
              {(+nfseStatusNumber == 4) && (
                <IconButton
                  size="medium"
                  onClick={() => {
                    dispatch(
                      handleError({
                        error: rowData && rowData.nfseResult,
                        transactionId,
                        establishmentId: rowData?.establishmentId,
                        paymentDateTime: rowData?.paymentDateTime
                      })
                    )
                  }}
                >
                  <FontAwesomeIcon
                    size="xs"
                    color="#F66D6E"
                    icon={faExclamationCircle}
                  />
                </IconButton>
              )}
              <IconButton
                size="medium"
                onClick={() =>
                  selectTransactionType(
                    establishmentId,
                    sourceId,
                    categorySourceId,
                    clientId,
                    rowData
                  )
                }
              >
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  color="#2CA9D6"
                  size="xs"
                />
              </IconButton>
              {(+nfseStatusNumber != 4) && (
                <IconButton
                  size="medium"
                  onClick={() => goToLink(rowData.nfseLink)}
                  disabled={!rowData.nfseLink}
                >
                  <FontAwesomeIcon
                    size="xs"
                    color="#2CA9D6"
                    icon={faLink}
                    style={{ opacity: !rowData.nfseLink ? 0.5 : 1 }}
                  />
                </IconButton>
              )}
            </>
          );
        }
      });
    }
  }, []);

  return(
    <Paper>
      {modal && (
        <ServiceOrder modal={modal} />
      )}
      {(registration && +transactionTypeId == 1) && (
        <Receipt isOpen={registration} />
      )}
      {(registration && +transactionTypeId == 2) && (
        <Payment isOpen={registration} />
      )}
      {isUpdatingInvoice && (
        <Invoice
          isOpen={isUpdatingInvoice}
          establishmentId={establishmentId}
        />
      )}
      {isOpenInvoiceServiceContract && (
        <InvoiceServiceContract
          isOpenInvoiceServiceContract={isOpenInvoiceServiceContract}
          setIsOpenInvoiceServiceContract={setIsOpenInvoiceServiceContract}
          calculatedInvoiceDateTime={calculatedInvoiceDateTime}
          isInvoicePaid={true}
        />
      )}
      <ProvisionalReceiptError
        open={error.length > 0}
        error={error}
        page={provisionalReceiptPage}
        pageSize={provisionalReceiptPageSize}
        qp={qp}
        extraProps={{ startDate, endDate }}
      />
      {isMobile ? (
        <FilterMobile
          filter
          hasSearchTerm
          linkButtonText="Configurações das notas fiscais"
          hasLinkButton="/configuracoes-de-notas-fiscais/informacoes-basicas"
          filters={filters}
          handlePageRequest={handleProvisionalReceiptList}
        />
      ) : (
        <Filter
          filter
          hasSearchTerm
          linkButtonText="Configurações das notas fiscais"
          hasLinkButton="/configuracoes-de-notas-fiscais/informacoes-basicas"
          filters={filters}
          handlePageRequest={handleProvisionalReceiptList}
        />
      )}
      <MTable
        loading={isLoading}
        headers={headers}
        data={convertedList}
        actionColumnWidth={tableWidth}
      />
      <Grid
        container
        xs={12}
        style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}
      >
        <Pagination
          color="primary"
          variant="outlined"
          shape="rounded"
          count={provisionalReceiptPageCount}
          page={provisionalReceiptPage}
          onChange={(_, page) => setProvisionalReceiptPage(page)}
        />
      </Grid>
    </Paper>
  );
}