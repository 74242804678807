import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from 'formik';
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { FormControlLabel, FormHelperText, Checkbox } from '@material-ui/core';

import Card from 'components/Card';
import Loader from 'components/Loader';
import Input from 'components/Input';
import Select from 'components/Select';
import AsyncSelect from 'components/AsyncSelect';

import { loadProvisionalReceiptInfo, loadEconomicActivities, loadRegime } from '../../../../store/thunk';
import { setDataPageAction, setCityCode, setWarningMessage } from '../../../../store/actions';

import { availableTaxationRegimesMock } from './resources';
import { useStyles } from './styles';

export default function CityHallRegistration({ formikProps }) {
  const dispatch = useDispatch();

  const styles = useStyles();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { isLoading } = useSelector(state => state.businessInfo);
  const { dataPage, configType, submitErrors, submit: { cityCode } } = useSelector(state => state.provisionalReceiptConfig);

  const [asyncSelectCityCode, setAsyncSelectCityCode] = useState(null);
  const [availableTaxationRegimes, setAvailableTaxationRegimes] = useState([]);

  const isSameCnpj = +configType == 0;

  const getRegimeEspecial = async () => {
    if(formikProps.values.cityCode || cityCode) {
      const { data } = await loadRegime(userId, formikProps.values.cityCode);

      dispatch(setDataPageAction(data));

      if(data?.regimesEspecialTributacao) {
        const regimeEspecial = data?.regimesEspecialTributacao.map(item => ({
          value: item.codigo,
          label: item.nome
        }));

        const itemSelect =
          regimeEspecial.filter(item => item.value == formikProps.values.taxationRegime)[0]?.value;

        formikProps.setFieldValue('taxationRegime', itemSelect);

        setAvailableTaxationRegimes(regimeEspecial);
      } else {
        setAvailableTaxationRegimes(availableTaxationRegimesMock);
      }
    }
  }

  useEffect(() => {
    dispatch(loadEconomicActivities(userId));
  }, []);

  useEffect(() => {
    if(establishmentId) {
      dispatch(loadProvisionalReceiptInfo(userId, establishmentId));
    }
  }, [establishmentId]);

  useLayoutEffect(() => {
    setAsyncSelectCityCode(cityCode);

    if(cityCode == formikProps.values.cityCode) {
      getRegimeEspecial();
    }
  }, [cityCode, formikProps.values.cityCode]);

  const handleChange = async (e, option) => {
    if(!!formikProps.values.warningMessageCheckbox) {
      dispatch(setWarningMessage(true));
    }

    dispatch(setCityCode(option?.cityCode));
    setAsyncSelectCityCode(option?.cityCode);

    formikProps.setFieldValue('cityCode', option?.cityCode);

    if(option && option?.cityCode) {
      const { data } = await loadRegime(userId, option?.cityCode);

      dispatch(setDataPageAction(data));

      if(data?.regimesEspecialTributacao) {
        const regimeEspecial = data?.regimesEspecialTributacao.map(item => ({
          value: item.codigo,
          label: item.nome,
        }));

        setAvailableTaxationRegimes(regimeEspecial);
      } else {
        setAvailableTaxationRegimes(availableTaxationRegimesMock);
      }

      dispatch(setDataPageAction(data));
    }
  }

  return(
    <>
      <Paper
        container
        style={{
          padding: 25,
          backgroundColor: '#FFFFFF',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0
        }}
      >
        <Grid item xs={12}>
          <Form>
            <Loader isLoading={isLoading}>
              <Grid
                container
                style={{
                  flexDirection: 'column',
                  marginBottom: 15,
                  padding: 15,
                  backgroundColor: 'rgb(241, 244, 249)',
                  border: '1px solid #38a8d8',
                  borderRadius: 10
                }}
              >
                <Grid item spacing={2}>
                  <Typography variant="h6" color="primary">Atenção:</Typography>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 400, fontSize: '15px' }}
                  >
                    1. Digite seu município para carregar as intruções de cadastro
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 400, fontSize: '15px' }}
                  >
                    2. Dependendo do município selecionado o "Tipo de
                    Autenticação" será exigida na página seguinte.
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Loader isLoading={isLoading}>
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <AsyncSelect
                        disabled={isSameCnpj}
                        name="cityCode"
                        label="Munícipio"
                        value={asyncSelectCityCode || formikProps.values.cityCode}
                        error={
                          formikProps.errors?.cityCode ||
                          submitErrors?.some(error => error?.includes('cityCode'))
                        }
                        helperText={
                          formikProps.errors?.cityCode ||
                          submitErrors?.find(error => error?.includes('cityCode'))
                        }
                        onChange={(e, opt) => handleChange(e, opt)}
                      />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <Tooltip
                        title={
                          <Typography style={{ fontSize: 15 }}>
                            {dataPage?.helpInscricaoMunicipal}
                          </Typography>
                        }
                        placement="top"
                      >
                        <Input
                          disabled={isSameCnpj}
                          id="municipalSubscription"
                          type="text"
                          label="Inscrição municipal"
                          value={formikProps.values.municipalSubscription}
                          error={
                            formikProps.errors.municipalSubscription ||
                            submitErrors?.some(error => error?.includes('municipalSubscription'))
                          }
                          helperText={
                            formikProps.errors.municipalSubscription ||
                            submitErrors?.find(error => error?.includes('municipalSubscription'))
                          }
                          onChange={formikProps.handleChange}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <Input
                        disabled={isSameCnpj}
                        id="stateSubscription"
                        type="text"
                        label="Inscrição estadual"
                        value={formikProps.values.stateSubscription}
                        error={
                          formikProps.errors.stateSubscription ||
                          submitErrors?.some(error => error?.includes('stateSubscription'))
                        }
                        helperText={
                          formikProps.errors.stateSubscription ||
                          submitErrors?.find(error => error?.includes('stateSubscription'))
                        }
                        onChange={formikProps.handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    {dataPage?.usaRegimeEspecialTributacao && (
                      <Grid item sm={6} xs={12}>
                        <Tooltip
                          title={
                            <Typography style={{ fontSize: 15 }}>
                              {dataPage?.helpRegimeEspecialTributacao}
                            </Typography>
                          }
                          placement="top"
                        >
                          <Select
                            disabled={isSameCnpj}
                            name="taxationRegime"
                            label="Regime de tributação"
                            options={availableTaxationRegimes || []}
                            value={formikProps.values.taxationRegime || ""}
                            error={formikProps.errors.taxationRegime}
                            helperText={formikProps.errors.taxationRegime}
                            onChange={formikProps.handleChange}
                          />
                        </Tooltip>
                      </Grid>
                    )}
                    <Grid item sm={6} xs={12}>
                      <Tooltip
                        title={
                          <Typography style={{ fontSize: 15 }}>
                            "Adicione mais de um cartão. Ex: 0-10"
                          </Typography>
                        }
                        placement="top"
                      >
                        <Select
                          disabled={isSameCnpj}
                          name="encouraging"
                          label="Incentivador cultural"
                          options={[
                            { label: 'Sim', value: 1 },
                            { label: 'Não', value: 2 }
                          ]}
                          value={formikProps.values.encouraging}
                          error={formikProps.errors.encouraging}
                          helperText={formikProps.errors.encouraging}
                          onChange={formikProps.handleChange}
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <Tooltip
                        title={
                          <Typography style={{ fontSize: 15 }}>
                            "Adicione mais de um cartão. Ex: 0-10"
                          </Typography>
                        }
                        placement="top"
                      >
                        <Select
                          disabled={isSameCnpj}
                          name="simpleNational"
                          label="Simples Nacional"
                          options={[
                            { label: 'Sim', value: 1 },
                            { label: 'Não', value: 2 }
                          ]}
                          value={formikProps.values.simpleNational}
                          error={formikProps.errors.simpleNational}
                          helperText={formikProps.errors.simpleNational}
                          onChange={formikProps.handleChange}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Tooltip
                        title={
                          <Typography style={{ fontSize: 15 }}>
                            "Adicione mais de um cartão. Ex: 0-10"
                          </Typography>
                        }
                        placement="top"
                      >
                        <Select
                          disabled={isSameCnpj}
                          name="issRetain"
                          label="ISS retido"
                          options={[
                            { label: 'Sim', value: 1 },
                            { label: 'Não', value: 2 }
                          ]}
                          value={formikProps.values.issRetain}
                          error={formikProps.errors.issRetain}
                          helperText={formikProps.errors.issRetain}
                          onChange={formikProps.handleChange}
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    {dataPage?.usaDescricaoServico && (
                      <Grid item sm={6} xs={12}>
                        <Tooltip
                          title={
                            <Typography style={{ fontSize: 15 }}>
                              {dataPage?.helpDescricaoServico}
                            </Typography>
                          }
                          placement="top"
                        >
                          <Input
                            disabled={isSameCnpj}
                            id="activityCodeList.description"
                            type="text"
                            label="Descrição do serviço"
                            value={formikProps.values?.activityCodeList?.description || ""}
                            error={formikProps.errors?.activityCodeList?.description}
                            helperText={formikProps.errors?.activityCodeList?.description}
                            onChange={formikProps.handleChange}
                          />
                        </Tooltip>
                      </Grid>
                    )}
                    {dataPage?.usaCodigoServicoMunicipal && (
                      <Grid item sm={6} xs={12}>
                        <Tooltip
                          title={
                            <Typography style={{ fontSize: 15 }}>
                              {dataPage?.helpCodigoServicoMunicipal}
                            </Typography>
                          }
                          placement="top"
                        >
                          <Input
                            disabled={isSameCnpj}
                            id="activityCodeList.serviceCode"
                            type="text"
                            label="Código do serviço"
                            value={formikProps.values.activityCodeList?.serviceCode || ""}
                            error={formikProps.errors.activityCodeList?.serviceCode}
                            helperText={formikProps.errors.activityCodeList?.serviceCode}
                            onChange={formikProps.handleChange}
                          />
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>
                  <Grid container spacing={2}>
                    {dataPage?.usaCNAE && (
                      <Grid item sm={6} xs={12}>
                        <Tooltip
                          title={
                            <Typography style={{ fontSize: 15 }}>
                              {dataPage?.helpCNAE}
                            </Typography>
                          }
                          placement="top"
                        >
                          <Input
                            disabled={isSameCnpj}
                            id="activityCodeList.code"
                            type="text"
                            label="CNAE"
                            value={formikProps.values.activityCodeList?.code || ""}
                            error={formikProps.errors.activityCodeList?.code}
                            helperText={formikProps.errors.activityCodeList?.code}
                            onChange={formikProps.handleChange}
                          />
                        </Tooltip>
                      </Grid>
                    )}
                    <Grid item sm={6} xs={12}>
                      <Input
                        disabled={isSameCnpj}
                        id="activityCodeList.aliquot"
                        label="Alíquota"
                        type="money"
                        value={formikProps.values.activityCodeList?.aliquot || ""}
                        error={formikProps.errors.activityCodeList?.aliquot}
                        helperText={formikProps.errors.activityCodeList?.aliquot}
                        onChange={formikProps.handleChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    {dataPage?.usaItemListaServico && (
                      <Grid item sm={6} xs={12}>
                        <Tooltip
                          title={
                            <Typography style={{ fontSize: 15 }}>
                              {dataPage?.helpItemListaServico}
                            </Typography>
                          }
                          placement="top"
                        >
                          <Input
                            disabled={isSameCnpj}
                            id="activityCodeList.itemListaServico"
                            type="text"
                            label="Item Lista Servico"
                            value={formikProps.values.activityCodeList?.itemListaServico || ""}
                            error={formikProps.errors.activityCodeList?.itemListaServico}
                            helperText={formikProps.errors.activityCodeList?.itemListaServico}
                            onChange={formikProps.handleChange}
                          />
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>
                </Loader>
              </Grid>
            </Loader>
          </Form>
        </Grid>
      </Paper>
      <Card
        title="Restrições de envio de NFS-e de cada prefeitura"
        key={formikProps?.values?.establishmentId}
        style={{ marginTop: 15 }}
      >
        <Grid item xs={6}>
          <Typography variant="h6" className={styles.text}>
            Emissão de NF-e sem cadastro de cliente:{' '}
            {dataPage?.suportaEmissaoNFeSemCliente ? 'Sim' : 'Não'}
          </Typography>
          <Typography variant="h6" className={styles.text}>
            Emissão de NF-e sem CPF/CNPJ:{' '}
            {dataPage?.suportaEmissaoNFeClienteSemCpf ? 'Sim' : 'Não'}
          </Typography>
          <Typography variant="h6" className={styles.text}>
            Emissão de NF-e sem endereço:{' '}
            {dataPage?.suportaEmissaoNFeClienteSemEndereco ? 'Sim' : 'Não'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6" className={styles.text}>
            Cancelamento NF-e sem cadastro de cliente:{' '}
            {dataPage?.suportaCancelamentoNFeSemCliente ? 'Sim' : 'Não'}
          </Typography>
          <Typography variant="h6" className={styles.text}>
            Cancelamento de NF-e sem CPF/CNPJ:{' '}
            {dataPage?.suportaCancelamentoNFeClienteSemCpf ? 'Sim' : 'Não'}
          </Typography>
        </Grid>
        <Grid>
          <FormControlLabel
            control={
              <Checkbox
                name="restrictionsCheckbox"
                type="checkbox"
                checked={formikProps.values.restrictionsCheckbox}
                value={formikProps.values.restrictionsCheckbox}
                onChange={
                  formikProps.values.restrictionsCheckbox
                    ? null
                    : formikProps.handleChange
                }
              />
            }
            label={
              <Typography style={{ fontSize: '15px', fontWeight: 'bold' }}>
                Eu li e concordo com os termos
              </Typography>
            }
          />
          {formikProps.errors.restrictionsCheckbox && (
            <FormHelperText error>
              {formikProps.errors.restrictionsCheckbox}
            </FormHelperText>
          )}
        </Grid>
      </Card>
    </>
  );
}

CityHallRegistration.defaultProps = {
  formikProps: {
    handleChange: () => {},
    setFieldValue: () => {},
    values: {
      establishmentName: '',
      companyName: '',
      documentId: '',
      phone: '',
      email: '',
      postalCode: '',
      street: '',
      number: 0,
      complement: '',
      district: '',
      city: '',
      state: '',
      vacanciesMarks: '',
      encouraging: '',
      simpleNational: '',
      issRetain: ''
    },
    errors: {
      companyName: false,
      documentId: false,
      phone: false,
      email: false,
      postalCode: false,
      street: false,
      number: false,
      complement: false,
      district: false,
      city: false,
      state: false,
      vacanciesMarks: false,
      encouraging: false,
      simpleNational: false,
      issRetain: false
    }
  }
};

CityHallRegistration.propTypes = {
  formikProps: PropTypes.shape()
};