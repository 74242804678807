import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import { prop } from 'ramda';
import { registerFieldChange } from '../store/actions';
import { convertToOrganizeFilters } from '../../../helpers/converters';

import { InputStyles, AutocompleteStyles } from './styles';

import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Input from '../../Input';
import Select from '../../Select';

const FilterFields = ({ xs, md, filters }) => {
  const styles = InputStyles();
  const autocompleteStyles = AutocompleteStyles();

  const dispatch = useDispatch();

  const { path } = useRouteMatch();

  const { filtrate } = useSelector(state => state.filters);
  const fieldChange = registerFieldChange;
  const onValueChange = func => e => dispatch(func(e.target.name, e.target.value));

  const isHistoryOperacional = path.split('/').includes('historico-operacional');
  const isNotaFiscal = path.split('/').includes('notas-fiscais');

  return(
    <>
      {filters.length > 0 &&
        convertToOrganizeFilters(filters).map(field => {
          const { type, label, id, placeholder, options, maskType, defaultValue } = field;

          let value = prop(id, filtrate) || defaultValue || '';

          if(id === 'status') {
            if(filtrate && filtrate.status) {
              value = filtrate.status;
            } else {
              value = 1;
            }
          }

          if(id === 'brandId') {
            if(filtrate && filtrate.brandId) {
              value = options?.find(option => +option?.value == +filtrate.brandId) || {};
            } else {
              value = {};
            }
          }

          if(id === 'productTypeId') {
            if(filtrate && filtrate.productTypeId) {
              value = options?.find(option => +option?.value == +filtrate.productTypeId) || {};
            } else {
              value = {};
            }
          }

          if(id === 'typeDateTime' && isHistoryOperacional) {
            if(filtrate && filtrate.typeDateTime) {
              value = filtrate.typeDateTime;
            } else {
              value = '';
            }
          }

          if(id === 'typeDateTime' && isNotaFiscal) {
            if(filtrate && filtrate.typeDateTime) {
              value = filtrate.typeDateTime;
            } else {
              value = 'paymentDateTime';
            }
          }

          return(
            <Grid xs={xs ? xs : 2} md={md ? md : 2} classes={styles}>
              <div className={styles.container}>
                {type === 'autocomplete' && (
                  <Autocomplete
                    id={id}
                    classes={autocompleteStyles}
                    options={options}
                    groupBy={option => option?.parent}
                    value={value}
                    onChange={(_, params) => dispatch(registerFieldChange(id, params?.value || ''))}
                    getOptionLabel={option => option.label}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        color="primary"
                        label={label}
                      />
                    )}
                  />
                )}
                {type === 'input' && (
                  <Input
                    fullWidth
                    name={id}
                    type={maskType || 'text'}
                    label={label}
                    placeholder={placeholder}
                    value={value}
                    onChange={onValueChange(fieldChange)}
                  />
                )}
                {type === 'select' && (
                  <Select
                    name={id}
                    label={label}
                    options={options}
                    value={value}
                    onChange={onValueChange(fieldChange)}
                  />
                )}
              </div>
            </Grid>
          );
        })
      }
    </>
  );
}

FilterFields.defaultProps = {
  filters: []
};

FilterFields.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      placeholder: PropTypes.string,
      type: PropTypes.string,
      value: PropTypes.string,
      advanced: PropTypes.bool
    })
  )
};

export default FilterFields;