import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Formik, Form } from 'formik';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { recoverPassword } from '../store/thunk';
import { useStyles } from '../../Login/components/styles';
import { validations } from './validations';

const logo = require('../../../assets/img/logo_login.svg');

export default function PasswordRecovery() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoading } = useSelector(state => state.profile);
  const initialState = { email: '' };

  const handlePasswordRecovery = async (values, actions) => {
    const { resetForm } = actions;

    return dispatch(recoverPassword(values)).then(() => {
      resetForm();
      history.push('/');
    });
  };

  return (
    <Grid
      container
      spacing={0}
      alignItems="center"
      justify="center"
      className={classes.container}
    >
      <Grid item md={4} xs={12}>
        <Paper elevation={0} className={classes.paper}>
          <img
            src={logo}
            width="100"
            height="100"
            alt="Jump Car"
            style={{ marginBottom: 36 }}
          />
          <Typography paragraph variant="h6" color="primary">
            Digite seu e-mail cadastrado
            <br />
            <Typography variant="h5" color="primary" className={classes.title}>
              e receba uma nova senha
            </Typography>
          </Typography>

          <Formik
            initialValues={initialState}
            validationSchema={validations}
            onSubmit={(values, actions) =>
              handlePasswordRecovery(values, actions)
            }
          >
            {({ ...formikProps }) => (
              <Form style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Input
                  name="email"
                  type="email"
                  label="Email"
                  placeholder="usuario@email.com.br"
                  value={formikProps.values.email}
                  error={formikProps.errors.email}
                  helperText={formikProps.errors.email}
                  onChange={formikProps.handleChange}
                />

                <Button
                  fullWidth
                  verticalGutter
                  color="secondary"
                  loading={isLoading}
                >
                  Enviar nova senha
                </Button>
              </Form>
            )}
          </Formik>
          <hr />
          <Link to="/">Voltar para o login</Link>
        </Paper>
      </Grid>
    </Grid>
  );
}
