import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import { compose, propEq, findIndex, assoc } from 'ramda';

import { Formik, Form } from 'formik';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '../../../components/Button';

import BasicInfo from './steps/BasicInfo';
import ConfigRPS from './steps/ConfigRps';
import ProvisionalReceiptInfo from './steps/ProvisionalReceiptInfo';
import ProvisionalReceiptFilter from './steps/ProvisionalReceiptFilter';

import { setWarningMessage, setRestrictions } from '../store/actions';
import { createRps, upsertRPS, updateCertificate, updatePassword, updateToken, updateSettings, updateFilters } from '../store/thunk';

import { basicInfoValidations } from './steps/BasicInfo/validations';
import { configRpsValidations } from './steps/ConfigRps/validations';
import { provisionalReceiptValidations } from './validations';

import { usePrevious } from '../../../helpers/hooks/usePrevious';

import { formatDocument } from 'helpers/formatters';

import { useStyles } from './styles';

export default function ProvisionalReceiptConfig() {
  const dispatch = useDispatch();

  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();

  const { establishmentId, accessKey } = useSelector(state => state.businessInfo);
  const businessLoading = useSelector(state => state.businessInfo.isLoading);
  const { userId, submit: { profileId } } = useSelector(state => state.profile);

  const {
    isLoading,
    submit,
    dataPage,
    isCityLoaded,
    warningMessageCheckbox,
    restrictionsCheckbox,
    certificateFileFromAPI,
    configType,
    isRpsConfig
  } = useSelector(state => state.provisionalReceiptConfig);

  const isSameCnpj = +configType == 0;
  const isUserAdmin = +profileId == 1;

  const basicInfoValidationProps = {
    ...submit,
    ...dataPage,
    isUserAdmin,
    warningMessageCheckbox,
    restrictionsCheckbox
  }

  const isBasicConfig = location.pathname == "/configuracoes-de-notas-fiscais/informacoes-basicas";

  const previousAccessKey = usePrevious(accessKey);

  const handleUpdateCertificate = async (values) => {
    if(dataPage.tipoAutenticacao === 0) {
      handleStepNavigation('next');
      return;
    }

    if(dataPage.tipoAutenticacao === 1) {
      if(!certificateFileFromAPI) {
        if(!values?.certificateFile) {
          return toast.error('Escolha um certificado para continuar.');
        }
      }

      if(values?.certificateFile) {
        if(!values?.autenticatorPassword && !values?.autenticatorPasswordConfirm) {
          return handleStepNavigation('next');
        }

        const params = {
          userId,
          establishmentId,
          certificateFile: values?.certificateFile,
          autenticatorPassword: values?.autenticatorPassword,
          autenticatorPasswordConfirm: values?.autenticatorPasswordConfirm
        }

        return dispatch(updateCertificate(params))
          .then(() => handleStepNavigation('next'));
      }

      return handleStepNavigation('next');
    }

    if(dataPage.tipoAutenticacao === 3) {
      const { autenticatorToken } = values;

      const json = {
        userId,
        establishmentId,
        autenticatorToken
      }

      return dispatch(updateToken(json))
        .then(() => handleStepNavigation('next'));
    }

    if(dataPage.tipoAutenticacao === 2) {
      const { autenticatorUser, autenticatorPassword } = values;

      const json = {
        userId,
        establishmentId,
        autenticatorUser,
        autenticatorPassword
      }

      return dispatch(updatePassword(json))
        .then(() => handleStepNavigation('next'));
    }
  }

  const sendBasicInfoCreation = params => {
    const business = {
      userId,
      establishmentId,
      logo: params.logo,
      fantasyName: params.fantasyName,
      documentId: formatDocument(params.documentId),
      phone: params.phone,
      email: params.email,
      companyName: params.companyName,
      postalCode: params.postalCode,
      street: params.street,
      number: params.number,
      complement: params.complement,
      district: params.district,
      city: params.city,
      state: params.state,
      cityCode: params.cityCode || '',
      municipalSubscription: params.municipalSubscription,
      stateSubscription: params.stateSubscription,
      taxationRegime: params.taxationRegime?.toString(),
      encouraging: params.encouraging,
      simpleNational: params.simpleNational,
      issRetain: params.issRetain,
      activityCodeList: JSON.stringify([params.activityCodeList])
    }

    return dispatch(createRps(business));
  }

  const sendBasicInfoRegistration = params => {
    const business = {
      userId,
      establishmentId,
      logo: params.logo,
      fantasyName: params.fantasyName,
      documentId: formatDocument(params.documentId),
      phone: params.phone,
      email: params.email,
      companyName: params.companyName,
      postalCode: params.postalCode,
      street: params.street,
      number: params.number,
      complement: params.complement,
      district: params.district,
      city: params.city,
      state: params.state,
      cityCode: params.cityCode || '',
      municipalSubscription: params.municipalSubscription,
      stateSubscription: params.stateSubscription,
      taxationRegime: params.taxationRegime?.toString(),
      encouraging: params.encouraging,
      simpleNational: params.simpleNational,
      issRetain: params.issRetain,
      activityCodeList: JSON.stringify([params.activityCodeList])
    }

    return dispatch(upsertRPS(business));
  }

  const sendFilterSend = params => {
    const {
      invoices,
      serviceOrders,
      selectedTransactions,
      paymentMethods
    } = params.automaticGenerate;

    const filter = {
      userId,
      establishmentId,
      statusRps: params.statusRps,
      automaticGenerate: JSON.stringify({
        invoices: invoices ? 1 : 0,
        serviceOrders: serviceOrders ? 1 : 0,
        selectedTransactions: selectedTransactions ? 1 : 0,
        paymentMethods: !!paymentMethods ? paymentMethods : []
      }),
      ...(!isSameCnpj && { automaticSendNfse: params.automaticSendNfse })
    }

    return dispatch(updateFilters(filter))
      .then(() => handleStepNavigation('next'));
  }

  const handleConfigRps = values => {
    const { rpsGenerateOnline, rpsSeries, rpsCount } = values;

    const params = {
      userId,
      establishmentId,
      rpsGenerateOnline,
      rpsSeries,
      rpsCount
    }

    return dispatch(updateSettings(params))
      .then(() => handleStepNavigation('next'));
  }

  const configurations = [
    {
      id: 'basicInfo',
      title: 'Informações básicas',
      path: '/configuracoes-de-notas-fiscais/informacoes-basicas',
      component: BasicInfo,
      onSubmit: params => {
        if(+configType == 0) {
          return handleStepNavigation('next');
        }

        dispatch(setWarningMessage(true));
        dispatch(setRestrictions(true));

        return isRpsConfig ? sendBasicInfoRegistration(params) : sendBasicInfoCreation(params);
      },
      validations: (+configType == 0) ? null : basicInfoValidations
    },
    {
      id: 'rpsInfo',
      title: 'Autenticação',
      path: '/configuracoes-de-notas-fiscais/autenticacao',
      component: ProvisionalReceiptInfo,
      onSubmit: params => {
        if(+configType == 0) {
          return handleStepNavigation('next');
        }

        handleUpdateCertificate(params);
      },
      validations: (+configType == 0) ? null : provisionalReceiptValidations
    },
    {
      id: 'configRps',
      title: 'Configuração RPS',
      path: '/configuracoes-de-notas-fiscais/configuracao-rps',
      component: ConfigRPS,
      onSubmit: params => {
        if(+configType == 0) {
          return handleStepNavigation('next');
        }

        handleConfigRps(params);
      },
      validations: (+configType == 0) ? null : configRpsValidations
    },
    {
      id: 'rpsInfo',
      title: 'Filtro de envio',
      path: '/configuracoes-de-notas-fiscais/filtro-de-envio',
      component: ProvisionalReceiptFilter,
      onSubmit: params => sendFilterSend(params)
    }
  ];

  const currentIndex = findIndex(propEq('path', location.pathname), configurations);
  const currentStep = configurations[currentIndex];

  const { onSubmit, id } = currentStep;

  useEffect(() => {
    if(previousAccessKey) {
      history.push('/configuracoes-de-notas-fiscais/informacoes-basicas');
    }
  }, []);

  useEffect(() => {
    if(establishmentId) {
      history.push('/configuracoes-de-notas-fiscais/informacoes-basicas');
    }
  }, [establishmentId]);

  const handleStepNavigation = to => {
    const currentIndex = findIndex(propEq('path', location.pathname), configurations);
    const nextIndex = to === 'next' ? currentIndex + 1 : currentIndex - 1;

    if(configurations[nextIndex]) {
      history.push(configurations[nextIndex].path);
    } else {
      history.push('/notas-fiscais');
    }
  }

  const handleNextStep = async values => {
    let params = values;

    if(id === 'businessInfo') {
      params = compose(assoc('establishmentId', establishmentId), assoc('userId', userId))(values);
    }

    return onSubmit(params)
      .then(() => {
        handleStepNavigation('next');
      })
      .catch(err => {
        console.log('err', err);
      });
  }

  const handlePreviousStep = () => {
    handleStepNavigation('prev');
  }

  return(
    <Grid className={classes.root}>
      <Stepper
        alternativeLabel
        nonLinear
        activeStep={currentIndex}
        style={{ background: 'transparent', padding: 0, paddingBottom: 24 }}
      >
        {configurations.map((nav, i) => (
          <Step key={nav.path} active={currentIndex === i}>
            <StepButton
              disabled={!isRpsConfig}
              completed={currentIndex > i}
              component={Link}
              to={nav.path}
              style={{ textDecoration: 'none' }}
            >
              {nav.title}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Grid>
        {configurations.map(({ component: Component, ...nav }) => (
          <Route
            exact
            key={nav.path}
            path={nav.path}
            render={routeProps => (
              <Formik
                enableReinitialize={(isBasicConfig && isRpsConfig) || isSameCnpj}
                initialValues={isBasicConfig ? basicInfoValidationProps : submit}
                validationSchema={nav?.validations}
                onSubmit={handleNextStep}
              >
                {({ ...formikProps }) => {
                  return(
                    <Form>
                      <Component formikProps={formikProps} {...routeProps} />
                      <Grid className={classes.footer}>
                        <Container>
                          <Grid container justify="space-between">
                            <Button
                              type="button"
                              color="secondary"
                              loading={isLoading || businessLoading}
                              onClick={handlePreviousStep}
                            >
                              Voltar
                            </Button>
                            <Button
                              color="success"
                              loading={isLoading || businessLoading}
                              disabled={isBasicConfig && !isCityLoaded}
                            >
                              Avançar
                            </Button>
                          </Grid>
                        </Container>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            )}
          />
        ))}
      </Grid>
    </Grid>
  );
}