import qs from 'qs';
import { newAPI } from '../../../services';

export const fetchCards = async (userId, establishmentId, url) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}${url}`);
  return response.data;
}

export const createCard = async (userId, establishmentId, params) => {
  const response = await newAPI.post(`${userId}/establishment/${establishmentId}/cards/new`, qs.stringify(params));
  return response.data;
}

export const deleteCards = async (userId, establishmentId, cardsData) => {
  const { data } = cardsData;

  const cardsPreparedies = data.cardCodes.map(card => `cards[]=${card}`);
  const regexForReplace = /,/gi;

  const dataForm =
    cardsPreparedies.length > 1
      ? cardsPreparedies.join().replace(regexForReplace, '&')
      : cardsPreparedies[0];

  const response = await newAPI.delete(`${userId}/establishment/${establishmentId}/cards/delete`, { data: dataForm });
  return response.data;
}