import * as yup from 'yup';
import { formatDocument, formatPhone, formatPostalCode } from 'helpers/formatters';
import { phoneRegExp, documentRegExp, postalCodeRegExp } from '../../../../../helpers/regex';

export const basicInfoValidations = yup.object().shape({
  warningMessageCheckbox: yup
    .boolean()
    .test(function(checked) {
      return checked
        ? true
        : this.createError({
            message: 'Aceite os termos para avançar',
            path: 'warningMessageCheckbox'
          });
    }),
  restrictionsCheckbox: yup
    .boolean()
    .test(function(checked) {
      return checked
        ? true
        : this.createError({
            message: 'Aceite os termos para avançar',
            path: 'restrictionsCheckbox'
          });
    }),
  fantasyName: yup
    .string()
    .required('O nome do estabelecimento é obrigatório')
    .nullable(),
  companyName: yup
    .string()
    .required('A razão social é obrigatória')
    .nullable(),
  documentId: yup
    .string()
    .test(function(document) {
      return formatDocument(document).match(documentRegExp)
        ? true
        : this.createError({
            message: 'O CNPJ é obrigatório',
            path: 'documentId'
          });
    })
    .nullable(),
  phone: yup
    .string()
    .test(function(phone) {
      return formatPhone(phone).match(phoneRegExp)
        ? true
        : this.createError({
            message: 'O telefone é obrigatório',
            path: 'phone'
          });
    })
    .nullable(),
  email: yup
    .string()
    .email('O e-mail precisa ser válido')
    .required('O e-mail é obrigatório')
    .nullable(),
  postalCode: yup
    .string()
    .test(function(postalCode) {
      return formatPostalCode(postalCode).match(postalCodeRegExp)
        ? true
        : this.createError({
            message: 'O CEP é obrigatório',
            path: 'postalCode'
          });
    }),
  street: yup
    .string()
    .required('O endereço é obrigatório')
    .nullable(),
  number: yup
    .number()
    .required('O número é obrigatório')
    .nullable(),
  district: yup
    .string()
    .required('O bairro é obrigatório')
    .nullable(),
  city: yup
    .string()
    .required('A cidade é obrigatória')
    .nullable(),
  state: yup
    .string()
    .required('O estado é obrigatório')
    .nullable(),
  encouraging: yup
    .number()
    .test(function(option) {
      return option == 1 || option == 2
        ? true
        : this.createError({
            message: 'Incentivador cultural é obrigatório',
            path: 'encouraging'
          });
    }),
  simpleNational: yup
    .number()
    .test(function(option) {
      return option == 1 || option == 2
        ? true
        : this.createError({
            message: 'Selecione se sua empresa se enquadra no Simples Nacional',
            path: 'simpleNational'
          });
    }),
  issRetain: yup
    .number()
    .test(function(option) {
      return option == 1 || option == 2
        ? true
        : this.createError({
            message: 'Selecione se sua empresa possui ISS retido',
            path: 'issRetain'
          });
    }),
  cityCode : yup
    .string()
    .required('O município é obrigatório')
    .nullable(),
  municipalSubscription: yup
    .string()
    .nullable()
    .test(function(value) {
      if(!!value) {
        return true;
      }

      if(!!this.parent.stateSubscription && !value) {
        return true;
      }

      return this.createError({
        message: 'A inscrição municipal é obrigatória',
        path: 'municipalSubscription'
      });
    }),
  stateSubscription: yup
    .string()
    .nullable()
    .test(function(value) {
      if(!!value) {
        return true;
      }

      if(!!this.parent.municipalSubscription && !value) {
        return true;
      }

      return this.createError({
        message: 'A inscrição estadual é obrigatória',
        path: 'stateSubscription'
      });
    }),
  usaRegimeEspecialTributacao: yup
    .boolean(),
  taxationRegime: yup
    .string()
    .nullable()
    .when("usaRegimeEspecialTributacao", {
      is: true,
      then: yup
        .string()
        .test(function(option) {
          return option != null && option != undefined
            ? true
            : this.createError({
                message: 'O regime de taxação é obrigatório',
                path: 'taxationRegime'
              })
        })
    }),
  'activityCodeList.description': yup
    .string()
    .nullable()
    .when(['usaDescricaoServico', 'activityCodeList'], {
      is: (usaDescricaoServico, activityCodeList) => usaDescricaoServico && !activityCodeList?.description,
      then: yup.string().required('Descrição do serviço é obrigatória')
    }),
  'activityCodeList.serviceCode': yup
    .string()
    .nullable()
    .when(['usaCodigoServicoMunicipal', 'activityCodeList'], {
      is: (usaCodigoServicoMunicipal, activityCodeList) => usaCodigoServicoMunicipal && !activityCodeList?.serviceCode,
      then: yup.string().required('Código do serviço é obrigatório')
    }),
  'activityCodeList.code': yup
    .string()
    .nullable()
    .when(['usaCNAE', 'activityCodeList'], {
      is: (usaCNAE, activityCodeList) => usaCNAE && !activityCodeList?.code,
      then: yup.string().required('O CNAE é obrigatório')
    }),
  'activityCodeList.aliquot': yup
    .string()
    .nullable()
    .when('activityCodeList', {
      is: activityCodeList => activityCodeList?.aliquot == undefined || activityCodeList?.aliquot == null,
      then: yup.string().required('Alíquota é obrigatória')
    }),
  'activityCodeList.itemListaServico': yup
    .string()
    .nullable()
    .when(['usaItemListaServico', 'activityCodeList'], {
      is: (usaItemListaServico, activityCodeList) => usaItemListaServico && !activityCodeList?.itemListaServico,
      then: yup.string().required('Item lista serviço é obrigatório')
    })
});