import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTag, faUserClock } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../components/Button';
import Select from '../../../components/Select';

import { loadFilters } from '../../../components/Filter/store/thunk';
import { setIsEdit } from '../../ClientRegistration/components/steps/BasicInfo/store/actions';
import { selectClient } from '../store/actions';
import { clearBasicInfo } from '../../ClientRegistration/components/steps/BasicInfo/store/actions';

import { useStyles } from './styles';

export default function ClientSelection() {
  const dispatch = useDispatch();

  const classes = useStyles();
  const history = useHistory();

  const [typeClient, setTypeClient] = useState();
  const [options, setOptions] = useState([]);

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);

  useEffect(() => {
    dispatch(loadFilters(userId, establishmentId));
    dispatch(setIsEdit(false));

    const initialOptions = [
      {
        label: 'Cliente avulso',
        value: 1
      },
      {
        label: 'Cliente pós pago',
        value: 5
      }
    ];

    setOptions(initialOptions);
  }, [userId, establishmentId]);

  const onSelect = e => {
    dispatch(clearBasicInfo());
    dispatch(selectClient(parseInt(e.currentTarget.value, 10)));
    history.push('/cadastro-de-cliente/informacoes-basicas/');
  }

  return(
    <Card className="client-type-option">
      <CardHeader
        style={{ backgroundColor: '#1E5168' }}
        title={
          <Typography gutterBottom variant="h6" className={classes.root}>
            Qual o tipo de cliente deseja adicionar?
          </Typography>
        }
      />
      <Grid
        item
        xs={11}
        md={5}
        spacing={3}
        direction="row"
        justify="left"
        alignItems="left"
        style={{ marginLeft: 16, marginTop: 16 }}
      >
        <Select
          label="Selecione o cliente que deseja cadastrar"
          value={typeClient}
          onChange={e => setTypeClient(e.target.value)}
          options={options}
        />
      </Grid>
      <CardContent align="center">
        <Grid
          container
          spacing={3}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item md={6}>
            <Card>
              {typeClient === 1 && (
                <CardContent>
                  <div className={classes.card}>
                    <div>
                      <FontAwesomeIcon
                        icon={faUserClock}
                        color="#1e5168"
                        size="4x"
                      />
                      <Typography gutterBottom variant="h6" color="primary">
                        Cliente Avulso
                      </Typography>
                    </div>
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      color="primary"
                    >
                      Cliente que pode receber restrições ou automações. Não
                      possuem faturas.
                    </Typography>
                  </div>
                </CardContent>
              )}
              {typeClient === 2 && (
                <CardContent className="text-center">
                  <div className={classes.card}>
                    <FontAwesomeIcon
                      icon={faUserTag}
                      color="#1e5168"
                      size="4x"
                    />
                    <Typography gutterBottom variant="h6" color="primary">
                      Cliente com fatura fixa
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      color="primary"
                    >
                      É o famoso cliente mensalista, mas, também, pode criar cobranças quinzenais ou semanais.
                    </Typography>
                  </div>
                </CardContent>
              )}
              {typeClient === 3 && (
                <CardContent className="text-center">
                  <div className={classes.card}>
                    <FontAwesomeIcon
                      icon={faUserTag}
                      color="#1e5168"
                      size="4x"
                    />
                    <Typography gutterBottom variant="h6" color="primary">
                      Cliente com pós pago
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      color="primary"
                    >
                      Clientes com fatura acumulativas e com fechamento de
                      fatura antes do pagamento da fatura.
                    </Typography>
                  </div>
                </CardContent>
              )}
              {typeClient === 5 && (
                <CardContent className="text-center">
                  <div className={classes.card}>
                    <FontAwesomeIcon
                      icon={faUserTag}
                      color="#1e5168"
                      size="4x"
                    />
                    <Typography gutterBottom variant="h6" color="primary">
                      Cliente com pós pago de estética
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      color="primary"
                    >
                      Clientes com fatura acumulativas e com fechamento de
                      fatura antes do pagamento da fatura.
                    </Typography>
                  </div>
                </CardContent>
              )}
            </Card>
          </Grid>
          <Grid item md={6}>
            <Card>
              {typeClient === 1 && (
                <CardContent>
                  <div className={classes.card}>
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      color="primary"
                    >
                      Exemplo: Condôminios, amigos com desconto
                      <br />
                      específico, clientes com cobrança automática.
                    </Typography>
                    <Button color="success" value={1} onClick={onSelect}>
                      Cadastrar
                    </Button>
                  </div>
                </CardContent>
              )}
              {typeClient === 2 && (
                <CardContent>
                  <div className={classes.card}>
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      color="primary"
                    >
                      Exemplo: Clientes que pagam um valor fixo todo mês, mensalistas.
                      Ou Clientes que pagam um valor fixo a cada quinzena ou semana.
                    </Typography>
                    <Button color="success" value={2} onClick={onSelect}>
                      Cadastrar
                    </Button>
                  </div>
                </CardContent>
              )}
              {typeClient === 3 && (
                <CardContent>
                  <div className={classes.card}>
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      color="primary"
                    >
                      Exemplo: Clientes como empresas, ou pessoas, que pagam
                      após uso dos serviços e acumulam um saldo em uma fatura.
                      Após o pagamento das faturas, as OS mudam o estado
                      financeiro de "A Faturar" para "Faturada". E o dinheiro
                      entra na empresa apenas após o pagamento da fatura.
                    </Typography>
                    <Button color="success" value={4} onClick={onSelect}>
                      Cadastrar
                    </Button>
                  </div>
                </CardContent>
              )}
              {typeClient === 5 && (
                <CardContent>
                  <div className={classes.card}>
                    <Typography
                      gutterBottom
                      variant="subtitle1"
                      color="primary"
                    >
                      Exemplo: Clientes como empresas, ou pessoas, que pagam
                      após uso dos serviços e acumulam um saldo em uma fatura.
                      Após o pagamento das faturas, as OS mudam o estado
                      financeiro de "A Faturar" para "Faturada". E o dinheiro
                      entra na empresa apenas após o pagamento da fatura.
                    </Typography>
                    <Button color="success" value={5} onClick={onSelect}>
                      Cadastrar
                    </Button>
                  </div>
                </CardContent>
              )}
            </Card>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}