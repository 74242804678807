import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Backdrop } from '@material-ui/core';
import Loader from 'components/Loader';

import BusinessInfo from './BusinessInfo';
import CityHallRegistration from './CityHallRegistration';

export default function BasicInfo({ formikProps }) {
  const {
    isRpsConfig,
    isCityLoaded
  } = useSelector(state => state.provisionalReceiptConfig);

  useEffect(() => {
    if(!isRpsConfig) {
      formikProps.setFieldValue('fantasyName', null);
      formikProps.setFieldValue('documentId', null);
      formikProps.setFieldValue('phone', null);
      formikProps.setFieldValue('email', null);
      formikProps.setFieldValue('companyName', null);
      formikProps.setFieldValue('postalCode', null);
      formikProps.setFieldValue('street', null);
      formikProps.setFieldValue('number', null);
      formikProps.setFieldValue('complement', null);
      formikProps.setFieldValue('district', null);
      formikProps.setFieldValue('city', null);
      formikProps.setFieldValue('state', null);
      formikProps.setFieldValue('cityCode', null);
      formikProps.setFieldValue('municipalSubscription', null);
      formikProps.setFieldValue('stateSubscription', null);
      formikProps.setFieldValue('taxationRegime', null);
      formikProps.setFieldValue('encouraging', 2);
      formikProps.setFieldValue('simpleNational', 2);
      formikProps.setFieldValue('issRetain', 2);
      formikProps.setFieldValue('activityCodeList.description', null);
      formikProps.setFieldValue('activityCodeList.serviceCode', null);
      formikProps.setFieldValue('activityCodeList.code', null);
      formikProps.setFieldValue('activityCodeList.aliquot', null);
      formikProps.setFieldValue('activityCodeList.itemListaServico', null);
    }
  }, [isRpsConfig]);

  useEffect(() => {
    if(+Object.keys(formikProps?.errors).length == 1) {
      if(formikProps?.errors?.warningMessageCheckbox) {
        return window.scrollTo(0, 0);
      }
    }
  }, [formikProps]);

  return(
    <>
      <Backdrop
        open={!isCityLoaded}
        style={{ zIndex: 10000 }}
      >
        <Loader
          spinner
          isLoading={!isCityLoaded}
        />
      </Backdrop>
      <BusinessInfo formikProps={formikProps} />
      <CityHallRegistration formikProps={formikProps} />
    </>
  );
}