import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { prop } from 'ramda';

import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import Input from '../../Input';
import Select from '../../Select';

import { registerFieldChange } from '../store/actions';
import { convertToOrganizeFilters } from '../../../helpers/converters';

import { AutocompleteStyles } from 'components/Filter/components/styles';

const FilterFields = ({ filters }) => {
  const dispatch = useDispatch();

  const autocompleteStyles = AutocompleteStyles();

  const { path } = useRouteMatch();
  const { filtrate } = useSelector(state => state.filters);

  const fieldChange = registerFieldChange;
  const onValueChange = func => e => dispatch(func(e.target.name, e.target.value));

  const isHistoryOperacional = path.split('/').includes('historico-operacional');
  const isNotaFiscal = path.split('/').includes('notas-fiscais');

  return(
    <Grid container={filters.length < 8}>
      {filters.length > 0 &&
        convertToOrganizeFilters(filters).map(field => {
          const { type, label, id, placeholder, options, maskType } = field;

          let value = prop(id, filtrate) || '';

          if(id === 'status') {
            if (filtrate && filtrate.status) {
              value = filtrate.status;
            } else {
              value = 1;
            }
          }

          if(id === 'brandId') {
            if(filtrate && filtrate.brandId) {
              value = options?.find(option => +option?.value == +filtrate.brandId) || {};
            } else {
              value = {};
            }
          }

          if(id === 'productTypeId') {
            if(filtrate && filtrate.productTypeId) {
              value = options?.find(option => +option?.value == +filtrate.productTypeId) || {};
            } else {
              value = {};
            }
          }

          if(id === 'typeDateTime' && isHistoryOperacional) {
            if (filtrate && filtrate.typeDateTime) {
              value = filtrate.typeDateTime;
            } else {
              value = '';
            }
          }

          if(id === 'typeDateTime' && isNotaFiscal) {
            if (filtrate && filtrate.typeDateTime) {
              value = filtrate.typeDateTime;
            } else {
              value = 'paymentDateTime';
            }
          }

          return(
            <Grid key={id} container alignItems="center">
              <Grid item xs={12}>
                {type === 'autocomplete' && (
                  <Autocomplete
                    id={id}
                    classes={autocompleteStyles}
                    options={options}
                    groupBy={option => option?.parent}
                    value={value}
                    onChange={(_, params) => dispatch(registerFieldChange(id, params?.value || ''))}
                    getOptionLabel={option => option.label}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        color="primary"
                        label={label}
                      />
                    )}
                  />
                )}
                {type === 'input' && (
                  <Input
                    fullWidth
                    name={id}
                    type={maskType || 'text'}
                    label={label}
                    placeholder={placeholder}
                    value={value}
                    onChange={onValueChange(fieldChange)}
                  />
                )}
                {type === 'select' && (
                  <Select
                    name={id}
                    label={label}
                    options={options}
                    value={value}
                    onChange={onValueChange(fieldChange)}
                  />
                )}
              </Grid>
            </Grid>
          );
        })}
    </Grid>
  );
}

FilterFields.defaultProps = {
  filters: []
};

FilterFields.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      placeholder: PropTypes.string,
      type: PropTypes.string,
      value: PropTypes.string,
      advanced: PropTypes.bool
    })
  )
};

export default FilterFields;