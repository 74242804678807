import { newAPI } from '../../../services';
import qs from 'qs';

export const fetchProvisionalReceipt = async url => {
  const response = await newAPI.get(url);
  return response.data;
}

export const sendNewNfse = async (url, params) => {
  const response = await newAPI.put(url, qs.stringify(params));
  return response.data;
}