import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from 'formik';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Card from '../../../../../components/Card';
import Loader from '../../../../../components/Loader';
import Input from '../../../../../components/Input';

import { loadProvisionalReceiptInfo } from '../../../store/thunk';

export default function ConfigRps({ formikProps }) {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { isLoading } = useSelector(state => state.businessInfo);

  const {
    lastRpsSuccess,
    configType,
    submit: {
      taxSettingsOwner,
      establishmentTaxSettingOwner
    }
  } = useSelector(state => state.provisionalReceiptConfig);

  const isSameCnpj = +configType == 0;

  const [check, setCheck] = useState(false);

  useEffect(() => {
    if(formikProps.values.rpsGenerateOnline === 1) {
      setCheck(true);
    }

    if(establishmentId) {
      dispatch(loadProvisionalReceiptInfo(userId, establishmentId));
    }
  }, [establishmentId]);

  return(
    <Card title="Configuração de geração do RPS">
      {(+taxSettingsOwner == 0) && (
        <Grid item xs={12}>
          <Grid
            container
            style={{
              flexDirection: 'column',
              marginTop: 15,
              marginBottom: 15,
              backgroundColor: '#f6ebe9',
              border: '1px solid #efd7d7',
              borderRadius: 10
            }}
          >
            <Grid
              item
              spacing={2}
              style={{ padding: 10 }}
            >
              <Typography style={{ color: '#aa513d', margin: 5 }}>
                Se quiser editar a configuração precisa acessar essa tela com o estabelecimento
                <b>{` ${establishmentTaxSettingOwner?.name} ${establishmentTaxSettingOwner?.address?.street}, ${establishmentTaxSettingOwner?.address?.number} - ${establishmentTaxSettingOwner?.address?.district}, ${establishmentTaxSettingOwner?.address?.city}`}.</b>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <Form>
          <Loader isLoading={isLoading}>
            <Grid
              container
              style={{
                flexDirection: 'column',
                padding: 15,
                backgroundColor: 'rgb(241, 244, 249)',
                border: '1px solid #38a8d8',
                borderRadius: 10
              }}
            >
              <Grid item spacing={2}>
                <Typography variant="h6" color="primary">
                  Atenção:
                </Typography>
                <Typography
                  variant="h6"
                  style={{ fontWeight: 400, fontSize: '15px' }}
                >
                  1. Se a sua prefeitura não exige a configuração de série única,
                  não marque a opção de série única.
                </Typography>
                <Typography
                  variant="h6"
                  style={{ fontWeight: 400, fontSize: '15px' }}
                >
                  2. Ao marcar a opção o RPS será gerado online e não será
                  impresso no ticket.
                </Typography>
                <Typography
                  variant="h6"
                  style={{ fontWeight: 400, fontSize: '15px' }}
                >
                  3. Verifique qual a Série Única da sua Prefeitura.
                </Typography>
                <Typography
                  variant="h6"
                  style={{ fontWeight: 400, fontSize: '15px' }}
                >
                  A "numeração a partir de" é opcional.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={isSameCnpj}
                    name="configRps"
                    checked={check}
                    onChange={() => {
                      setCheck(!check);

                      if(!check) {
                        formikProps.setFieldValue('rpsGenerateOnline', 1);
                        return;
                      }

                      formikProps.setFieldValue('rpsGenerateOnline', 0);
                    }}
                  />
                }
                label="Série única"
              />
              {(!!lastRpsSuccess?.series && !!lastRpsSuccess?.number) && (
                <Typography>Última RPS gerada com sucesso: {lastRpsSuccess?.series}/{lastRpsSuccess?.number}</Typography>
              )}
            </Grid>
            <Grid container spacing={2}>
              <Grid item sm={5} xs={12}>
                <Input
                  disabled={!check || isSameCnpj}
                  name="rpsSeries"
                  type="text"
                  label="Série"
                  placeholder="Série"
                  maxLength={4}
                  value={formikProps.values.rpsSeries}
                  error={formikProps.errors.rpsSeries}
                  helperText={formikProps.errors.rpsSeries}
                  onChange={formikProps.handleChange}
                />
              </Grid>
              <Grid item sm={5} xs={12}>
                <Input
                  disabled={!check || isSameCnpj}
                  name="rpsCount"
                  type="text"
                  label="Numeração de RPS a partir de"
                  placeholder="Numeração de RPS a partir de"
                  value={formikProps.values.rpsCount}
                  error={formikProps.errors.rpsCount}
                  onChange={formikProps.handleChange}
                />
              </Grid>
            </Grid>
          </Loader>
        </Form>
      </Grid>
    </Card>
  );
}

ConfigRps.defaultProps = {
  formikProps: {
    handleChange: () => {},
    setFieldValue: () => {},
    values: {
      establishmentName: '',
      companyName: '',
      documentId: '',
      phone: '',
      email: '',
      postalCode: '',
      street: '',
      number: 0,
      complement: '',
      district: '',
      city: '',
      state: '',
      vacanciesMarks: ''
    },
    errors: {
      companyName: false,
      documentId: false,
      phone: false,
      email: false,
      postalCode: false,
      street: false,
      number: false,
      complement: false,
      district: false,
      city: false,
      state: false,
      vacanciesMarks: false
    }
  }
};

ConfigRps.propTypes = {
  formikProps: PropTypes.shape()
};