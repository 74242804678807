import { toast } from 'react-toastify';
import { omit } from 'ramda';

import {
  createRPSConfig,
  updateRPSConfig,
  requestToUpdateCertificate,
  requestToUpdatePassword,
  requestToUpdateToken,
  requestToUpdateSettings,
  requestToUpdateFilters,
  fetchProvisionalReceiptInfo,
  fetchEconomicActivities,
  requestToUpdateLogo,
  loadRegimeEstadual,
  fetchEstablishmentsWithConfig,
  requestAttachEstablishment,
  requestDetachEstablishment,
  requetSendNfse
} from './service';

import {
  setProvisionalReceiptInfo,
  setProvisionalReceiptErrors,
  clearProvisionalReceiptErrors,
  setEconomicActivities,
  setLogoFile,
  handleLoading,
  handleCertificateLoading,
  clearCertificate,
  clearProvisionalReceiptInfo,
  setEstablishmentsWithConfig,
  setLastRpsSuccess,
  isRpsConfig,
  isSendNfseLoading
} from './actions';

export const updateLogo = (userId, establishmentId, params) => dispatch => {
  const formData = new FormData();
  formData.append('logo', params);

  return requestToUpdateLogo(userId, establishmentId, formData).then(({ data }) => {
    dispatch(setLogoFile(data));
  });
}

export const loadProvisionalReceiptInfo = (userId, establishmentId) => dispatch => {
  dispatch(handleLoading(true));

  return fetchProvisionalReceiptInfo(userId, establishmentId)
    .then((res) => {
      dispatch(setProvisionalReceiptInfo(res.data));
      dispatch(setLastRpsSuccess(res.lastRpsSuccess));
      dispatch(isRpsConfig(true));
    })
    .catch(error => {
      if (error.response && error.response.data.data.code === '17001') {
        dispatch(clearProvisionalReceiptInfo());
        dispatch(isRpsConfig(false));
      }
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const loadEconomicActivities = userId => dispatch => {
  dispatch(handleLoading(true));

  return fetchEconomicActivities(userId)
    .then(({ data }) => dispatch(setEconomicActivities(data)))
    .finally(() => dispatch(handleLoading(false)));
}

export const createRps = params => dispatch => {
  const { userId, establishmentId } = params;

  const formatParams = omit(['password', 'passwordConfirm', 'logo', 'userId', 'establishmentId'], params);

  return createRPSConfig(userId, establishmentId, formatParams)
    .then(({ data }) => {
      toast.success('Configuração de nota fiscal criada com sucesso!');
      dispatch(clearProvisionalReceiptErrors());
      dispatch(setProvisionalReceiptInfo(data));
    })
    .catch((error) => {
      if(Array.isArray(error)) {
        dispatch(setProvisionalReceiptErrors(error));
      }

      return Promise.reject();
    })
    .finally(() => {
      dispatch(handleLoading(false));
    });
}

export const upsertRPS = params => (dispatch, getState) => {
  const { logoFile } = getState().provisionalReceiptConfig;
  const { userId, establishmentId } = params;

  dispatch(handleLoading(true));

  if(logoFile?.name) {
    dispatch(updateLogo(userId, establishmentId, params.logo));
  }

  const formatParams = omit(['password', 'passwordConfirm', 'logo', 'userId', 'establishmentId'], params);

  return updateRPSConfig(userId, establishmentId, formatParams)
    .then(({ data }) => {
      toast.success('Nota fiscal atualizada com sucesso!');
      dispatch(clearProvisionalReceiptErrors());
      dispatch(setProvisionalReceiptInfo(data));
    })
    .catch((error) => {
      if(Array.isArray(error)) {
        dispatch(setProvisionalReceiptErrors(error));
      }

      return Promise.reject();
    })
    .finally(() => {
      dispatch(handleLoading(false));
    });
}

export const updateCertificate = params => dispatch => {
  dispatch(handleCertificateLoading(true));

  const { userId, establishmentId } = params;

  return requestToUpdateCertificate(userId, establishmentId, params)
    .then(() => {
      toast.success("Autenticação concluída com sucesso");
      dispatch(clearCertificate());
      return Promise.resolve();
    })
    .catch(() => Promise.reject())
    .finally(() => dispatch(handleCertificateLoading(false)));
}

export const updateConfigRps = params => dispatch => {
  dispatch(handleCertificateLoading(true));

  return requestToUpdateCertificate(params)
    .then(({ data }) => toast.success(data.msg))
    .finally(() => dispatch(handleCertificateLoading(false)));
}

export const updatePassword = params => dispatch => {
  dispatch(handleLoading(true));

  const { userId, establishmentId } = params;

  return requestToUpdatePassword(userId, establishmentId, params)
    .then(() => {
      toast.success("Autenticação concluída com sucesso.");
      return Promise.resolve();
    })
    .catch(() => Promise.reject())
    .finally(() => dispatch(handleLoading(false)));
}

export const updateToken = params => dispatch => {
  dispatch(handleLoading(true));

  const { userId, establishmentId } = params;

  return requestToUpdateToken(userId, establishmentId, params)
    .then(() => {
      toast.success("Autenticação concluída com sucesso.");
      return Promise.resolve();
    })
    .catch(() => Promise.reject())
    .finally(() => dispatch(handleLoading(false)));
}

export const updateSettings = params => dispatch => {
  dispatch(handleLoading(true));

  const { userId, establishmentId } = params;

  return requestToUpdateSettings(userId, establishmentId, params)
    .then(() => {
      toast.success("Configuração atualizada com sucesso.");
      return Promise.resolve();
    })
    .catch(() => Promise.reject())
    .finally(() => dispatch(handleLoading(false)));
}

export const updateFilters = params => dispatch => {
  dispatch(handleLoading(true));

  const { userId, establishmentId } = params;

  return requestToUpdateFilters(userId, establishmentId, params)
    .then(() => {
      toast.success("Configuração do filtro atualizada com sucesso.");
      return Promise.resolve();
    })
    .catch(() => Promise.reject())
    .finally(() => dispatch(handleLoading(false)));
}

export const loadRegime = (userId, codigoIbge) => {
  return loadRegimeEstadual(userId, codigoIbge)
    .then(data => data)
    .catch(() => toast.error('Problemas no servidor, tente novamente mais tarde'));
}

export const loadEstablishmentsWithConfig = (userId, establishmentId) => dispatch => {
  dispatch(handleLoading(true));

  return fetchEstablishmentsWithConfig(userId, establishmentId)
    .then(({ data }) => dispatch(setEstablishmentsWithConfig(data)))
    .finally(() => dispatch(handleLoading(false)));
}

export const attachEstablishment = (userId, establishmentId, taxSettingUuid) => dispatch => {
  dispatch(handleLoading(true));

  return requestAttachEstablishment(userId, establishmentId, taxSettingUuid)
    .then(({ data }) => {
      dispatch(setProvisionalReceiptInfo({ ...data, taxSettingsOwner: 0 }));
    })
    .catch(() => toast.error('Houve um problema ao vincular o estabelecimento.'))
    .finally(() => dispatch(handleLoading(false)));
}

export const detachEstablishment = (userId, establishmentId) => dispatch => {
  dispatch(handleLoading(true));

  return requestDetachEstablishment(userId, establishmentId)
    .catch(() => toast.error('Houve um problema ao desvincular o estabelecimento.'))
    .finally(() => dispatch(handleLoading(false)));
}

export const sendNfse = (userId, establishmentId, transactionId) => dispatch => {
  dispatch(isSendNfseLoading(true));

  return requetSendNfse(userId, establishmentId, transactionId)
    .then(() => toast.success('NFS-e enviada com sucesso.'))
    .catch(() => toast.error('Não foi possível enviar a NFS-e.'))
    .finally(() => dispatch(isSendNfseLoading(false)));
}