import { setEmployeesList, setEmployeeRegister, handleLoading } from './actions';
import { fetchEmployees, fetchEmployeeRegister } from './services';

export const loadEmployees = ({ page, pageSize, qp = '', userId, establishmentId }) => dispatch => {
  let url = `${userId}/users?&pg=${page}&limit=${pageSize}${qp}`;

  if(establishmentId) {
    url = `${userId}/establishment/${establishmentId}/users?&pg=${page}&limit=${pageSize}${qp}`;
  }

  dispatch(handleLoading(true));

  return fetchEmployees(url)
    .then(({ data }) => {
      const newData = data.content.filter(item => {
        if (item.profileName !== 'Manobrista') {
          return item;
        }
      });
      dispatch(setEmployeesList(newData, newData.length));
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const loadEmployeeRegister = (userId, registerId) => dispatch => {
  return fetchEmployeeRegister(userId, registerId)
    .then(({ data }) => dispatch(setEmployeeRegister(data)));
}