import * as yup from 'yup';
import { phoneRegExp } from '../../../../../../helpers/regex';
import { validateDocument, validateCnpj } from '../../../../../../helpers/validations';
import moment from 'moment';

export const validations = yup.object().shape({
  name: yup
    .string()
    .required('O nome é obrigatório'),
  email: yup
    .string()
    .trim()
    .email('O e-mail precisa ser válido')
    .nullable(),
  phone: yup
    .string()
    .matches(phoneRegExp, 'O telefone precisa ser válido')
    .nullable(),
  documentId: yup
    .string()
    .nullable()
    .test('documentId', 'O CPF/CNPJ precisa ser válido', value => {
      let result = true;

      if (!value || value.length === 0) {
        result = true;
      } else if (value.length === 14) {
        result = validateDocument(value);
      } else {
        result = validateCnpj(value);
      }

      return result;
    }),
  dateOfBirth: yup
    .string()
    .nullable()
    .test('dateOfBirth', 'A data de nascimento precisa ser válida', value => {
      if(value == '--' || value == '') {
        return true;
      }

      const date = moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD');
      return moment(value).isValid() || moment(date).isValid();
    })
});