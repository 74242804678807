import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';

import { resendNfse } from '../../store/thunk';
import { handleError } from '../../store/actions';

export default function ProvisionalReceiptError({ open, error, page, pageSize, qp, extraProps }) {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { transactionId, establishmentId, paymentDateTime, isLoading } = useSelector(state => state.provisionalReceipt);

  const [confirmResendNfse, setConfirmResendNfse] = useState(false);

  const isFiveDaysPastFromPayment =
    moment(moment.now()).diff(moment(paymentDateTime, 'YYYY-MM-DD'), 'days') > 5;

  const handleCloseError = () => {
    dispatch(handleError({ error: [], transactionId: null }));
  }

  const handleSendAgain = () => {
    const params = {
      resend: 1
    }

    dispatch(resendNfse(userId, establishmentId, transactionId, page, pageSize, qp, extraProps, params, handleCloseError));
  }

  return(
    <Modal
      id="service-order"
      title="Erros da nota fiscal"
      scroll="body"
      open={open}
      onClose={handleCloseError}
      maxWidth="sm"
      modalActions={
        <Button
          color="success"
          disabled={isLoading || (isFiveDaysPastFromPayment && !confirmResendNfse)}
          onClick={handleSendAgain}
        >
          Reenviar NFS-e
        </Button>
      }
    >
      {error.length > 0 ? (
        error.map(e => (
          <Grid style={{ marginBottom: '8px' }}>
            {e.code && (
              <Typography variant="body1" color="textSecondary">
                <strong>Código:</strong> {e.code}
              </Typography>
            )}
            {e.message && (
              <Typography variant="body1" color="textSecondary">
                <strong>Mensagem:</strong> {e.message}
              </Typography>
            )}
            {e.solution && (
              <Typography variant="body1" color="textSecondary">
                <strong>Solução:</strong> {e.solution}
              </Typography>
            )}
            {isFiveDaysPastFromPayment && (
              <Grid style={{ marginTop: 15 }}>
                <Typography variant="body1" color="textSecondary">
                  <b>Atenção! Mais de 5 dias se passaram desde o pagamento, fique atento a uma possível cobrança de multa por parte da prefeitura.</b>
                </Typography>
                <FormControlLabel
                  labelPlacement="end"
                  label="Entendi"
                  control={
                    <Checkbox
                      value={confirmResendNfse}
                      onChange={event => setConfirmResendNfse(event.target.checked)}
                    />
                  }
                />
              </Grid>
            )}
          </Grid>
        ))
      ) : (
        <Typography variant="body1" color="textSecondary">
          Não há mensagens a serem exibidas
        </Typography>
      )}
    </Modal>
  );
}

ProvisionalReceiptError.defaultProps = {
  error: []
};

ProvisionalReceiptError.propTypes = {
  open: PropTypes.bool.isRequired,
  error: PropTypes.string
};