import * as type from './constants';

export const setProvisionalReceipt = (data, total) => ({
  type: type.SET_RPS,
  data,
  total
});

export const handleLoading = status => ({
  type: type.HANDLE_LOADING,
  status
});

export const handleError = ({ error, transactionId, establishmentId, paymentDateTime }) => ({
  type: type.HANDLE_ERROR,
  error,
  transactionId,
  establishmentId,
  paymentDateTime
});