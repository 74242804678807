import { combineReducers } from 'redux';

import filtersReducers from '../components/Filter/store/reducer';
import paginationReducers from '../components/Pagination/store/reducer';
import dateFilterReducers from '../components/LayoutRoute/store/reducer';
import dashboardReducer from '../pages/Dashboard/store/reducer';
import loginReducer from '../pages/Login/store/reducer';
import operationalHistoryReducer from '../pages/OperationalHistory/store/reducer';
import serviceOrderReducer from '../components/ServiceOrder/store/reducer';
import activitiesReducer from '../pages/ActivitiesRegistration/store/reducer';
import reservationsReducer from '../pages/Reservations/store/reducer';
import sessionsReducer from '../pages/SessionsRegistration/store/reducer';
import profileReducer from '../pages/Profile/store/reducer';
import cashFlowReducer from '../pages/CashFlow/store/reducer';
import transactionsReducer from '../pages/Transactions/store/reducer';
import provisionalReceiptReducer from '../pages/ProvisionalReceipt/store/reducer';
import servicesReducer from '../pages/Services/store/reducer';
import employeesListReducers from '../pages/EmployeesList/store/reducer';
import employeesRegistrationReducers from '../pages/EmployeesRegistration/store/reducer';
import priceTableReducer from '../pages/PriceTable/store/reducer';
import cardsReducer from '../pages/Cards/store/reducer';
import businessInfoReducer from '../pages/BusinessInfo/store/reducer';
import clientListReducer from '../pages/ClientList/store/reducer';
import clientInvoicesReducer from '../pages/ClientInvoices/store/reducer';
import clientSelectionReducer from '../pages/ClientSelection/store/reducer';
import clientRegistrationReducer from '../pages/ClientRegistration/store/reducer';
import accountsReducer from '../pages/Accounts/store/reducer';
import categoriesReducer from '../pages/Categories/store/reducer';
import costCentersReducer from '../pages/CostCenters/store/reducer';
import paymentMethodsReducer from '../pages/PaymentMethods/store/reducer';
import reservationsConfigReducer from '../pages/ReservationsConfig/store/reducer';
import provisionalReceiptConfigReducer from '../pages/ProvisionalReceiptConfig/store/reducer';
import notificationsReducer from '../pages/NotificationsList/store/reducer';
import notificationsConfigReducer from '../pages/NotificationsConfig/store/reducer';
import operationalReducer from '../pages/Operational/store/reducer';
import typePermissionReducer from './permission/reducer';
import onboardingReducer from '../components/Onboarding/store/reducer';
import comissionsReducer from '../pages/Comissions/store/reducer';
import comissionedListReducer from '../pages/ComissionedList/store/reducer';
import comissionedPayrollsReducer from '../pages/ComissionedPayrolls/store/reducer';
import comissionedRegistrationReducer from '../pages/ComissionedRegistration/store/reducer';
import comissionedPayrollListReducer from '../pages/ComissionedPayrollList/store/reducer';
import comissionedPayrollRegistrationReducer from '../pages/ComissionedPayrollRegistration/store/reducer';
import ComissionedPayrollSheetReducer from '../pages/ComissionedPayrollSheet/store/reducer';
import VehicleDamageReducer from '../pages/VehicleDamage/store/reducer';
import ProductsReducer from '../pages/Products/store/reducer';
import ProductsRegistrationReducer from '../pages/ProductsRegistration/store/reducer';
import ProductsSellRegistrationReducer from '../pages/ProductsSellRegistration/store/reducer';
import ProductsUseAndSellRegistrationReducer from '../pages/ProductsUseAndSellRegistration/store/reducer';
import StorageHistoryReducer from '../pages/StorageHistory/store/reducer';
import SellOrdersReducer from '../pages/SellOrders/store/reducer';

export default combineReducers({
  accounts: accountsReducer,
  activities: activitiesReducer,
  businessInfo: businessInfoReducer,
  cards: cardsReducer,
  cashFlow: cashFlowReducer,
  categories: categoriesReducer,
  clientList: clientListReducer,
  clientInvoices: clientInvoicesReducer,
  clientSelection: clientSelectionReducer,
  clientRegistration: clientRegistrationReducer,
  costCenters: costCentersReducer,
  dateFilter: dateFilterReducers,
  dashboard: dashboardReducer,
  employeesList: employeesListReducers,
  employeesRegistration: employeesRegistrationReducers,
  filters: filtersReducers,
  login: loginReducer,
  operationalHistory: operationalHistoryReducer,
  pagination: paginationReducers,
  paymentMethods: paymentMethodsReducer,
  priceTable: priceTableReducer,
  profile: profileReducer,
  provisionalReceipt: provisionalReceiptReducer,
  reservations: reservationsReducer,
  reservationsConfig: reservationsConfigReducer,
  serviceOrder: serviceOrderReducer,
  services: servicesReducer,
  sessions: sessionsReducer,
  transactions: transactionsReducer,
  notifications: notificationsReducer,
  notificationsConfig: notificationsConfigReducer,
  provisionalReceiptConfig: provisionalReceiptConfigReducer,
  operational: operationalReducer,
  typePermission: typePermissionReducer,
  onboarding: onboardingReducer,
  comissions: comissionsReducer,
  comissionedList:comissionedListReducer,
  comissionedPayrolls: comissionedPayrollsReducer,
  comissionedRegistration: comissionedRegistrationReducer,
  comissionedPayrollList: comissionedPayrollListReducer,
  comissionedPayrollRegistration: comissionedPayrollRegistrationReducer,
  comissionedPayrollSheet: ComissionedPayrollSheetReducer,
  vehicleDamage: VehicleDamageReducer,
  products: ProductsReducer,
  productsRegistration: ProductsRegistrationReducer,
  productsSellRegistration: ProductsSellRegistrationReducer,
  productsUseAndSellRegistration: ProductsUseAndSellRegistrationReducer,
  storageHistory: StorageHistoryReducer,
  sellOrders: SellOrdersReducer
});