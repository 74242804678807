import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { Grid, Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Card from 'components/Card';
import Loader from 'components/Loader';
import CardFooter from 'components/CardFooter';
import Button from 'components/Button';
import Title from '../../components/Title';
import Subtitle from '../../components/Subtitle';

import { loadConfigurations, upsertConfigurations } from '../../store/thunk';

export default function Operation() {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { isLoading } = useSelector(state => state.operational);
  const { settingHistoryUserName, settingHistoryDateTime } = useSelector(state => state.operational);

  const {
    serviceOnly,
    manualCovenant,
    manualTime,
    recurrentClientEntryOption,
    prePaidExit,
    requireReceiptCovantTypePrice,
    requireReceiptExpense
  } = useSelector(state => state.operational.operation);

  const [operationServiceOnly, setOperationServiceOnly] = useState(serviceOnly || false);
  const [operationManualCovenant, setOperationManualCovenant] = useState(manualCovenant ||false);
  const [operationManualTime, setOperationManualTime] = useState(manualTime || false);
  const [operationRecurrentClientEntryOption, setOperationRecurrentClientEntryOption] = useState(recurrentClientEntryOption || false);
  const [operationPrePaidExit, setOperationPrePaidExit] = useState(prePaidExit || false);
  const [operationRequireReceiptCovantTypePrice, setOperationRequireReceiptCovantTypePrice] = useState(requireReceiptCovantTypePrice || false);
  const [operationRequireReceiptExpense, setOperationRequireReceiptExpense] = useState(requireReceiptExpense || false);

  useEffect(() => {
    if(establishmentId) {
      dispatch(loadConfigurations(userId, establishmentId));
    }
  }, [establishmentId]);

  useEffect(() => {
    setOperationServiceOnly(false);
    setOperationManualCovenant(false);
    setOperationManualTime(false);
    setOperationRecurrentClientEntryOption(false);
    setOperationPrePaidExit(false);

    if(establishmentId) {
      setOperationServiceOnly(serviceOnly);
      setOperationManualCovenant(manualCovenant);
      setOperationManualTime(manualTime);
      setOperationRecurrentClientEntryOption(recurrentClientEntryOption);
      setOperationPrePaidExit(prePaidExit);
      setOperationRequireReceiptCovantTypePrice(requireReceiptCovantTypePrice);
      setOperationRequireReceiptExpense(requireReceiptExpense);
    }
  }, [establishmentId, serviceOnly, manualCovenant, manualTime, recurrentClientEntryOption, prePaidExit, requireReceiptCovantTypePrice, requireReceiptExpense]);

  const handleConfigurationsSave = () => {
    const params = {
      serviceOnly: checkedValueByType(operationServiceOnly, 'number'),
      manualCovenant: checkedValueByType(operationManualCovenant, 'number'),
      manualTime: checkedValueByType(operationManualTime, 'number'),
      recurrentClientEntryOption: checkedValueByType(operationRecurrentClientEntryOption, 'number'),
      prePaidExit: checkedValueByType(operationPrePaidExit, 'number'),
      requireReceiptCovantTypePrice: checkedValueByType(operationRequireReceiptCovantTypePrice, 'number'),
      requireReceiptExpense: checkedValueByType(operationRequireReceiptExpense, 'number'),
      page: 'Operação'
    }

    dispatch(upsertConfigurations(userId, establishmentId, params));
  }

  const checkedValueByType = (value, type) => {
    if(type == 'boolean') {
      return value == 1;
    }

    if(type == 'number') {
      return value == true ? 1 : 0;
    }
  }

  return(
    <Card title="Operação">
      <Grid xs={12} alignItems='center'>
        <Loader isLoading={isLoading}>
          <Grid container>
            {(!!settingHistoryUserName && !!settingHistoryDateTime) && (
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography>
                  <b>Última alteração feita por {settingHistoryUserName} em {moment(settingHistoryDateTime).format('LLL')}</b>
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Title>Permitir <strong>somente serviços</strong></Title>
              <Subtitle>Permite para quando selecionar um serviço, automaticamente o estacionamento não será cobrado.</Subtitle>
              <FormControlLabel
                control={
                  <Checkbox
                    name="serviceOnly"
                    type="checkbox"
                    checked={operationServiceOnly}
                    onChange={e => setOperationServiceOnly(checkedValueByType(e.target.checked, 'boolean'))}
                  />
                }
                label="Sim, eu permito."
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 20 }}>
            <Grid item xs={12}>
              <Title>Permitir <strong>convênio</strong> manual</Title>
              <Subtitle>Permite que o operador manualmente indique qual é o convênio que o motorista está utilizando.</Subtitle>
              <FormControlLabel
                control={
                  <Checkbox
                    name="manualCovenant"
                    type="checkbox"
                    checked={operationManualCovenant}
                    onChange={e => setOperationManualCovenant(checkedValueByType(e.target.checked, 'boolean'))}
                  />
                }
                label="Sim, eu permito."
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 20 }}>
            <Grid item xs={12}>
              <Title>Permitir <strong>horário</strong> manual</Title>
              <Subtitle>Permite que o operador manualmente indique qual é o horário corrente do aparelho (celular/tablet).</Subtitle>
              <FormControlLabel
                control={
                  <Checkbox
                    name="manualTime"
                    type="checkbox"
                    checked={operationManualTime}
                    onChange={e => setOperationManualTime(checkedValueByType(e.target.checked, 'boolean'))}
                  />
                }
                label="Sim, eu permito."
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 20 }}>
            <Grid item xs={12}>
              <Title>Permitir a{' '}<strong>entrada sem cartão e impressão</strong> para clientes recorrentes</Title>
              <Subtitle>Opção de não obrigar a entrega do cartão quando o cliente for mensalista. O sistema reconhece pela placa e não exige o cartão para a entrada.</Subtitle>
              <FormControlLabel
                control={
                  <Checkbox
                    name="recurrentClientEntryOption"
                    type="checkbox"
                    checked={operationRecurrentClientEntryOption}
                    onChange={e => setOperationRecurrentClientEntryOption(checkedValueByType(e.target.checked, 'boolean'))}
                    value={0}
                  />
                }
                label="Sim, eu permito."
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 20 }}>
            <Grid item xs={12}>
              <Title>Forçar <strong>pagamento antecipado com saída</strong> do veículo.</Title>
              <Subtitle>Forçar que ao dar entrada no veículo o operador dê entrada com pagamento antecipado e que o veículo saia automaticamente após a entrada.</Subtitle>
              <FormControlLabel
                control={
                  <Checkbox
                    name="prePaidExit"
                    type="checkbox"
                    checked={operationPrePaidExit}
                    onChange={e => setOperationPrePaidExit(checkedValueByType(e.target.checked, 'boolean'))}
                  />
                }
                label="Sim, eu permito."
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 20 }}>
            <Grid item xs={12}>
              <Title>Obrigar comprovante ao selecionar uma <strong>tabela convênio</strong>.</Title>
              <FormControlLabel
                control={
                  <Checkbox
                    name="prePaidExit"
                    type="checkbox"
                    checked={operationRequireReceiptCovantTypePrice}
                    onChange={e => setOperationRequireReceiptCovantTypePrice(checkedValueByType(e.target.checked, 'boolean'))}
                  />
                }
                label="Sim, eu permito."
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 20 }}>
            <Grid item xs={12}>
              <Title>Obrigar comprovante ao fazer um <strong>registro de despesa</strong> no aplicativo operacional.</Title>
              <FormControlLabel
                control={
                  <Checkbox
                    name="prePaidExit"
                    type="checkbox"
                    checked={operationRequireReceiptExpense}
                    onChange={e => setOperationRequireReceiptExpense(checkedValueByType(e.target.checked, 'boolean'))}
                  />
                }
                label="Sim, eu permito."
              />
            </Grid>
          </Grid>
          <Grid xs={12}>
            <CardFooter>
              <Button
                color="primary"
                onClick={handleConfigurationsSave}
              >
                Salvar
              </Button>
            </CardFooter>
          </Grid>
        </Loader>
      </Grid>
    </Card>
  );
}