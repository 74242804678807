import * as yup from 'yup';

export const configRpsValidations = yup.object().shape({
  rpsSeries: yup
    .string()
    .when(['rpsGenerateOnline'], {
      is: rpsGenerateOnline => rpsGenerateOnline == 1,
      then: yup.string().required('O campo série é obrigatório')
    })
    .nullable()
});