export const REGISTER_FIELD_CHANGE = 'RPS_INFO/REGISTER_FIELD_CHANGE';
export const SET_RPS_INFO = 'RPS_INFO/SET_RPS_INFO';
export const SET_RPS_ERRORS = 'RPS_INFO/SET_RPS_ERRORS';
export const CLEAR_RPS_ERRORS = 'RPS_INFO/CLEAR_RPS_ERRORS';
export const SET_SERVICES = 'RPS_INFO/SET_SERVICES';
export const SET_ECONOMIC_ACTIVIES = 'RPS_INFO/SET_ECONOMIC_ACTIVIES';
export const SET_CERTIFICATE_FILE = 'RPS_INFO/SET_CERTIFICATE_FILE';
export const SET_LOGO_FILE = 'RPS_INFO/SET_LOGO_FILE';
export const CLEAR_RPS_INFO = 'RPS_INFO/CLEAR_RPS_INFO';
export const CLEAR_CERTIFICATE = 'RPS_INFO/CLEAR_CERTIFICATE';
export const HANDLE_LOADING = 'RPS_INFO/HANDLE_LOADING';
export const HANDLE_CERTIFICATE_LOADING = 'RPS_INFO/HANDLE_CERTIFICATE_LOADING';
export const SET_DATA_PAGE = 'RPS_INFO/DATA_PAGE';
export const SET_CITY_CODE = 'RPS_INFO/SET_CITY_CODE';
export const SET_IS_CITY_LOADED = 'RPS_INFO/SET_IS_CITY_LOADED';
export const SET_WARNING_MESSAGE = 'RPS_INFO/SET_WARNING_MESSAGE';
export const SET_RESTRICTIONS = 'RPS_INFO/SET_RESTRICTIONS';
export const SET_CONFIG_TYPE = 'RPS_INFO/SET_CONFIG_TYPE';
export const SET_ESTABLISHMENTS_WITH_CONFIG = 'RPS_INFO/SET_ESTABLISHMENTS_WITH_CONFIG';
export const SET_SELECTED_ESTABLISHMENTS_WITH_CONFIG = 'RPS_INFO/SET_SELECTED_ESTABLISHMENTS_WITH_CONFIG';
export const SET_LAST_RPS_SUCCESS = 'RPS_INFO/SET_LAST_RPS_SUCCESS';
export const IS_RPS_CONFIG = 'RPS_INFO/IS_RPS_CONFIG';
export const SET_IS_SEND_NFSE_LOADING = 'RPS_INFO/SET_IS_SEND_NFSE_LOADING';