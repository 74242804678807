import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Grid, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';

import Filter from '../../../components/Filter/components';
import FilterMobile from '../../../components/FilterMobile/components';
import MTable from '../../../components/MTable';
import ModalConfirmation from '../../../components/ModalConfirmation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';

import { loadCards, insertCard, removeCards } from '../store/thunk';
import { textChangeToInsertForm, clearCardsSelected, selectCard, selectSingleCard, toggleToRemoveCard } from '../store/actions';

const headers = [
  { title: 'Número do cartão', field: 'cardCode', sortable: false }
];

const width = window.innerWidth;
const tableRowWidth = width < 768 ? 120 : 100;

export default function Cards() {
  const dispatch = useDispatch();

  const width = window.innerWidth;
  const isMobile = width < 1024;

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { qp } = useSelector(state => state.filters);

  const {
    cardCodes,
    cards,
    inputText,
    total,
    isLoading,
    isRemoving
  } = useSelector(state => state.cards);

  const [cardsPage, setCardsPage] = useState(1);
  const cardsPageSize = 10;
  const cardsPageCount = Math.ceil(total / cardsPageSize);

  const handleTextChangeInInsertForm = e => {
    dispatch(textChangeToInsertForm(e.target.id, e.target.value));
  }

  const onSaveCard = () => {
    const params = { cardCode: inputText }

    if(!inputText) {
      return toast.error('Preencha o campo do número do cartão para cadastrar.');
    }

    return dispatch(insertCard(userId, establishmentId, params, { page: cardsPage, pageSize: cardsPageSize }));
  }

  const toggleToRemoveCards = () => {
    dispatch(toggleToRemoveCard(!isRemoving));
  }

  const onSelectSingleCard = row => {
    const { cardCode } = row;

    dispatch(selectSingleCard(cardCode));
    toggleToRemoveCard(!isRemoving);
  }

  const onRemoveCards = () => {
    dispatch(removeCards({ userId, establishmentId, properties: { page: cardsPage, pageSize: cardsPageSize }, cardCodes }));
  }

  const onSelectAllRows = rows => {
    dispatch(clearCardsSelected());

    rows.forEach(row => {
      const { cardCode } = row;
      dispatch(selectCard(cardCode, row.tableData.checked));
    });
  }

  const requestLoadCards = ({ page, qp }) => {
    if(establishmentId) {
      dispatch(loadCards({ userId, establishmentId, page, pageSize: cardsPageSize, qp }));
    }
  }

  useEffect(() => {
    requestLoadCards({ page: 1, pageSize: cardsPageSize, qp });
  }, [establishmentId]);

  useEffect(() => {
    requestLoadCards({ page: cardsPage, pageSize: cardsPageSize, qp });
  }, [cardsPage]);

  useEffect(() => {
    if(!(headers.filter(header => header.field === 'actions').length > 0)) {
      headers.push({
        title: 'Ações',
        field: 'actions',
        render: rowData => {
          const { cardCode } = rowData;

          const disableRemoveButton =
            cardCodes.length > 0 && cardCodes.length < 2
              ? !cardCodes.includes(cardCode)
              : cardCodes.length > 1;

          return(
            <>
              <IconButton
                size="medium"
                onClick={() => onSelectSingleCard(rowData)}
                disabled={disableRemoveButton}
              >
                <FontAwesomeIcon
                  icon={faMinusCircle}
                  color="#F66D6E"
                  size="xs"
                  style={{ opacity: disableRemoveButton ? 0.5 : 1 }}
                />
              </IconButton>
            </>
          );
        }
      });
    }
  }, []);

  return(
    <Paper>
      <Grid container>
        <Grid xs={12} style={{ padding: 15 }}>
          <Grid
            container
            style={{
              flexDirection: 'column',
              marginTop: 15,
              backgroundColor: '#f6ebe9',
              border: '1px solid #efd7d7',
              borderRadius: 10
            }}
          >
            <Grid item spacing={2} style={{ padding: 10 }}>
              <Typography style={{ color: '#aa513d', margin: 5, fontWeight: 500 }}>
                Esta tela será <b>descontinuada em breve</b>, assim não será mais necessário pré-cadastrar os cartões. O usuário vai precisar apenas ler o QR Code na entrada e saída do veículo no app.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isMobile ? (
        <FilterMobile
          hasRemoveButton
          hasSearchTerm
          inlineCreateForm
          itemsToRemove={cardCodes}
          inputText={inputText}
          handleToRemoveItems={toggleToRemoveCards}
          onPropsTextChange={handleTextChangeInInsertForm}
          handleToRegisterItem={onSaveCard}
          handlePageRequest={requestLoadCards}
        />
      ) : (
        <Filter
          hasRemoveButton
          hasSearchTerm
          inlineCreateForm
          itemsToRemove={cardCodes}
          inputText={inputText}
          handleToRemoveItems={toggleToRemoveCards}
          onPropsTextChange={handleTextChangeInInsertForm}
          handleToRegisterItem={onSaveCard}
          handlePageRequest={requestLoadCards}
        />
      )}
      <MTable
        multiselect
        loading={isLoading}
        headers={headers}
        data={cards}
        actionColumnWidth={tableRowWidth}
        onSelectionChange={rows => onSelectAllRows(rows)}
      />
      <Grid
        container
        xs={12}
        style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}
      >
        <Pagination
          color="primary"
          variant="outlined"
          shape="rounded"
          count={cardsPageCount}
          page={cardsPage}
          onChange={(_, page) => setCardsPage(page)}
        />
      </Grid>
      <ModalConfirmation
        open={isRemoving}
        isLoading={isLoading}
        onClose={toggleToRemoveCards}
        handleConfirmation={onRemoveCards}
      >
        Deseja remover os cartões selecionados?{' '}
        <strong>{cardCodes.join(', ')}</strong>
      </ModalConfirmation>
    </Paper>
  );
}