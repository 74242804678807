import {
  compose,
  filter,
  head,
  last,
  split,
  prop,
  curry,
  when,
  propSatisfies,
  join,
  take,
  append,
  gt,
  __
} from 'ramda';

export const relativePercentage = (countdown, total) => {
  return Math.ceil(countdown * 100 / total);
}

function base64ToArrayBuffer(base64) {
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);

  for(let i = 0; i < len; i += 1) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes.buffer;
}

export const isImageUrl = url => {
  return(url?.match(/\.(jpeg|jpg|png|bmp)$/) != null);
}

export const getFileExtensionFromUrl = url => {
  return isImageUrl(url)
    ? url?.toString()?.split(".")?.at(-1)?.toUpperCase()
    : 'anexo';
}

export const createPDF = component => {
  return component.toBlob().then(blobProp => {
    const reader = new FileReader();

    reader.onload = () => {
      const blob = base64ToArrayBuffer(reader.result.split(',')[1]);
      const file = new Blob([blob], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    };

    reader.readAsDataURL(blobProp);
  });
}

export const getImageExtensionFromBase64 = base64 => {
  const base64firstCharacter = base64?.charAt(0);

  switch(base64firstCharacter) {
    case '/': return 'jpg'
    case 'i': return 'png'
    case 'R': return 'gif'
    case 'U': return 'webp'
    default: return 'jpg'
  }
}

export const splitStringInHalf = string => {
  const first = string.substring(0, Math.ceil(string.length / 2));
  const second = string.substring(Math.ceil(string.length / 2));

  return [first, second];
}

export const removeNullVehiclesFromString = vehicle => {
  if(!vehicle?.vehicleModel || !vehicle?.vehicleColor) {
    return `${vehicle?.plate}`;
  }

  return `${vehicle?.vehicleModel} ${vehicle?.vehicleColor} - ${vehicle?.plate}`;
}

export const renameProp = curry(
  (oldProp, newProp, { [oldProp]: old, ...others }) => ({
    [newProp]: old,
    ...others,
  })
);

export const separateDate = (date, obj) =>
  compose(head, split(' '), prop(date))(obj);

export const separateTime = (date, obj) => {
  if (!date) return false;
  return compose(last, split(' '), prop(date))(obj);
};

export const filterNonNullable = arr => filter(x => x !== null, arr);

export const limitNameSize = (name, size) => {
  const truncate = when(
    propSatisfies(gt(__, size), 'length'),
    compose(join(''), append('…'), take(size))
  );

  return truncate(name);
};

export const handleMultipleSelection = (selected, item, arr) => {
  let newArr = arr;

  if (selected) {
    newArr = newArr.concat(item);
  } else {
    newArr = newArr.filter(value => value !== item);
  }

  return newArr;
};

export const filterValueLabel = (options, value) => {
  if (options.length > 0) {
    const hasOptions = options.filter(o => o.value);
    const optionsFiltered =
      options && options.filter(opt => opt.value === value);
    return value && hasOptions.length > 0
      ? optionsFiltered.length > 0 && optionsFiltered[0].label
      : optionsFiltered.length > 0 && options[0].label;
  }

  return '';
};