import qs from 'qs';
import { changeBaseAPI, newAPI } from '../../../services';

export const fetchProvisionalReceiptInfo = async (userId, establishmentId) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/rps`);
  return response.data;
}

export const fetchCities = (userId, params) =>
  changeBaseAPI('post', `/${userId}/nfse/cities`, qs.stringify(params)).then(response => response.data);

export const fetchEconomicActivities = userId =>
  changeBaseAPI('get').then(response => response.data);

export const createRPSConfig = async (userId, establishmentId, params) => {
  const response = await newAPI.post(`${userId}/establishment/${establishmentId}/rps`, qs.stringify(params));
  return response.data;
}

export const updateRPSConfig = async (userId, establishmentId, params) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/rps`, qs.stringify(params));
  return response.data;
}

export const requestToUpdateCertificate = async (userId, establishmentId, params) => {
  const formData = new FormData();

  formData.append('password', params?.autenticatorPassword);
  formData.append('passwordConfirm', params?.autenticatorPasswordConfirm);
  formData.append('certificate', params?.certificateFile);

  const response = await newAPI.post(`${userId}/establishment/${establishmentId}/rps/auth/certificate`, formData);
  return response.data;
}

export const requestToUpdatePassword = async (userId, establishmentId, params) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/rps/auth/password`, qs.stringify(params));
  return response.data;
}

export const requestToUpdateToken = async (userId, establishmentId, params) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/rps/auth/token`, qs.stringify(params));
  return response.data;
}

export const requestToUpdateSettings = async (userId, establishmentId, params) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/rps/settings`, qs.stringify(params));
  return response.data;
}

export const requestToUpdateFilters = async (userId, establishmentId, params) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/rps/filters`, qs.stringify(params));
  return response.data;
}

export const requestToUpdateLogo = async (userId, establishmentId, params) => {
  const response = await newAPI.post(`${userId}/establishment/${establishmentId}/rps/logo`, params);
  return response.data;
}

export const loadRegimeEstadual = async (userId, codigoIbge) => {
  const response = await newAPI.get(`${userId}/nfse/cities/${codigoIbge}`);
  return response.data;
}

export const fetchEstablishmentsWithConfig = async (userId, establishmentId) => {
  const response = await newAPI.get(`${userId}/establishment/${establishmentId}/rps/list`);
  return response.data;
}

export const requestAttachEstablishment = async (userId, establishmentId, taxSettingUuid) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/rps/attach/${taxSettingUuid}`);
  return response.data;
}

export const requestDetachEstablishment = async (userId, establishmentId) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/rps/detach`);
  return response.data;
}

export const requetSendNfse = async (userId, establishmentId, transactionId) => {
  const response = await newAPI.put(`${userId}/establishment/${establishmentId}/nfse/${transactionId}/send`);
  return response.data;
}