import * as yup from 'yup';

const withoutAccessKey = text => ({
  is: value => value === null,
  then: yup.string().required(text),
});

export const provisionalReceiptValidations = yup.object().shape({
  accessKey: yup.string().nullable(),
  password: yup
    .string()
    .when('certificate', withoutAccessKey('A senha é obrigatória'))
    .nullable(),
  passwordConfirm: yup
    .string()
    .when('password', (password, schema) =>
      password ? schema.required('Confirme a senha') : schema
    )
    .oneOf(
      [yup.ref('password'), null],
      'A senha e a confirmação devem ser iguais'
    )
    .nullable(),
});
