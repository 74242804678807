import { toast } from 'react-toastify';
import { fetchProvisionalReceipt, sendNewNfse } from './services';
import { setProvisionalReceipt, handleLoading } from './actions';

export const loadProvisionalReceipt = ({
  userId,
  establishmentId,
  page,
  pageSize,
  qp = '',
  extraProps
}) => dispatch => {
  const { startDate, endDate } = extraProps;

  let url = `${userId}/nfse`;

  if(establishmentId) {
    url = `${userId}/establishment/${establishmentId}/nfse`;
  }

  if(qp) {
    url = `${url}?startDate=${startDate}&endDate=${endDate}&pg=${page}&limit=${pageSize}${qp}`;
  } else {
    url = `${url}?startDate=${startDate}&endDate=${endDate}&pg=${page}&limit=${pageSize}&typeDateTime=paymentDateTime&orderBy=paymentDateTime&sort=desc`;
  }

  if(qp.includes('nfseStatus')) {
    url = `${url}&typeDateTime=paymentDateTime&orderBy=paymentDateTime`;
  }

  dispatch(handleLoading(true));

  return fetchProvisionalReceipt(url)
    .then(({ data }) =>
      dispatch(setProvisionalReceipt(data.content, data.total))
    )
    .finally(() => dispatch(handleLoading(false)));
}

export const resendNfse = (
  userId,
  establishmentId,
  transactionId,
  page,
  pageSize,
  qp,
  extraProps,
  params,
  handleCloseError
) => dispatch => {
  const url = `${userId}/establishment/${establishmentId}/nfse/${transactionId}/send`;

  dispatch(handleLoading(true));

  return sendNewNfse(url, params)
    .then(res => {
      if(+res.data.nfseStatus != 4) {
        toast.success('Nota enviada com sucesso! Aguarde confirmação.');
        dispatch(loadProvisionalReceipt({ userId, establishmentId, page, pageSize, qp, extraProps }));
        handleCloseError();
      }
    })
    .finally(() => dispatch(handleLoading(false)));
}