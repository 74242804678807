import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { Paper, Grid, Typography, Button } from '@material-ui/core';
import Loader from '../../../../../components/Loader';
import Card from '../../../../../components/Card';
import FileUploader from '../../../../../components/FileUploader';
import Input from '../../../../../components/Input';

import { loadProvisionalReceiptInfo, loadEconomicActivities } from '../../../store/thunk';
import { setCertificateFile } from '../../../store/actions';
import { convertToBase64 } from '../../../../../helpers/converters';

import { useStyles } from './styles';

const logoJump = require('../../../../../assets/img/logo_login.svg');
const logoTecnoSign = require('../../../../../assets/img/logo_tecnosign.png');

export default function ProvisionalReceiptInfo({ formikProps }) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);

  const {
    submit: {
      taxSettingsOwner,
      establishmentTaxSettingOwner
    },
    submit,
    certificateFile,
    certificateFileFromAPI,
    isLoading,
    dataPage,
    configType
  } = useSelector(state => state.provisionalReceiptConfig);

  const hasCertificateFile = !!certificateFile;
  const isSameCnpj = +configType == 0;

  useEffect(() => {
    formikProps.setValues(submit);
  }, [submit]);

  useEffect(() => {
    if(establishmentId) {
      dispatch(loadProvisionalReceiptInfo(userId, establishmentId));
    }
  }, [establishmentId]);

  useEffect(() => {
    dispatch(loadEconomicActivities(userId));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleUploadFile = (e, formikNewProps) => {
    const certificate = e.target.files[0];
    const { setFieldValue } = formikNewProps;

    setFieldValue('certificateFile', certificate);

    convertToBase64(certificate, base64 => {
      setFieldValue('certificate', base64);
      formikProps.setFieldValue('certificate', base64);
      formikProps.setFieldValue('certificateFile', certificate);
    });

    return dispatch(setCertificateFile(certificate));
  }

  const taxSettingsOwnerMessage = (taxSettingsOwner, establishmentTaxSettingOwner) => {
    if(+taxSettingsOwner == 1) {
      return <></>;
    }

    if(+taxSettingsOwner == 0) {
      return(
        <Grid item xs={12}>
          <Grid
            container
            style={{
              flexDirection: 'column',
              marginTop: 15,
              marginBottom: 15,
              backgroundColor: '#f6ebe9',
              border: '1px solid #efd7d7',
              borderRadius: 10
            }}
          >
            <Grid
              item
              spacing={2}
              style={{ padding: 10 }}
            >
              <Typography style={{ color: '#aa513d', margin: 5 }}>
                Se quiser editar a configuração precisa acessar essa tela com o estabelecimento
                <b>{` ${establishmentTaxSettingOwner?.name} ${establishmentTaxSettingOwner?.address?.street}, ${establishmentTaxSettingOwner?.address?.number} - ${establishmentTaxSettingOwner?.address?.district}, ${establishmentTaxSettingOwner?.address?.city}`}.</b>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    }
  }

  return(
    <>
      {(dataPage && dataPage.tipoAutenticacao === 0 && (
        <Card title="Município não possui autenticação">
          {taxSettingsOwnerMessage(taxSettingsOwner, establishmentTaxSettingOwner)}
          <Grid item xs={12}>
            <Loader isLoading={isLoading}>
              <Grid container spacing={2}>
                <Grid item>
                  <Typography variant="subtitle1" color="textSecondary">
                    Município não possui autenticação, usuário pode avançar para o próximo passo.
                  </Typography>
                </Grid>
              </Grid>
            </Loader>
          </Grid>
        </Card>
      ))}
      {(dataPage && dataPage.tipoAutenticacao === 1 && (
        <>
          <Card title="Certificado Digital A1">
            {taxSettingsOwnerMessage(taxSettingsOwner, establishmentTaxSettingOwner)}
            <Grid item xs={12}>
              <Loader isLoading={isLoading}>
                {dataPage && dataPage.helpTipoAutenticacao && (
                  <Grid container spacing={2}>
                    <Grid item>
                      <Typography variant="subtitle1" color="textSecondary">
                        {dataPage && dataPage.helpTipoAutenticacao.certificadoDigital}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={2}>
                  <Grid item>
                    <FileUploader
                      name="certificate"
                      file={certificateFile}
                      disabled={isSameCnpj}
                      errors={formikProps.errors.certificate}
                      onChange={e => handleUploadFile(e, formikProps)}
                    >
                      Escolher certificado
                    </FileUploader>
                    {submit.certificateName && (
                      <Typography variant="subtitle2" className={classes.subtitle}>
                        {submit.certificateName &&
                          `Nome do Arquivo: ${submit.certificateName}`}{' '}
                        <br />
                        {submit.certificateExpirationDate &&
                          `Expiração: ${moment(
                            submit.certificateExpirationDate
                          ).format('DD/MM/YYYY')}`}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <Input
                      disabled={isSameCnpj || (!certificateFileFromAPI && !hasCertificateFile)}
                      name="autenticatorPassword"
                      type="password"
                      label="Senha do certificado"
                      value={formikProps.values.autenticatorPassword || ""}
                      error={formikProps.errors.autenticatorPassword}
                      helperText={formikProps.errors.autenticatorPassword}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Input
                      disabled={isSameCnpj || (!certificateFileFromAPI && !hasCertificateFile)}
                      name="autenticatorPasswordConfirm"
                      type="password"
                      label="Confirmação de senha"
                      value={formikProps.values.autenticatorPasswordConfirm || ""}
                      error={formikProps.errors.autenticatorPasswordConfirm}
                      helperText={formikProps.errors.autenticatorPasswordConfirm}
                      onChange={formikProps.handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  style={{
                    flexDirection: 'column',
                    marginTop: 15,
                    marginBottom: 15,
                    backgroundColor: '#f6ebe9',
                    border: '1px solid #efd7d7',
                    borderRadius: 10
                  }}
                >
                  <Grid item xs={12}>
                    <Typography style={{ color: '#aa513d', margin: 5, fontWeight: 500 }}>
                      Atenção: <br/>
                      Ao trocar para o novo certificado, as notas não enviadas por causa do certificado
                      vencido, devem ser reemitidas uma por uma através das listagens de nota.
                    </Typography>
                  </Grid>
                </Grid>
              </Loader>
            </Grid>
          </Card>
          <Paper style={{ marginTop: 25 }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', gap: 20, padding: 20 }}
              >
                <Typography variant='h4' color='primary'>
                  Precisa comprar ou renovar o certificado?!
                </Typography>
                <Grid style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 20 }}>
                  <img src={logoJump} width="50" height="50" alt="Jump Park" />
                  <Typography style={{ fontSize: 30, fontWeight: 500 }}>&</Typography>
                  <img src={logoTecnoSign} height="50" alt="TecnoSign" />
                </Grid>
                <Typography color='primary'>
                  Acesse um atalho para criar ou renovar seu certificado A1 Digital
                </Typography>
                <Typography color='primary'>
                  Atenção: Estamos apenas indicando, quem vende e renova o certificado é a TecnoSign. <br/>
                  Selecione a opção Certificado A1 Digital para emitir as notas fiscais.
                </Typography>
                <Button
                  style={{ backgroundColor: '#ED702D', textTransform: 'capitalize' }}
                  onClick={() => window.open('https://certcontrol.com.br:8443/venda-rapida/vd0tp720tkc8df459027', '_blank')}
                >
                  <Typography variant='h5' style={{ color: '#FFFFFF', padding: 5 }}>
                    Criar/renovar certificado A1 Digital
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </>
      ))}
      {(dataPage && dataPage.tipoAutenticacao === 3 && (
        <Card title="Token ou chave de Acesso">
          {taxSettingsOwnerMessage(taxSettingsOwner, establishmentTaxSettingOwner)}
          <Grid item xs={12}>
            <Loader isLoading={isLoading}>
              <Grid
                container
                style={{
                  flexDirection: 'column',
                  padding: 15,
                  backgroundColor: 'rgb(241, 244, 249)',
                  border: '1px solid #38a8d8',
                  borderRadius: 10
                }}
              >
                <Grid item spacing={2}>
                  <Typography variant="h6" color="primary">
                    Atenção:
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{ fontWeight: 400, fontSize: '15px' }}
                  >
                    Para obtê-la acesse o portal da NFS-e da prefeitura e após se
                    autenticar vá no menu Notas Fiscais na opção Web Service -
                    Gerar Chave Autenticação. Clique em "Gerar Chave".
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Input
                    disabled={isSameCnpj}
                    name="autenticatorToken"
                    type="text"
                    label="Chave de acesso"
                    value={formikProps.values.autenticatorToken || ""}
                    error={formikProps.errors.autenticatorToken}
                    helperText={formikProps.errors.autenticatorToken}
                    onChange={formikProps.handleChange}
                  />
                </Grid>
              </Grid>
            </Loader>
          </Grid>
        </Card>
      ))}
      {(dataPage && dataPage.tipoAutenticacao === 2 && (
        <Card title="Usuário e senha ">
          {taxSettingsOwnerMessage(taxSettingsOwner, establishmentTaxSettingOwner)}
          {dataPage && !!dataPage.helpTipoAutenticacao && (
            <Grid
              container
              style={{
                flexDirection: 'column',
                padding: 15,
                backgroundColor: 'rgb(241, 244, 249)',
                border: '1px solid #38a8d8',
                borderRadius: 10
              }}
            >
              <Grid item spacing={2}>
                <Typography variant="h6" color="primary">
                  Atenção:
                </Typography>
                <Typography
                  variant="h6"
                  style={{ fontWeight: 400, fontSize: '15px' }}
                >
                  {dataPage && dataPage.helpTipoAutenticacao.usuario}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Loader isLoading={isLoading}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Input
                    disabled={isSameCnpj}
                    name="autenticatorUser"
                    type="user"
                    label="Usuário"
                    value={formikProps.values.autenticatorUser || ""}
                    error={formikProps.errors.autenticatorUser}
                    helperText={formikProps.errors.autenticatorUser}
                    onChange={e => formikProps.handleChange(e)}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Input
                    disabled={isSameCnpj}
                    name="autenticatorPassword"
                    type="password"
                    label="Senha"
                    value={formikProps.values.autenticatorPassword || ""}
                    error={formikProps.errors.autenticatorPassword}
                    helperText={formikProps.errors.autenticatorPassword}
                    onChange={formikProps.handleChange}
                  />
                </Grid>
              </Grid>
            </Loader>
          </Grid>
        </Card>
      ))}
    </>
  );
}

ProvisionalReceiptInfo.propTypes = {
  formikProps: PropTypes.shape().isRequired
};