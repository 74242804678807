import { compose, over, set, lensProp, map, assoc } from 'ramda';
import { SET_RPS, HANDLE_LOADING, HANDLE_ERROR } from './constants';
import { formatDate, formatEmptyValue } from '../../../helpers/formatters';
import { convertToReal } from '../../../helpers/converters';

const defaultState = {
  list: [],
  total: null,
  isLoading: false,
  error: [],
  transactionId: null,
  establishmentId: null,
  paymentDateTime: null
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_RPS: {
      const formatRps = rps => {
        const {
          rpsSeries,
          rpsNumber,
          nfseStatus,
          paymentDateTime,
          amount
        } = rps;

        const formatter = compose(
          assoc('rpsSerieAndNumber', `${rpsSeries}-${rpsNumber}`),
          assoc('nfseStatusNumber', nfseStatus),
          over(lensProp('nfseNumber'), formatEmptyValue),
          over(lensProp('nfseDateTime'), formatDate),
          assoc('amountView', convertToReal(amount)),
          assoc('paymentDateTimeView', formatDate(paymentDateTime))
        );

        return formatter(rps);
      }

      const list = map(formatRps, action.data);

      const rps = compose(
        set(lensProp('list'), list),
        set(lensProp('total'), action.total),
        set(lensProp('isLoading'), false)
      );

      return rps(state);
    }
    case HANDLE_LOADING: {
      return set(lensProp('isLoading'), action.status, state);
    }
    case HANDLE_ERROR: {
      return {
        ...state,
        error: action.error,
        transactionId: action.transactionId,
        establishmentId: action.establishmentId,
        paymentDateTime: action.paymentDateTime
      }
    }
    default:
      return state;
  }
}