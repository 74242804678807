import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { filter } from 'lodash';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Filter from '../../../components/Filter/components';
import FilterMobile from '../../../components/FilterMobile/components';
import MTable from '../../../components/MTable';
import Resume from './Resume';
import Receipt from './Receipt';
import Payment from './Payment';
import DateConfirmation from './DateConfirmation';
import CashierDetails from './CashierDetails';
import Invoice from '../../../components/Invoice';
import InvoicePos from '../../../components/InvoicePos';
import InvoiceServiceContract from '../../../components/InvoiceServiceContract';
import ServiceOrder from '../../../components/ServiceOrder/components';
import Payroll from '../../../components/Payroll';
import PayrollSheet from '../../../components/PayrollSheet';
import SellOrder from '../../../components/SellOrder/components';

import { clearRegistration, handleRegistration, setTransactionDetails, selectSourceId, selectCategorySourceId, selectTransactionId, setTransactionType, setCashierId, setStoreCashierId, handleLoading } from '../store/actions';
import { loadSingleTransaction, loadTransaction, loadResume } from '../store/thunk';
import { formatFilters, formatTransaction } from '../../../helpers/formatters';
import { convertList, convertDateToDB, convertFormattedMoneyToNumber, convertToBrl } from '../../../helpers/converters';
import { fetchInvoiceDetails } from '../../ClientInvoices/store/services';
import { setServiceOrderId } from '../../../components/ServiceOrder/store/actions';
import { toggleServiceOrder } from '../../OperationalHistory/store/actions';
import { handleFilterButtonClick } from 'components/Filter/store/actions';
import { loadFilters } from '../../../components/Filter/store/thunk';
import { setClientId } from '../../ClientRegistration/components/steps/BasicInfo/store/actions';
import { setInvoiceId, handleUpdateInvoice } from '../../ClientInvoices/store/actions';

import { clearPayroll } from 'pages/ComissionedPayrolls/store/actions';
import { loadSinglePayroll, loadPartialPayment } from 'pages/ComissionedPayrolls/store/thunk';

import { clearPayrollSheet } from 'pages/ComissionedPayrollSheet/store/actions';
import { loadSinglePayrollSheet } from 'pages/ComissionedPayrollSheet/store/thunk';

import { clearSellOrder } from 'pages/SellOrders/store/actions';
import { loadSellOrder } from 'pages/SellOrders/store/thunk';

const headers = [
  {
    title: 'Data',
    field: 'orderDateTime',
    headerStyle: {
      width: 80,
      minWidth: 80
    }
  },
  {
    title: 'Categoria',
    field: 'category',
    headerStyle: {
      width: 100,
      minWidth: 100
    }
  },
  {
    title: 'Descrição',
    field: 'description',
    headerStyle: {
      width: 500,
      textAlign: 'left'
    },
    cellStyle: {
      width: 500,
      textAlign: 'left'
    }
  },
  {
    title: 'Meio de pagamento',
    field: 'paymentMethodName',
    headerStyle: {
      width: 100,
      minWidth: 100
    }
  },
  {
    title: 'Valor',
    field: 'amount',
    headerStyle: {
      width: 100,
      minWidth: 100
    }
  }
];

const adminHeaders = headers.concat(
  {
    title: 'Última sincronização',
    field: 'lastSync',
    headerStyle: {
      width: 80,
      minWidth: 80
    }
  },
  {
    title: 'ID do caixa',
    field: 'cashierId',
    headerStyle: {
      width: 80,
      minWidth: 80
    }
  }
);

const exportHeaders = [
  { label: 'Data', id: 'transactionDateTime' },
  { label: 'Categoria', id: 'category' },
  { label: 'Descrição', id: 'description' },
  { label: 'Valor', id: 'amount' },
  { label: 'Meio de Pagamento', id: 'paymentMethodId' }
];

const width = window.innerWidth;
const tableColumnActionWidth = width < 768 ? 80 : 60;

export default function Transactions() {
  const dispatch = useDispatch();

  const width = window.innerWidth;
  const isMobile = width < 1024;

  const [isTransactionsListLoading, setIsTransactionsListLoading] = useState(false);
  const [isResumeLoading, setIsResumeLoading] = useState(false);

  const [isPayrollModalOpen, setIsPayrollModalOpen] = useState(false);
  const [isPayrollSheetModalOpen, setIsPayrollSheetModalOpen] = useState(false);
  const [isSellOrderModalOpen, setIsSellOrderModalOpen] = useState(false);
  const [isOpenInvoiceServiceContract, setIsOpenInvoiceServiceContract] = useState(false);
  const [isOpenInvoicePos, setIsOpenInvoicePos] = useState(false);

  const [calculatedInvoiceDateTime, setCalculatedInvoiceDateTime] = useState('');

  const { userId, submit: { profileId } } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { startDate, endDate } = useSelector(state => state.dateFilter);
  const { qp, selects, isFilterButtonClicked } = useSelector(state => state.filters);
  const { modal } = useSelector(state => state.operationalHistory);
  const { isUpdatingInvoice } = useSelector(state => state.clientInvoices);

  const {
    content,
    modalType,
    resume,
    total,
    registration,
    isLoading
  } = useSelector(state => state.transactions);

  const isUserAdmin = +profileId == 1;

  const paymentMethods = formatFilters(['paymentMethod'], selects)[0]?.options;
  const filters = formatFilters(['paymentMethod', 'costCenter', 'category', 'transactionType'], selects);
  const list = convertList(['category'], filters, content);

  const allCategories = filters
    ?.find(filter => filter?.id == 'category')?.options

  const uniqueCategoriesId = filter(allCategories, v => filter(allCategories, v1 => v1?.label == v?.label).length > 1)
    ?.map(category => category?.value);

  const categories = filters
    ?.find(filter => filter?.id == 'category')?.options
    ?.map(category => {
      return uniqueCategoriesId.includes(category?.value)
        ? {
          ...category,
          label: +category?.transactionTypeId == 1 ? `${category?.label} (receita)` : `${category?.label} (despesa)`
        } : {
          ...category
        }
    });

  const formattedFilters = filters?.map(filter => {
    return filter?.id == 'category'
      ? {
        ...filter,
        options: categories
      } : {
        ...filter
      }
  });

  const [transactionsPage, setTransactionsPage] = useState(1);
  const transactionsPageSize = 10;
  const transactionsPageCount = Math.ceil(total / transactionsPageSize);

  const formatAmountType = row => {
    const { amount, situationId } = row;

    const amountColor = () => {
      if(situationId === 1) {
        return 'primary';
      }

      if(amount >= 0) {
        return 'textPrimary';
      }

      return 'error';
    }

    return(
      <Typography variant="body2" color={amountColor()}>
        {convertToBrl(convertFormattedMoneyToNumber(amount))}
      </Typography>
    );
  }

  const convertPaymentMethodIdToPaymentMethodName = (paymentMethods, id) => {
    return paymentMethods?.find(paymentMethod => paymentMethod.value == id)?.label || '-';
  }

  const requestTransaction = ({ page, qp, exportation }) => {
    const extraProps = {
      userId,
      establishmentId,
      startDate,
      endDate,
      exportation
    }

    setIsTransactionsListLoading(true);

    dispatch(loadTransaction({ qp, page, pageSize: transactionsPageSize, extraProps })).then(() => {
      setIsTransactionsListLoading(false);
    });
  }

  const requestExport = ({ page, qp, exportation }) => {
    const extraProps = {
      userId,
      establishmentId,
      startDate,
      endDate,
      exportation
    }

    if(exportation) {
      return dispatch(loadTransaction({ qp, page, pageSize: transactionsPageSize, extraProps }));
    }

    setIsTransactionsListLoading(true);
    setIsResumeLoading(true);

    dispatch(loadTransaction({ qp, page, pageSize: transactionsPageSize, extraProps })).then(() => {
      setIsTransactionsListLoading(false);

      dispatch(loadResume(userId, establishmentId, { startDate, endDate, qp })).then(() => {
        setIsResumeLoading(false);
      });
    });
  }

  const requestResume = () => {
    setIsResumeLoading(true);

    dispatch(loadResume(userId, establishmentId, { startDate, endDate, qp })).then(() => {
      setIsResumeLoading(false);
    });
  }

  useEffect(() => {
    if(establishmentId) {
      requestResume();
    }
  }, []);

  useEffect(() => {
    if(establishmentId) {
      requestResume();
    }
  }, [establishmentId]);

  useEffect(() => {
    dispatch(handleFilterButtonClick(false));
  }, [isFilterButtonClicked]);

  useEffect(() => {
    const canUserSearchByDate =
      establishmentId &&
      startDate !== 'Invalid date' &&
      endDate !== 'Invalid date' &&
      isFilterButtonClicked;

    if(canUserSearchByDate) {
      dispatch(loadFilters(userId, establishmentId));
      requestTransaction({ page: 1, qp });
    }
  }, [establishmentId, startDate, endDate]);

  useEffect(() => {
    if(establishmentId) {
      const queryParams = qp.includes('financialSituation') ? '' : qp;
      requestTransaction({ qp: queryParams, page: transactionsPage });
    }
  }, [establishmentId, transactionsPage]);

  useEffect(() => {
    dispatch(loadFilters(userId, establishmentId));
  }, [establishmentId]);

  const calculateInvoiceDateTime = (sourceId, clientId) => {
    const newInvoiceDateTime = sourceId?.toString()?.replace(clientId?.toString(), "");
    return newInvoiceDateTime;
  }

  const handleTransactionRegistration = async (
    status,
    modalType,
    sourceId = null,
    categorySourceId = null,
    transactionId = null,
    transactionTypeId = null,
    cashierId = '',
    storeCashierId = ''
  ) => {
    dispatch(clearRegistration());
    dispatch(selectSourceId(sourceId));
    dispatch(selectCategorySourceId(categorySourceId));
    dispatch(selectTransactionId(transactionId));
    dispatch(setTransactionType(transactionTypeId));
    dispatch(handleRegistration(status, modalType));
    dispatch(setCashierId(cashierId || ''));
    dispatch(setStoreCashierId(storeCashierId || ''));
  }

  const handleOpenPayroll = sourceId => {
    dispatch(clearPayroll());
    dispatch(loadPartialPayment(userId, establishmentId, sourceId));
    dispatch(loadSinglePayroll(userId, establishmentId, sourceId));
    setIsPayrollModalOpen(true);
  }

  const handleOpenPayrollSheet = sourceId => {
    dispatch(clearPayrollSheet());
    dispatch(loadSinglePayrollSheet(userId, establishmentId, sourceId));
    setIsPayrollSheetModalOpen(true);
  }

  const handleOpenSellOrder = sourceId => {
    dispatch(clearSellOrder());
    dispatch(loadSellOrder(userId, establishmentId, sourceId));
    setIsSellOrderModalOpen(true);
  }

  const selectServiceOrder = sourceId => {
    dispatch(setServiceOrderId(sourceId, userId, establishmentId));
    dispatch(toggleServiceOrder(true));
  }

  const selectInvoice = async (clientId, sourceId) => {
    dispatch(setClientId(clientId));
    dispatch(setInvoiceId(sourceId));

    const params = {
      userId,
      establishmentId,
      clientId,
      invoiceDateTime: calculateInvoiceDateTime(sourceId, clientId)
    }

    const invoiceDetails = await fetchInvoiceDetails(params);

    const clientTypeId = invoiceDetails.data.clientTypeId;

    if(clientTypeId === 4 || clientTypeId === 5) {
      setIsOpenInvoicePos(true);
      return;
    }

    dispatch(handleUpdateInvoice(true));
  }

  const selectTransaction = async (item, modalType) => {
    await dispatch(handleLoading(true));

    const singleTransactionData = await dispatch(loadSingleTransaction(userId, establishmentId, item.transactionId));

    if(singleTransactionData) {
      await dispatch(handleLoading(false));

      const data = {
        ...item,
        ...singleTransactionData,
        amount: item?.rawAmount
      }

      await handleTransactionRegistration(true, modalType).then(async () => {
        await dispatch(setTransactionDetails(data));
      });
    }
  }

  const handleSelectTransaction = item => {
    const {
      situationId,
      categorySourceId,
      sourceId,
      clientId,
      transactionTypeId,
      transactionId,
      cashierId,
      storeCashierId
    } = item;

    const transactionTypes = {
      1: 'receipt',
      2: 'payment'
    }

    dispatch(setClientId(clientId));

    if(categorySourceId === 8) {
      setCalculatedInvoiceDateTime(calculateInvoiceDateTime(sourceId, clientId));
      setIsOpenInvoiceServiceContract(true);
      return;
    }

    const onSelectFunctionsByType = {
      1: () => selectServiceOrder(sourceId),
      2: () => selectInvoice(clientId, sourceId, categorySourceId),
      4: () =>
        handleTransactionRegistration(
          true,
          'cashierDetails',
          sourceId,
          categorySourceId,
          transactionId,
          transactionTypeId,
          cashierId,
          storeCashierId
        ),
      5: () =>
        handleTransactionRegistration(
          true,
          'cashierDetails',
          sourceId,
          categorySourceId,
          transactionId,
          transactionTypeId,
          cashierId,
          storeCashierId
        ),
      6: () =>
        handleTransactionRegistration(
          true,
          'cashierDetails',
          sourceId,
          categorySourceId,
          transactionId,
          transactionTypeId,
          cashierId,
          storeCashierId
        ),
      7: () => false,
      9: () => handleOpenPayroll(sourceId),
      10: () => handleOpenPayrollSheet(sourceId),
      11: () => handleOpenSellOrder(sourceId),
      transactionByType: () => selectTransaction(item, transactionTypes[transactionTypeId]),
      changeDateConfirmation: () => selectTransaction(item, 'dateConfirmation'),
      default: () => false
    }

    return(
      onSelectFunctionsByType[categorySourceId] ||
      (situationId !== 3 && onSelectFunctionsByType['transactionByType']) ||
      onSelectFunctionsByType['transactionByType']
    )();
  }

  const verifyDueDateAndCanceledTransaction = row => {
    const result = {
      backgroundColor: 'none',
      textDecoration: 'none'
    }

    if(row) {
      const {
        primitivePaymentMethodId,
        situationId,
        previsionDateTime,
        transactionDateTime
      } = row;

      const convertedTransactionDateTime = convertDateToDB(transactionDateTime);

      result.backgroundColor = '#FFFFFF';

      if(primitivePaymentMethodId !== 1) {
        if(situationId !== 2 && situationId !== 3) {
          if(
            previsionDateTime &&
            previsionDateTime !== '0001-01-01 00:00:00' &&
            previsionDateTime !== '' &&
            moment(previsionDateTime).isBefore(moment().startOf('day'))
          ) {
            result.backgroundColor = '#F2E7E3';
          } else if (
            transactionDateTime &&
            transactionDateTime !== '0001-01-01 00:00:00' &&
            transactionDateTime !== '' &&
            moment(convertedTransactionDateTime).isBefore(moment().startOf('day'))
          ) {
            result.backgroundColor = '#F2E7E3';
          }
        }
      }

      if(situationId === 4) {
        result.textDecoration = 'line-through';
      }
    }

    return result;
  }

  return(
    <Paper style={{ marginBottom: 20 }}>
      {isPayrollModalOpen && (
        <Payroll
          isPayrollModalOpen={isPayrollModalOpen}
          setIsPayrollModalOpen={setIsPayrollModalOpen}
          pageOrigin='transactions'
        />
      )}
      {isPayrollSheetModalOpen && (
        <PayrollSheet
          isPayrollSheetModalOpen={isPayrollSheetModalOpen}
          setIsPayrollSheetModalOpen={setIsPayrollSheetModalOpen}
          pageOrigin='transactions'
        />
      )}
      {isSellOrderModalOpen && (
        <SellOrder
          isSellOrderModalOpen={isSellOrderModalOpen}
          setIsSellOrderModalOpen={setIsSellOrderModalOpen}
          pageOrigin='transactions'
        />
      )}
      {registration && modalType === 'receipt' && (
        <Receipt isOpen={registration} />
      )}
      {registration && modalType === 'payment' && (
        <Payment isOpen={registration} />
      )}
      {registration && modalType === 'dateConfirmation' && (
        <DateConfirmation isOpen={registration} />
      )}
      {registration && modalType === 'cashierDetails' && (
        <CashierDetails isOpen={registration} />
      )}
      {modal && (
        <ServiceOrder
          modal={modal}
          pageOrigin="transactions"
          transactionsProps={{ qp, transactionsPage, transactionsPageSize }}
        />
      )}
      {isUpdatingInvoice && (
        <Invoice
          isOpen={isUpdatingInvoice}
          pageOrigin="transactions"
          establishmentId={establishmentId}
          transactionsQp={qp}
          transactionsPage={transactionsPage}
          transactionsPageSize={transactionsPageSize}
        />
      )}
      {isOpenInvoicePos && (
        <InvoicePos
          isOpen={isOpenInvoicePos}
          setIsOpenInvoicePos={setIsOpenInvoicePos}
          pageOrigin="transactions"
          establishmentId={establishmentId}
          transactionsQp={qp}
          transactionsPage={transactionsPage}
          transactionsPageSize={transactionsPageSize}
        />
      )}
      {isOpenInvoiceServiceContract && (
        <InvoiceServiceContract
          isOpenInvoiceServiceContract={isOpenInvoiceServiceContract}
          setIsOpenInvoiceServiceContract={setIsOpenInvoiceServiceContract}
          calculatedInvoiceDateTime={calculatedInvoiceDateTime}
          isInvoicePaid={true}
        />
      )}
      {isMobile ? (
        <FilterMobile
          filter
          hasExportButton
          insertTransactionSelect
          exportType="extract"
          fileName="transacoes"
          handlePageRequest={requestExport}
          handleRegistration={handleTransactionRegistration}
          filters={formattedFilters}
          exportHeaders={exportHeaders}
          formatComponentData={formatTransaction}
        />
      ) : (
        <Filter
          filter
          hasExportButton
          insertTransactionSelect
          exportType="extract"
          fileName="transacoes"
          handlePageRequest={requestExport}
          handleRegistration={handleTransactionRegistration}
          filters={formattedFilters}
          exportHeaders={exportHeaders}
          formatComponentData={formatTransaction}
        />
      )}
      <MTable
        loading={isTransactionsListLoading || isLoading}
        headers={isUserAdmin ? adminHeaders : headers
        }
        data={list.map(item => {
          return {
            ...item,
            paymentMethodName: convertPaymentMethodIdToPaymentMethodName(paymentMethods, item?.paymentMethodId),
            amount: formatAmountType(item),
            rawAmount: item?.amount,
            lastSync: moment(item?.lastSync).format('DD/MM/YYYY HH:mm:ss'),
            orderDateTime: moment(item?.orderDateTime).format('DD/MM/YYYY')
          }
        })}
        selectRow={handleSelectTransaction}
        rowStyle={verifyDueDateAndCanceledTransaction}
        actionColumnWidth={tableColumnActionWidth}
      />
      <Grid
        container
        xs={12}
        style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}
      >
        <Pagination
          color="primary"
          variant="outlined"
          shape="rounded"
          count={transactionsPageCount}
          totalItems={transactionsPage}
          onChange={(_, page) => setTransactionsPage(page)}
        />
      </Grid>
      <Resume
        resume={resume}
        loading={isResumeLoading}
      />
    </Paper>
  );
}