import { compose, set, lensProp } from 'ramda';
import {
  TEXT_CHANGE_TO_INSERT_FORM,
  SET_CARDS,
  CLEAR_CARDS_SELECTED,
  SELECT_CARD,
  SELECT_SINGLE_CARD,
  LOADING_INFORMATIONS,
  TOGGLE_TO_REMOVE_CARD,
  CLEAR_CARDS,
} from './constants';

import { handleMultipleSelection } from '../../../helpers/common';

const defaultState = {
  cardCode: null,
  description: null,
  amount: null,
  inputText: '',
  status: 1,
  total: null,
  cards: [],
  cardCodes: [],
  isRemoving: false,
  isUpdating: false,
  isLoading: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case TEXT_CHANGE_TO_INSERT_FORM: {
      return set(lensProp(action.field), action.value, state);
    }
    case SET_CARDS: {
      const cards = compose(
        set(lensProp('total'), action.total),
        set(lensProp('cards'), action.data)
      );

      return cards(defaultState);
    }
    case CLEAR_CARDS_SELECTED: {
      return set(lensProp('cardCodes'), [], state);
    }
    case SELECT_CARD: {
      const { cardCodes } = state;
      const { selected, cardCode } = action;

      return set(
        lensProp('cardCodes'),
        handleMultipleSelection(selected, cardCode, cardCodes),
        state
      );
    }
    case SELECT_SINGLE_CARD: {
      return set(lensProp('cardCodes'), [action.cardCode], state);
    }
    case TOGGLE_TO_REMOVE_CARD: {
      return set(lensProp('isRemoving'), action.status, state);
    }
    case LOADING_INFORMATIONS: {
      return set(lensProp('isLoading'), action.status, state);
    }
    case CLEAR_CARDS:
      return defaultState;
    default:
      return state;
  }
};
