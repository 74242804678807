import * as type from './constants';

export const textChangeToInsertForm = (field, value) => ({
  type: type.TEXT_CHANGE_TO_INSERT_FORM,
  field,
  value,
});

export const setCards = (data, total) => ({
  type: type.SET_CARDS,
  data,
  total,
});

export const clearCardsSelected = () => ({
  type: type.CLEAR_CARDS_SELECTED,
});

export const selectCard = (cardCode, selected) => ({
  type: type.SELECT_CARD,
  cardCode,
  selected,
});

export const selectSingleCard = cardCode => ({
  type: type.SELECT_SINGLE_CARD,
  cardCode,
});

export const toggleToRemoveCard = status => ({
  type: type.TOGGLE_TO_REMOVE_CARD,
  status,
});

export const handleLoading = status => ({
  type: type.LOADING_INFORMATIONS,
  status,
});

export const clearCards = () => ({
  type: type.CLEAR_CARDS,
});
