import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, Typography, TextField, Tooltip } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import Loader from 'components/Loader';
import Card from 'components/Card';
import Input from 'components/Input';
import Button from 'components/Button';

import { clearComissionsErrors } from '../store/actions';
import { loadComissionConfigList, upsertComissionConfig } from '../store/thunk';
import { loadFilters } from 'components/Filter/store/thunk';

import { useStyles } from './styles';
import useWindowDimensions from 'helpers/hooks/useWindowDimensions';

export default function Comissions() {
  const dispatch = useDispatch();

  const autoCompleteStyles = useStyles();

  const { width } = useWindowDimensions();
  const isMobile = width < 728;

  const allEstablishments = useSelector(state => state.login.establishments);
  const { userId } = useSelector(state => state.profile);
  const { establishmentId, establishments } = useSelector(state => state.businessInfo);
  const { submit, isLoading } = useSelector(state => state.comissions);
  const { selects } = useSelector(state => state.filters);

  const establishmentUuid = establishments?.find(establishment => +establishment?.establishmentId == +establishmentId)?.uuid || '';
  const alternativeEstablishmentUuid = allEstablishments?.find(establishment => +establishment?.establishmentId == +establishmentId)?.uuid || '';

  const services = selects
    ?.find(service => service?.id == 'services')?.options
    ?.map(service => {
      return {
        label: service?.description,
        value: service?.serviceId
      }
    })
    ?.filter(service => !!service?.value);

  const profiles = useSelector(state => state.comissionedList.filters.commissionProfiles)?.options
    ?.map(profile => {
      return {
        label: profile?.name,
        value: profile?.id
      }
    })
    ?.filter(profile => profile?.value != '');

  const [selectedServiceId, setSelectedServiceId] = useState();

  const [servicesList, setServicesList] = useState({
    establishmentUuid,
    uuid: '',
    serviceId: selectedServiceId,
    profiles: []
  });

  const requestLoadComissionConfigList = (establishmentId, serviceId) => {
    dispatch(loadComissionConfigList({
      userId,
      establishmentId,
      serviceId
    }));
  }

  useEffect(() => {
    dispatch(clearComissionsErrors());
  }, []);

  useEffect(() => {
    if(establishmentId) {
      dispatch(loadFilters(userId, establishmentId));
    }
  }, [establishmentId]);

  useEffect(() => {
    if(services?.length > 0) {
      setSelectedServiceId(services[0]?.value);
      requestLoadComissionConfigList(establishmentId, services[0]?.value);
    }
  }, []);

  useEffect(() => {
    const profilesToServicesList = profiles?.map(profile => {
      return {
        profileId: profile?.value,
        type: '1',
        amount: ''
      };
    });

    const servicesWithProfiles = {
      ...submit?.uuid
        ? submit
        : { ...servicesList, uuid: '', serviceId: selectedServiceId, profiles: profilesToServicesList }
    }

    setServicesList(servicesWithProfiles);
  }, [submit]);

  const handleServiceListChange = (profileId, params) => {
    const newProfiles = servicesList.profiles?.map(profile => {
      return +profile?.profileId == +profileId ? {
        ...profile,
        ...params
      } : {
        ...profile
      }
    });

    const newServicesList = {
      ...servicesList,
      profiles: newProfiles
    }

    setServicesList(newServicesList);
  }

  const handleSave = () => {
    const profiles = servicesList.profiles;

    const newProfiles = profiles?.map(profile => {
      delete profile?.label;
      return profile;
    });

    const submitServicesList = {
      ...servicesList,
      establishmentUuid: !!establishmentUuid ? establishmentUuid : alternativeEstablishmentUuid,
      serviceId: selectedServiceId,
      profiles: newProfiles
    }

    dispatch(upsertComissionConfig(userId, establishmentId, submitServicesList));
  }

  return(
    <Card title='Configurações do comissionamento'>
      <Grid xs={12} alignItems='center'>
        <Loader isLoading={isLoading}>
          <Grid container spacing={2} style={{ padding: '0 15px 0 15px' }}>
            <Grid item xs={12} spacing={2}>
              <Autocomplete
                id="serviceOptions"
                getOptionLabel={option => option.label}
                classes={autoCompleteStyles}
                options={services}
                loading={false}
                value={
                  services?.find(service => +service?.value == +selectedServiceId) ||
                  services[0]
                }
                loadingText={<p>Carregando...</p>}
                noOptionsText={<p>Sem opções</p>}
                onChange={(_, service) => {
                  if(service) {
                    setSelectedServiceId(service.value);
                    requestLoadComissionConfigList(establishmentId, service.value);
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Serviço"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
          {servicesList?.profiles?.map(profile => (
            <Grid container spacing={2} style={{ padding: '0 15px 0 15px' }}>
              <Grid item xs={6} spacing={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant='subtitle2' color='primary'>
                  {profiles?.find(profileList => +profileList?.value == +profile?.profileId)?.label}
                </Typography>
                <Input
                  label='Valor'
                  type='money'
                  id={profile?.profileId}
                  name={profile?.profileId}
                  value={profile?.amount}
                  error={null}
                  helperText={null}
                  onChange={event => handleServiceListChange(profile?.profileId, { amount: event.target.value })}
                />
              </Grid>
              <Grid item xs={6} spacing={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography variant='subtitle2' color='primary'>Tarifa de comissão</Typography>
                <ToggleButtonGroup
                  exclusive
                  color='primary'
                  value={profile?.type}
                  style={{ marginTop: isMobile ? '5px' : '8px' }}
                >
                  <Tooltip
                    title='Valor absoluto em real'
                    placement='top'
                  >
                    <ToggleButton
                      value='money'
                      onClick={() => handleServiceListChange(profile?.profileId, { type: '2' })}
                      style={{
                        backgroundColor: +profile?.type == 2 ? '#1E5168' : 'inherit',
                        color: +profile?.type == 2 ? '#FFFFFF' : '#6C6C6C'
                      }}
                    >
                      R$
                    </ToggleButton>
                  </Tooltip>
                  <Tooltip
                    title='Valor percentual'
                    placement='top'
                  >
                    <ToggleButton
                      value='percentage'
                      onClick={() => handleServiceListChange(profile?.profileId, { type: '1' })}
                      style={{
                        backgroundColor: +profile?.type == 1 ? '#1E5168' : 'inherit',
                        color: +profile?.type == 1 ? '#FFFFFF' : '#6C6C6C'
                      }}
                    >
                      %
                    </ToggleButton>
                  </Tooltip>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
          ))}
          <Grid container spacing={2} style={{ padding: '0 15px 10px 15px' }}>
            <Grid item xs={12} spacing={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                type='submit'
                color='success'
                onClick={handleSave}
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Loader>
      </Grid>
    </Card>
  );
}