import { toast } from 'react-toastify';
import { createCard, deleteCards, fetchCards } from './services';
import { setCards, handleLoading } from './actions';

export const loadCards = ({ userId, establishmentId, page, pageSize, qp = '' }) => dispatch => {
  dispatch(handleLoading(true));

  const qpSearch = qp.replace('search', 'cardCode');

  const url = `/cards?pg=${page}&limit=${pageSize}${qpSearch}`;

  return fetchCards(userId, establishmentId, url)
    .then(({ data }) => dispatch(setCards(data.content, data.total)))
    .finally(() => dispatch(handleLoading(false)));
}

export const insertCard = (userId, establishmentId, params, properties) => dispatch => {
  const { page, pageSize } = properties;

  dispatch(handleLoading(true));

  return createCard(userId, establishmentId, params)
    .then(() => {
      toast.success('Cartão cadastrado com sucesso!');
      dispatch(loadCards({ userId, establishmentId, page, pageSize }));
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const removeCards = ({ userId, establishmentId, properties, cardCodes }) => dispatch => {
  const data = { data: { cardCodes } };
  const { page, pageSize } = properties;

  dispatch(handleLoading(true));

  return deleteCards(userId, establishmentId, data)
    .then(() => {
      toast.success('Cartão(ões) removido(s) com sucesso!');
      dispatch(loadCards({ userId, establishmentId, page, pageSize }));
    })
    .finally(() => dispatch(handleLoading(false)));
}