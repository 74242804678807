import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import qs from 'qs';

import { Formik } from 'formik';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import Input from '../../../components/Input';
import Button from '../../../components/Button';

import { login } from '../store/thunk';
import { handleLogout } from '../store/actions';
import { clearProfile } from '../../Profile/store/actions';
import { clearBusinessInfo } from '../../BusinessInfo/store/actions';
import { validations } from './validations';
import { useStyles } from './styles';

import projectPackage from '../../../../package.json';

const logo = require('../../../assets/img/logo_login.svg');

const Login = () => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);

  const { loggedIn, establishments } = useSelector(state => state.login);
  const initialState = { email: '', password: '' };

  const doesUserHaveAnyEstablishment =
    establishments[0]?.establishmentId || establishments[establishments.length - 1]?.establishmentId;

  useEffect(() => {
    const notAuthorized = qs.parse(location.search, { ignoreQueryPrefix: true }).notauthorized;

    if(notAuthorized) {
      dispatch(handleLogout());
      toast.error('Ação não autorizada. Por favor, efetue o login antes de continuar.');
    }

    if(loggedIn && doesUserHaveAnyEstablishment) {
      history.push('/visao-geral');
    }
  }, []);

  const handleLogin = values => {
    setLoading(true);

    dispatch(clearProfile())

    dispatch(login(values, history))
      .finally(() => setLoading(false));
  }

  const handleRegistration = () => {
    dispatch(clearProfile());
    dispatch(clearBusinessInfo());

    localStorage.clear();
  }

  return (
    <Grid
      container
      spacing={0}
      alignItems="center"
      justify="center"
      className={classes.container}
    >
      <Grid item md={4} xs={12}>
        <Paper elevation={0} className={classes.paper}>
          <img src={logo} width="100" height="100" alt="Jump Car" />
          <Typography paragraph variant="h6" color="primary">
            Bem-vindo ao
            <br />
            <Typography variant="h5" color="primary" className={classes.title}>
              Jump Car
            </Typography>
          </Typography>
          <Formik
            enableReinitialize
            initialValues={initialState}
            validationSchema={validations}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={handleLogin}
          >
            {({ ...formikProps }) => (
              <form
                onKeyDown={event => {
                  if(event.key == 'Enter') {
                    return handleLogin(formikProps.values);
                  }
                }}
              >
                <Input
                  name="email"
                  type="email"
                  label="Email"
                  value={formikProps.values.email}
                  error={formikProps.errors.email}
                  helperText={formikProps.errors.email}
                  onChange={formikProps.handleChange}
                />
                <Input
                  name="password"
                  type="password"
                  label="Senha"
                  value={formikProps.values.password}
                  error={formikProps.errors.password}
                  helperText={formikProps.errors.password}
                  onChange={formikProps.handleChange}
                />
                <Button
                  fullWidth
                  verticalGutter
                  type="button"
                  color="secondary"
                  loading={loading}
                  onClick={() => handleLogin(formikProps.values)}
                >
                  Entrar
                </Button>
              </form>
            )}
          </Formik>
          <Grid className={classes.linkContainer}>
            <Typography
              paragraph
              to="/esqueci-a-senha"
              variant="body1"
              color="primary"
              className={classes.link}
              component={Link}
              onClick={() => handleRegistration()}
            >
              Esqueci minha senha
            </Typography>
          </Grid>
          <Divider className={classes.divider} />
          <Link
            to={{ pathname: "/cadastro-inicial", state: { isRegister: true } }}
            style={{ textDecoration: 'none' }}
            onClick={() => handleRegistration()}
          >
            <Button fullWidth color="success">
              Iniciar teste grátis de 7 dias
            </Button>
          </Link>
        </Paper>
        <Typography className={classes.version}>
          Versão {projectPackage.version}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Login;