export const availableTaxationRegimesMock = [
  { value: 0, label: 'Nenhum' },
  { value: 1, label: 'Microempresa Municipal' },
  { value: 2, label: 'Estimativa' },
  { value: 3, label: 'Sociedade de Profissionais' },
  { value: 4, label: 'Cooperativa' },
  { value: 5, label: 'Microempresario Individual (MEI)' },
  { value: 6, label: 'Microempresario e Empresa de Pequeno Porte (ME EPP))' },
];

export const availableIssChargeabilities = [
  { value: 1, label: 'Exigivel' },
  { value: 2, label: 'Nao incidencia' },
  { value: 3, label: 'Isencao' },
  { value: 4, label: 'Exportacao' },
  { value: 5, label: 'Imunidade' },
  { value: 6, label: 'Exigibilidade Suspensa por Decisao Judicial' },
  { value: 7, label: 'Exigibilidade Suspensa por Processo Administrativo' },
];
